.guarantee-rules {
  &__heading {
    font-weight: 500;
    color: var(--primary-color);
    font-size: 18px;
    padding: 24px 50px;
    margin: 0 -50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      right: 0;
      height: 1px;
      background: var(--border-gray-color);
      z-index: 1500;
    }
  }

  ul {
    li {
      &.accordion {
        height: calc(var(--nav-name-height) * 1px);
        overflow: hidden;
        transition: height var(--default-transition);
        margin: 0 -50px;
        padding: 0 50px;
      }
    }
  }

  &__item {
    transition: var(--default-transition);

    &.closed {
      .guarantee-rules {
        &__heading {
          color: var(--black-color);
          border-bottom: none;
        }

        &__toggle-icon {
          background-image: url("../images/svg/arrows/bottom-gray.svg");
          transform: rotate(0deg);
        }
      }
    }
  }

  &__toggle-icon {
    background-image: url("../images/svg/arrows/bottom-red.svg");
    transform: rotate(180deg);
    width: 14px;
    height: 7px;
    background-repeat: no-repeat;
    transition: var(--default-transition);
  }

  //&__item-closed {
  //  .guarantee-rules {
  //    &__heading {
  //      color: var(--black-color);
  //      border-bottom: none;
  //    }
  //
  //    &__toggle-icon {
  //      background-image: url("../images/svg/arrows/bottom-gray.svg");
  //      transform: rotate(0deg);
  //    }
  //  }
  //}

  &__content {
    padding: 30px 0;
    color: var(--text-color);
    margin-bottom: 20px;

    ul {
      list-style: none;

      li {
        margin-bottom: 12px;
        padding-left: 26px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          width: 6px;
          height: 6px;
          left: 0;
          top: 10px;
          background-color: var(--primary-color);
        }

        &:first-child {
          margin-top: 20px;
        }
      }
    }
  }
}

@include respond-up("large") {
  .guarantee-rules {
  }
}

@include respond("medium") {
  .guarantee-rules {
    padding: 30px;

    &__heading {
      padding: 20px 30px;
      margin: 0 -30px;
      font-size: 18px;
    }

    &__content {
      font-size: 15px;
      margin-bottom: 0;
      padding: 20px 0 18px;
    }
  }
}

@include respond-down("medium") {
  .guarantee-rules {
  }
}

@include respond-down("small") {
  .guarantee-rules {
    &__heading {
      padding: 15px 19px;
      margin: 0 -19px;
      font-size: 15px;
    }
  }
}
