.catalog-main {
  &__link {
    cursor: default;
  }

  &__filter-button {
    svg.icon-filter {
      height: 22px;
      width: 22px;
    }

    &._hide {
      display: none;
    }
  }

  &__side-filter {
    grid-area: side-filter;
  }

  &__product-list {
    grid-area: product-list;
  }

  &__before-products {
    grid-area: before-products;
  }
}

@include respond-up('large') {
  .catalog-main {
    padding-top:  var(--gutter-large);

    &__info {
      margin-top: var(--gutter-large);
    }

    &__main {
      display: grid;
      grid-template-columns: 239px 1fr;
      grid-template-rows: auto 1fr;
      grid-gap: 0 var(--gutter-large);
      grid-template-areas: "side-filter before-products"
                            "side-filter product-list";
    }

    &__filter-button {
      display: none;
    }

    &__side-filter {
      border-radius: 3px;
      z-index: 20;
    }

    &__product-list {
      z-index: 10;
    }

    &__side-filter {
      border-radius: 3px;
      z-index: 20;
    }
  }
}

@include respond-up('medium') {
  .catalog-main {
    &__link, &__description {
      margin-top: 22px;
    }
  }
}

@include respond('medium') {
  .catalog-main {
    padding: var(--gutter-medium) 0 45px;

    &__info {
      margin-top: var(--gutter-medium);
    }

    &__selection-filter {
      margin-bottom: var(--gutter-medium);
    }

    &__filter-button {
      max-width: 200px;
      bottom: 45px;
    }
  }
}

@include respond-down('medium') {
  .catalog-main {
    &__filter-button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: sticky;
      margin: 0 auto;
      font-size: 15px;
      line-height: 1.35em;
      color: #FFFFFF;

      svg.icon-filter {
        margin-right: 11px;
      }
    }
  }
}

@include respond-down('small') {
  .catalog-main {
    padding: var(--gutter-small) 0 30px;

    &__info {
      margin-top: var(--gutter-small);
    }

    &__link, &__description {
      margin-top: 12px;
    }

    &__selection-filter {
      margin-bottom: var(--gutter-small);
    }

    &__filter-button {
      max-width: 146px;
      bottom: 30px;
    }
  }
}