.cart-receiving {
  &__title {
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 1.17;
  }

  &__tabs-container {

  }

  &__tabs {

  }

  &__content-container {

  }

  &__content {

  }
}

@include respond-up('large') {
  .cart-receiving {
    &__title {
      font-size: 24px;
      padding: 30px 50px;
    }

    &__tabs-container {
      padding: 0 50px;
      margin-bottom: 40px;
    }

    &__tabs {

    }

    &__content-container {

    }

    &__content {

    }
  }
}

@include respond('medium') {
  .cart-receiving {
    &__title {
      font-size: 24px;
      padding: 20px 30px;
    }

    &__tabs-container {
      padding: 0 30px;
      margin-bottom: 30px;
    }

    &__tabs {

    }

    &__content-container {

    }

    &__content {

    }
  }
}

@include respond-down('small') {
  .cart-receiving {
    &__title {
      font-size: 24px;
      padding: 14px 19px;
    }

    &__tabs-container {
      padding: 0 19px;
      margin-bottom: 20px;
    }

    &__tabs {

    }

    &__content-container {

    }

    &__content {

    }
  }
}