.services {
  background-color: white;

  &__list {
    display: grid;
  }

  &__item {
    position: relative;
  }

  &__item-link {
    display: block;
    position: relative;

    &::after {
      position: absolute;
      z-index: 1;
      content: '';
      border-radius: 3px;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(31, 33, 41, 0.3);
      opacity: 0;
      transition: opacity var(--default-transition);
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  &__item-image {
    object-fit: cover;
    width: 100%;
    border-radius: 3px;
  }

  &__item-name {
    position: absolute;
    font-weight: 500;
    font-size: 19px;
    line-height: 1.24em;
    color: white;
    left: 28px;
    bottom: 15px;
    z-index: 10;
    padding-right: 20px;
  }
}

@include respond-up('large') {
  .services {
    margin-bottom: 70px;

    &__list {
      grid-template-columns: repeat(auto-fit, minmax(23%, 1fr));
      padding: 28px;
      grid-gap: 28px;
    }

    &__item-image {
      height: 184px;
    }
  }
}

@include respond('medium') {
  .services {
    margin-bottom: 50px;
    &__list {
      grid-template-columns: repeat(auto-fit, minmax(45%, 1fr));
      padding: 24px;
      grid-gap: 24px;
    }

    &__item-image {
      height: 180px;
    }
  }
}

@include respond-down('small') {
  .services {
    margin-bottom: 30px;
    &__list {
      padding: 14px;
      grid-gap: 14px;
    }

    &__item-image {
      height: 160px;
    }
  }
}