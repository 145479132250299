.cart-result-mini {
  display: flex;
  justify-content: space-between;

  &__result-container {
    display: flex;
    flex-direction: column;
  }

  &__result-text {
    color: var(--text-color-light);
    line-height: 1.33;
  }

  &__result-count {

  }

  &__result-items {

  }

  &__sum-container {
    color: var(--black-color);
    line-height: 1.17;
    font-weight: 500;
  }

  &__sum {

  }

  &__postfix {

  }

  &__delivery {
    display: flex;
    flex-direction: column;
  }

  &__delivery-title {
    color: var(--text-color-light);
    line-height: 1.33;
  }

  &__delivery-text {

  }

  &__button {
    align-self: center;
  }
}

@include respond('medium') {
  .cart-result-mini {
    padding: 12px 0;

    &__result-text {
      font-size: 15px;
      margin-bottom: 3px;
    }

    &__sum-container {
      font-size: 28px;
    }

    &__delivery {
      font-size: 15px;
    }

    &__delivery-title {
      margin-bottom: 3px;
    }

    &__button {
      height: 44px;
      padding: 12px 20px;
    }
  }
}

@include respond-down('small') {
  .cart-result-mini {
    padding: 16px 0;

    &__result-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex: 1 1 100%;
    }

    &__result-text {
      font-size: 14px;
    }

    &__sum-container {
      font-size: 24px;
    }

    &__delivery {
      display: none;
    }

    &__button {
      display: none;
    }
  }
}