.link-city {
  display: inline-flex;

  &:hover {
    .link-city {
      &__name {
        border-bottom-color: var(--primary-color-opacity-light);
      }
    }
  }

  &_light {
    &:hover {
      .link-city {
        &__name {
          border-bottom-color: rgba(255, 255, 255, 0.3);
        }
      }
    }

    .link-city {
      &__name {
        color: white;
      }
    }

    svg {
      path {
        fill: rgba(255, 255, 255, 0.3);
      }
    }
  }

  &__icon {
    display: flex;
    margin-right: 8px;
  }

  &__name {
    font-family: var(--font);
    font-size: 14px;
    line-height: 1.2em;
    white-space: nowrap;
    color: var(--primary-color);
    border-bottom: 1px solid var(--t);
    transition: border-bottom-color var(--default-transition);
  }
}