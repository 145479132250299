.rating-svg {
  @for $i from 1 through 5 {
    &_#{$i} {
      path {
        &:nth-child(-n + #{$i}) {
          fill: var(--rating-color);
        }
      }
    }
  }
}