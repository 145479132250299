.model-offer {
  display: flex;
  justify-content: space-between;

  &__first-container {
    display: flex;
    align-items: center;
  }

  &__name-link {
    padding: 10px;
    margin: -10px;

    &:hover {
      .model-offer {
        &__name {
          border-bottom: 1px solid var(--product-name-border);
        }
      }
    }
  }

  &__name {
    transition: var(--default-transition);
    border-bottom: 1px solid var(--t);
    line-height: 1.33;
  }

  &__center-container {
    display: flex;
    align-items: center;
  }

  &__price-container {
    display: flex;
    align-items: center;
  }

  &__price {
    font-weight: 500;
    line-height: 1.17;
    font-size: 18px;
  }

  &__price-complect {
    line-height: 1.2;
    font-size: 13px;
    color: var(--link-gray-color);
  }

  &__availability {
    line-height: 1.2;
    font-size: 14px;
  }

  &__second-container {
    display: flex;
    align-items: center;
  }

  &__cart-button {
    background-color: var(--t);
    margin: 0 5px 0 0;
    padding: 10px;
    border: 1px solid var(--button-border-color);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s;

    svg {
      path {
        transition: all .3s;
      }
    }

    &:hover {
      background-color: var(--primary-color);
      border: 1px solid var(--primary-color);

      svg {
        path {
          fill: #fff;
        }
      }
    }
  }

  &__favorites-button {
    background-color: var(--t);
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    &:hover {
      background-color: var(--t);
    }
  }
}

@include respond-up('large') {
  .model-offer {
    padding: 20px 50px;

    &__first-container {

    }

    &__name-link {
      width: 205px;
      margin-right: 40px;
    }

    &__center-container {

    }

    &__price-container {
      width: 220px;
      margin-right: 14px;
    }

    &__price {
      margin-right: 14px;
    }

    &__price-complect {

    }

    &__availability {

    }

    &__second-container {

    }

    &__cart-button {

    }

    &__favorites-button {

    }
  }
}

@include respond('medium') {
  .model-offer {
    padding: 20px 30px;

    &__first-container {

    }

    &__name-link {
      width: 164px;
      margin-right: 30px;
    }

    &__center-container {

    }

    &__price-container {
      width: 120px;
      flex-direction: column;
      align-items: flex-start;
      margin-right: 50px;
    }

    &__price {
      margin-bottom: 3px;
      font-size: 15px;
    }

    &__price-complect {

    }

    &__availability {

    }

    &__second-container {

    }

    &__cart-button {
      background-color: var(--primary-color);
      border: 1px solid var(--primary-color);

      svg {
        path {
          fill: #fff;
        }
      }
    }

    &__favorites-button {

    }
  }
}

@include respond-down('small') {
  .model-offer {
    padding: 16px 19px;
    justify-content: space-between;

    &__first-container {
      flex-direction: column;
      align-items: flex-start;
    }

    &__name-link {
      margin-bottom: 6px;
      font-size: 14px;
    }

    &__center-container {
      flex-direction: column;
      align-items: flex-start;
    }

    &__price-container {
      align-items: center;
      display: flex;
      margin-bottom: 5px;
    }

    &__price {
      font-size: 15px;
      margin-right: 9px;
    }

    &__price-complect {
      line-height: 1.2;
    }

    &__availability {

    }

    &__second-container {
      flex-direction: column-reverse;
      justify-content: space-between;
      align-items: flex-end;
    }

    &__cart-button {
      background-color: var(--primary-color);
      border: 1px solid var(--primary-color);
      margin-right: 0;

      svg {
        path {
          fill: #fff;
        }
      }
    }

    &__favorites-button {
      padding: 0;
      margin: 0;
    }
  }
}