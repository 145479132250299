.articles {
  &__list {
    border-radius: 3px;
  }

  &__item {
    &:last-child {
      .articles__link {
        border-bottom: none;
      }
    }
  }

  &__wrapper {
    background: white;
  }

  &__link {
    display: grid;
    border-bottom: 1px solid var(--border-gray-color);
  }

  &__picture {
    display: flex;
  }

  &__img {
    border-radius: 3px;
    object-fit: cover;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__created-at {
    color: var(--text-color-light-2);
    font-size: 14px;
    line-height: 120%;
  }

  &__title {
    font-weight: 500;
    line-height: 124%;
  }

  &__short-text {
    font-size: 15px;
    line-height: 150%;
    color: var(--text-color);
  }

  &__icon {
    justify-self: flex-end;
  }
}

@include respond-up('large') {
  .articles {
    margin-bottom: 78px;

    &__link {
      grid-template-columns: 302fr 678fr 58fr;
      padding: 50px;

      &:hover {
        .articles {
          &__icon {
            transform: translateX(5px);
          }
        }
      }
    }

    &__icon {
      align-self: center;
      width: fit-content;
      transition: transform .3s ease-in-out;
    }

    &__picture {
      display: block;
    }

    &__img {
      width: 100%;
      max-height: 172px;
    }

    &__text-wrapper {
      border-right: 1px solid var(--border-gray-color);
      padding: 0 48px;
    }

    &__created-at {
      margin-bottom: 20px;
    }

    &__title {
      font-size: 19px;
      margin-bottom: 14px;
    }
  }
}

@include respond('medium') {
  .articles {
    margin-bottom: 45px;

    &__link {
      grid-template-columns: 208px 1fr;
      align-self: stretch;
      grid-column-gap: 24px;
      padding: 30px;
    }

    &__img {
      min-height: 184px;
    }

    &__icon {
      display: none;
    }

    &__created-at {
      margin-bottom: 15px;
    }

    &__title {
      font-size: 19px;
      line-height: 124%;
      margin-bottom: 10px;
    }
  }
}

@include respond-down('small') {
  .articles {
    margin-bottom: 30px;

    &__picture {
      display: flex;
      width: 100%;
      height: calc((var(--grid-column4 - 40px) * 170/300));
    }

    &__link {
      padding: 20px;
    }

    &__img {
      margin-bottom: 20px;
      width: 100%;
      height: 100%;
    }

    &__created-at {
      margin-bottom: 10px;
    }

    &__title {
      font-size: 18px;
      margin-bottom: 10px;
    }

    &__icon {
      display: none;
    }
  }
}