.price-competitor-main {
  width: max-content;
  height: max-content;
  position: relative;

  &__heading-title {
    position: sticky;
    z-index: 50;
    top: 0;
    left: 0;
    width: 200px;
    height: 64px;
    border: 1px solid var(--black-color);
    background-color: var(--background-color);
    padding: 10px;
  }

  &__heading-columns {
    position: sticky;
    z-index: 25;
    top: 0;
    display: flex;
    height: max-content;
    margin-top: -64px;
    padding-left: 200px;
    background-color: var(--background-color);
  }

  &__heading-column {
    width: 150px;
    border: 1px solid var(--black-color);
    border-left: unset;
    padding: 10px;
    font-size: 14px;
  }

  &__heading-rows {
    position: sticky;
    z-index: 25;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 200px;
    background-color: var(--background-color);
  }

  &__heading-row {
    width: 100%;
    height: 120px;
    border: 1px solid var(--black-color);
    border-top: unset;
    padding: 10px;
  }

  &__data {
    position: absolute;
    top: 64px;
    left: 200px;
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    height: 120px;
    border-bottom: 1px solid var(--black-color);
  }

  &__item-column {
    width: 150px;
    height: 100%;
    border-right: 1px solid var(--black-color);
    display: flex;
    flex-direction: column;

    &._green {
      background-color: var(--success-color-opacity);
    }

    &._red {
      background-color: var(--primary-color-opacity);
    }
  }

  &__tyre-link {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
    font-size: 14px;

    &:hover {
      .price-competitor-main {
        &__tyre-link-price,
        &__tyre-link-date {
          text-decoration: underline;
        }
      }
    }
  }

  &__stats-link {
    flex: 0 0 auto;
    padding: 0 10px 10px;
    color: var(--primary-color);

    &:hover {
      text-decoration: underline;
    }
  }
}

@include respond-down('medium') {
  .price-competitor-main {
    &__tyre-link {
      padding: 5px;
    }
  }
}