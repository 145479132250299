.wrong-tyre-modal {
  &__form-success {
    &.request-form__success {
      position: static;
    }
  }
}

@include respond-up('medium') {
  .wrong-tyre-modal {
    width: 500px;
  }
}

@include respond-down('small') {
  .wrong-tyre-modal {
    width: var(--grid-column3);
  }
}