.order-success {
  &__container {
    background-color: #fff;
    border-radius: 3px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__title-container {
    display: flex;
    align-items: center;
  }

  &__title {

  }

  &__label {
    color: var(--blue-color);
    border: 1px solid var(--blue-opacity-color);
    border-radius: 3px;
    font-size: 14px;
    padding: 1px 6px;
  }

  &__print {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__print-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__product-list {

  }

  &__product-item {
    border-bottom: 1px solid var(--border-gray-color);


    &:first-child {
      border-top: 1px solid var(--border-gray-color);
    }
  }

  &__result-container {
    background-color: var(--gray-layer);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__result {
    display: flex;
    align-items: center;
  }

  &__result-count {
    color: var(--text-color-light);
    line-height: 1.3;
  }

  &__result-price {
    font-weight: 700;
    line-height: 1.17;
  }

  &__info-list {

  }

  &__info-item {
    border-bottom: 1px solid var(--border-gray-color);

    &:last-child {
      border-bottom: none;
    }
  }

  &__info-item-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1.33;
  }

  &__info-item-name {
    flex: 0 0 50%;
    color: var(--text-color-light);
  }

  &__info-item-value {
    flex: 0 0 50%;
  }

  &__info-personally {
    font-weight: 500;
    line-height: 1.24;
  }

  &__buttons {

  }

  &__button {
    &_lk {

    }

    &_index {

    }
  }

  &__notification {
    font-weight: 500;
    color: var(--black-color);
    border-top: 1px solid var(--border-gray-color);
  }
}

@include respond-up('large') {
  .order-success {
    margin-bottom: 70px;
    display: flex;
    justify-content: center;

    &__container {
      padding: 30px 50px 40px;
      max-width: 890px;
    }

    &__header {
      padding-bottom: 30px;
    }

    &__title-container {

    }

    &__title {
      font-size: 30px;
      margin-right: 30px;
    }

    &__label {

    }

    &__print {

    }

    &__print-icon {

    }

    &__product-list {
      margin: 0 -50px 30px;
    }

    &__product-item {
      padding: 0 50px;
    }

    &__result-container {
      padding: 21px;
      margin-bottom: 30px;
    }

    &__result {

    }

    &__result-count {
      margin-right: 16px;
      font-size: 15px;
    }

    &__result-price {
      font-size: 24px;
    }

    &__info-list {
      margin-bottom: 40px;
    }

    &__info-item {
      padding: 9px 0;
    }

    &__info-item-container {

    }

    &__info-item-name {
      font-size: 15px;
    }

    &__info-item-value {
      font-size: 15px;
    }

    &__info-personally {
      font-size: 19px;
      margin-bottom: 23px;
    }

    &__buttons {

    }

    &__button {
      &_lk {
        margin-right: 47px;
      }

      &_index {

      }
    }

    &__notification {
      padding: 22px 50px;
      margin: 0 -50px;
    }
  }
}

@include respond-up('medium') {
  .order-success {
    &__notification {
      font-size: 19px;
      line-height: 24/19*1em;
    }
  }
}

@include respond('medium') {
  .order-success {
    margin-bottom: 70px;
    display: flex;
    justify-content: center;

    &__container {
      padding: 30px 50px 40px;
      max-width: 890px;
    }

    &__header {
      padding-bottom: 30px;
    }

    &__title-container {

    }

    &__title {
      font-size: 30px;
      margin-right: 30px;
    }

    &__label {

    }

    &__print {

    }

    &__print-icon {

    }

    &__product-list {
      margin: 0 -50px 30px;
    }

    &__product-item {
      padding: 0 50px;
    }

    &__result-container {
      padding: 21px;
      margin-bottom: 30px;
    }

    &__result {

    }

    &__result-count {
      margin-right: 16px;
      font-size: 15px;
    }

    &__result-price {
      font-size: 24px;
    }

    &__info-list {
      margin-bottom: 30px;
    }

    &__info-item {
      padding: 9px 0;
    }

    &__info-item-container {

    }

    &__info-item-name {
      font-size: 15px;
    }

    &__info-item-value {
      font-size: 15px;
    }

    &__info-personally {
      font-size: 19px;
      margin-bottom: 20px;
    }

    &__buttons {

    }

    &__button {
      &_lk {
        margin-right: 47px;
      }

      &_index {

      }
    }
  }
}

@include respond-down('medium') {
  .order-success {
    &__notification {
      padding: 20px 50px;
      margin: 0 -50px;
    }
  }
}

@include respond-down('small') {
  .order-success {
    margin-bottom: 6px;

    &__container {
      padding: 14px 19px 20px;
    }

    &__header {
      padding-bottom: 15px;
      align-items: flex-start;
    }

    &__title-container {
      flex-direction: column;
      align-items: flex-start;
    }

    &__title {
      font-size: 23px;
      margin-bottom: 14px;
    }

    &__label {

    }

    &__print {

    }

    &__print-icon {

    }

    &__product-list {
      margin: 0 -19px 21px;
    }

    &__product-item {
      padding: 0 19px;
    }

    &__result-container {
      padding: 17px;
      margin-bottom: 20px;
    }

    &__result {

    }

    &__result-count {
      margin-right: 13px;
      font-size: 14px;
    }

    &__result-price {
      font-size: 20px;
    }

    &__info-list {
      margin-bottom: 20px;
    }

    &__info-item {
      padding: 9px 0;
    }

    &__info-item-container {

    }

    &__info-item-name {
      font-size: 14px;
    }

    &__info-item-value {
      font-size: 14px;
    }

    &__info-personally {
      font-size: 18px;
      margin-bottom: 20px;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
    }

    &__button {
      &_lk {
        padding: 12px 16px;
      }

      &_index {
        padding: 12px 20px;
      }
    }

    &__notification {
      font-size: 18px;
      line-height: 22/18*1em;
    }
  }
}

@media print {
  body * {
    visibility: hidden;
  }

  header {
    display: none;
  }

  footer {
    display: none;
  }

  .sub-header {
    display: none;
  }

  .content-header {
    display: none;
  }

  #main *, #main #printarea, #main #printarea * {
    visibility: visible;

    .order-success {
      margin-top: 30px;
      margin-bottom: 30px;

      &__print {
        display: none;
      }

      &__info-list {
        margin-bottom: 0;
      }

      &__buttons {
        display: none;
      }

      &__info-personally {
        display: none;
      }
    }

    .cart-success-product {
      &__name-container {
        border-bottom: none !important;
      }
    }
  }
}