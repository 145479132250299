.product-page {

}

@include respond-up('large') {
  .product-page {
    &__container {
      padding-bottom: 70px;
    }

    &__title {
      margin: 70px 0 28px;
    }

    &__main {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: var(--gutter-large);
    }

    &__content {
      grid-column: 1/10;
    }

    &__bar {
      grid-column: 10/13;
    }

    &__price-box {
      position: sticky;
      top: 107px;
    }

    &__info {
      margin-top: var(--gutter-large);
    }
  }
}

@include respond('medium') {
  .product-page {
    &__container {
      padding-bottom: 45px;
    }

    &__title {
      margin: 45px 0 24px;
    }

    &__info {
      margin-top: var(--gutter-medium);
    }
  }
}

@include respond-down('medium') {
  .product-page {
    &__bar {
      display: none;
    }
  }
}

@include respond-down('small') {
  .product-page {
    &__container {
      padding-bottom: 30px;
    }

    &__title {
      margin: 30px 0 14px;
    }

    &__info {
      margin-top: var(--gutter-small);
    }
  }
}