















.cart-errors {
    color: red;
    list-style: none;
    margin: 3px 0 5px;
    padding: 0;
    font-size: 12px;

    &:empty {
        margin-bottom: 0;
    }
}
