.personal-heading-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    font-weight: 500;
  }

  &__back-button {
    font-size: 14px;
    color: var(--link-gray-color);
  }

  &__back-link {
    padding: 20px;
    margin: -20px;
    display: flex;
    align-items: center;
  }
}

.personal-page-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 70px;
}

.personal-content-wrapper {
  flex-grow: 1;
}

.personal-menu {
  margin-right: 28px;
  flex-basis: 278px;

  &__item {
    border-bottom: 1px solid var(--border-gray-color);
    font-size: 16px;
    font-weight: 500;

    &._active {
      border-left: 3px solid var(--primary-color);
      color: var(--primary-color);

      path {
        fill: var(--primary-color);
      }
    }
  }

  &__item-link {
    line-height: 1em;
    padding: 20px 29px 21px 24px;
    display: flex;
    align-items: center;
  }

  &__item-link-icon {
    display: block;
    flex-basis: 25px;
    object-fit: contain;
    text-align: center;
    margin-right: 15px;
  }
}

.personal-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 50px;
  border-bottom: 1px solid var(--border-gray-color);

  &__section-wrapper {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }

  &__back-button-mobile {
    display: none;
    background: url("../images/svg/arrows/left-black.svg");
    width: 11px;
    height: 17px;
    background-repeat: no-repeat;
    margin-right: 17px;
  }

  &__title-wrapper {
    margin-right: 40px;

    .link-icon {
      margin-bottom: 12px;
    }
  }

  &__title {
    font-size: 24px;
  }
}

.personal-content {
  &__part-content {
    padding: 40px 50px;
    border-bottom: 1px solid var(--border-gray-color);

    &:last-child {
      border: none;
    }
  }

  &__part-content-form {
    display: flex;
  }

  &__part-content-form-label {
    flex-basis: 214px;
    margin-right: 86px;
  }

  &__part-content-form-label-heading {
    font-size: 19px;
    font-weight: 500;
    padding-bottom: 14px;
  }

  &__part-content-form-label-subheading {
    font-size: 15px;
    color: var(--text-color-light-2);
  }

  &__part-content-save {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .button {
      margin-right: 28px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__left-section {

  }

  &__right-section {

  }
}

@include respond-down('medium') {
  .personal-page-wrapper {
    flex-direction: column;
    margin-bottom: 45px;
  }

  .personal-heading-wrapper {
    &__back-button {
      display: none;
    }
  }

  .personal-head {
    padding: 0;
    border-bottom: none;

    &__title-wrapper {
      display: none;
    }

    &__section-wrapper {
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
    }

    &__left-section, &__right-section {
      padding: 24px 30px;
      margin: 0;
      border-bottom: 1px solid var(--border-gray-color);

      &:empty {
        border: none;
        padding: 0;
        margin: 0;
      }
    }
  }


  .personal-menu {
    margin-right: 0;
    flex-basis: 0;

    &__list {
      display: flex;
      justify-content: space-between;
      padding: 0;
    }

    &__item {
      border-right: 1px solid var(--border-gray-color);
      border-bottom: 1px solid var(--border-gray-color);
      margin: 0;
      flex-grow: 1;

      &:last-child {
        border-right: none;
      }

      &._active {
        border-left: none;
        border-top: 3px solid var(--primary-color);
        border-bottom: none;
      }
    }

    &__item-link-icon {
      display: none;
    }

    &__item-link {
      padding: 20px 0;
      justify-content: center;
    }
  }

  .personal-content {
    &__part-content {
      padding: 24px 30px;
    }

    &__part-content-save {
      .button {
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@include respond-down('small') {
  .personal-head {
    padding: 0 19px;
    flex-direction: column;
    align-items: stretch;

    &__back-button-mobile {
      display: block;
    }

    &__title-wrapper {
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      padding: 20px 19px;
      margin: 0 -19px;
      font-size: 20px;
      border-bottom: 1px solid var(--border-gray-color);

      .link-icon {
        margin-bottom: 5px;
      }
    }

    &__title-wrapper-mobile {
      flex-direction: row;
      align-items: center;
    }

    &__section-wrapper {
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
    }

    &__left-section, &__right-section {
      padding: 20px 19px;
      margin: 0 -19px;

      &:empty {
        border: none;
        padding: 0;
        margin: 0;
      }
    }
  }

  .personal-page-wrapper {
    margin-bottom: 30px;

    .personal-menu {
      display: none;
    }

    .personal-content-wrapper {
      display: inline-block;
    }

    &._menu-opened {
      .personal-menu {
        display: inline-block;
      }

      .personal-content-wrapper {
        display: none;
      }
    }
  }

  .personal-menu {
    &__list {
      flex-direction: column;
    }

    &__item {
      &._active {
        border-top: none;
        border-bottom: 1px solid var(--border-gray-color);
        color: var(--black-color);

        path {
          fill: var(--button-disabled-color);
        }
      }
    }

    &__item-link {
      padding: 15px 20px;
      justify-content: flex-start;
    }

    &__item-link-icon {
      display: block;
    }

  }

  .personal-content {
    &__part-content {
      padding: 19px 20px;
      flex-direction: column;
    }

    &__part-content-form {
      flex-direction: column;
    }

    &__part-content-form-label {
      flex-basis: auto;
      margin-right: 0;
    }

    &__part-content-form-label-heading {
      padding-bottom: 6px;
    }

    &__part-content-save {
      flex-direction: column-reverse;
      align-items: stretch;

      .button {
        margin-right: 0;
        margin-bottom: 12px;

        &:first-child {
          margin-bottom: 0;
        }
      }
    }

    &__left-section-link-wrapper {
      display: flex;
      flex-flow: column;
    }

    &__left-section-link {
      border-top: 1px solid var(--border-gray-color);
      padding: 20px 19px;
      margin: 0 -19px -20px;
      display: flex;
      justify-content: center;
    }

    &__right-section {
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 20px;
    }
  }
}