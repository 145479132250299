.to-top {
  position: fixed;
  right: 62px;
  bottom: 50px;
  display: flex;
  opacity: 0;
  pointer-events: none;
  transition: opacity .6s, transform .6s;
  transform: translateY(50px);
  z-index: 300;

  &.visible {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }

  &__link {
    display: flex;
    width: 50px;
    height: 50px;
    background-color: #fff;
    box-shadow: 0 10px 18px rgba(50, 59, 86, 0.1);
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    svg {
      path {
        transition: fill .3s;
      }
    }

    &:hover {
      svg {
        path {
          fill: var(--black-color);
        }
      }
    }
  }
}

@include respond-down('medium') {
  .to-top {
    right: 30px;
    bottom: 30px;

    &._upper {
      bottom: 80px;
    }
  }
}

