.rating-circle {
  position: relative;
  width: 58px;
  height: 58px;

  &__text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}