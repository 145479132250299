.action {
  &__main {
    background-color: white;
    border-radius: 3px;
  }

  &__created-at {
    font-size: 14px;
    line-height: 18px;
    color: var(--text-color-light-2);
  }

  &__short-text {
    font-size: 18px;
    line-height: 150%;
  }

  &__img {
    width: 100%;
    border-radius: 3px;
    object-fit: cover;
  }

  &__sticky {
    background-color: white;
    border-radius: 3px;
  }

  &__sticky-item {
    border-top: 1px solid var(--border-gray-color);
  }

  &__sticky-wrapper {
    display: flex;
    flex-direction: column;
    padding: 18px 33px;

    &:hover {
      .action__sticky-name {
        color: var(--primary-color);
      }
    }
  }

  &__sticky-created-at {
    color: var(--text-color-light-2);
    font-size: 14px;
    line-height: 120%;
    margin-bottom: 6px;
  }

  &__sticky-name {
    font-size: 15px;
    line-height: 133%;
    transition: var(--default-transition);
  }

  &__slider-link {
    display: flex;
    flex-direction: column;
    background-color: white;

    &:hover {
      .action__slider-name {
        color: var(--primary-color);
      }
    }
  }

  &__bottom {
    position: relative;
  }


  &__slider-arrow {
    position: absolute;
    top: 50%;

    &_left {
      left: 0;
      transform: translate3d(-50%, -50%, 0);
    }

    &_right {
      right: 0;
      transform: translate3d(50%, -50%, 0);
    }
  }

  &__slider-list {
    border-radius: 3px;
  }

  &__slider-item {
    border-right: 1px solid var(--border-gray-color);

    &:last-child {
      border-right: none;
    }
  }

  &__slider-img {
    border-radius: 3px;
  }

  &__slider-created-at {
    color: var(--text-color-light-2);
    font-size: 14px;
    line-height: 120%;
  }

  &__slider-name {
    font-size: 17px;
    line-height: 124%;
    min-height: 63px;
    color: black;
    transition: color var(--default-transition);
  }

  &__slider-list {
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@include respond-up('large') {
  .action {

    &__slider-link {
      padding: 28px;
    }

    &__bottom {
      margin-bottom: 70px;
    }

    &__bottom-title {
      margin-bottom: 23px;
    }

    &__slider-img {
      width: 242px;
      height: 130px;
      object-fit: cover;
      margin-bottom: 15px;
    }

    &__slider-created-at {
      margin-bottom: 10px;
    }

    &__top {
      display: grid;
      grid-template-columns: 1fr 278px;
      grid-column-gap: 28px;
    }

    &__main {
      padding: 30px 50px;
      margin-bottom: 70px;
    }

    &__slider-arrow {
      ._show {
        display: flex;
      }
    }

    &__img {
      min-height: 450px;
      margin-bottom: 20px;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__created-at {
      margin-bottom: 21px;
    }

    &__short-text {
      margin-bottom: 40px;
    }

    &__sticky {
      position: sticky;
      top: 100px;
      margin-bottom: 70px;
    }

    &__sticky-title {
      padding: 18px 43px 16px 33px;
    }
  }
}

@include respond('medium') {
  .action {
    &__main {
      padding: 30px;
      margin-bottom: 45px;
    }

    &__bottom {
      margin-right: -39px;
      margin-bottom: 55px;
    }

    &__slider-item {
      flex: 0 0 284px;
    }

    &__bottom-title {
      margin-bottom: 20px;
    }

    &__slider-img {
      border-radius: 3px;
    }

    &__slider-link {
      padding: 28px;
      height: 100%;
    }

    &__slider-created-at {
      margin-bottom: 10px;
    }

    &__slider-img {
      width: 242px;
      height: 130px;
      object-fit: cover;
      margin-bottom: 15px;
    }

    &__slider-name {
      font-size: 17px;
      line-height: 124%;
      color: black;
    }

    &__slider-list {
      display: flex;
      overflow-x: scroll;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__created-at {
      margin-bottom: 20px;
    }

    &__picture {
      margin-bottom: 45px;
    }

    &__short-text {
      margin-bottom: 45px;
    }

    &__sticky-block {
      display: none;
    }

    &__slider-arrow {
      display: none;
    }
  }
}

@include respond-down('small') {
  .action {
    &__main {
      padding: 20px 19px;
      margin-bottom: 30px;
    }

    &__bottom {
      margin-right: -19px;
      margin-bottom: 30px;
    }

    &__slider-item {
      flex: 0 0 212px;
    }

    &__bottom-title {
      margin-bottom: 12px;
    }

    &__slider-img {
      border-radius: 3px;
    }

    &__slider-link {
      padding: 14px;
      height: 100%;
    }

    &__slider-created-at {
      margin-bottom: 10px;
    }

    &__slider-img {
      width: 184px;
      height: 120px;
      object-fit: cover;
      margin-bottom: 10px;
    }

    &__slider-name {
      font-size: 16px;
      line-height: 124%;
      color: black;
    }

    &__slider-list {
      display: flex;
      overflow-x: scroll;
    }

    &__slider-arrow {
      display: none;
    }

    &__title {
      margin-bottom: 15px;
    }

    &__created-at {
      margin-bottom: 15px;
    }

    &__picture {
      margin-bottom: 20px;
    }

    &__short-text {
      font-size: 17px;
      margin-bottom: 30px;
    }

    &__sticky-block {
      display: none;
    }
  }
}