.category-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: #fff;

  &__title {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: var(--black-color);
    text-align: center;
  }

  &__subtitle {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    color: var(--text-color-light);
    margin-top: 8px;
    text-align: center;
  }

  &__image-wrap {
    display: flex;
  }

  &__picture {
    display: flex;
    width: 100%;
    height: 100%;
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

@include respond-up('large') {
  .category-card {
    padding: 24px;

    &__image-wrap {
      width: 100%;
      height: 175px;
    }
  }
}

@include respond-up('medium') {
  .category-card {
    &__title {
      margin-top: 18px;
    }
  }
}

@include respond('medium') {
  .category-card {
    padding: 24px 20px;
  }
}

@include respond-down('small') {
  .category-card {
    padding: 14px;

    &__title {
      font-size: 15px;
      line-height: 133%;
      margin-top: 14px;
    }
  }
}