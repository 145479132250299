.steps-points-list {

}

@include respond-up('large') {
  .steps-points-list {
    .steps-base-content {
      &__items {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}

@include respond-up('medium') {
  .steps-points-list {

  }
}

@include respond('medium') {
  .steps-points-list {

  }
}

@include respond-down('medium') {
  .steps-points-list {

  }
}

@include respond-down('small') {
  .steps-points-list {

  }
}