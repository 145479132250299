.payment-schedule {
  padding: 40px 0;

  &__form {
    margin-bottom: 20px;
  }

  &__form-delays {
    margin: 15px 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
  }

  &__week {
    border-bottom: 1px solid #dadada;

    &._opened {
      .payment-schedule__week-orders {
        display: block;
      }
    }
  }

  &__week-head {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    padding: 10px 0;
    font-weight: bold;
    font-size: 18px;
    cursor: pointer;
  }

  &__week-orders {
    display: none;
    padding-left: 50px;
    text-align: left;

    td, th {
      padding: 3px 20px;
    }

    tr:nth-child(even) {
      td {
        background-color: #f1f1f1;
      }
    }

    th {
      font-weight: bold;
    }
  }
}