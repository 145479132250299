.price-competitor {
  padding: var(--grid-spacer);

  &__main {
    margin-top: var(--grid-spacer);
  }

  &__total-title {
    margin-top: var(--grid-spacer);
  }
}

@include respond-up('large') {
  .price-competitor {

  }
}

@include respond-up('medium') {
  .price-competitor {

  }
}

@include respond('medium') {
  .price-competitor {

  }
}

@include respond-down('medium') {
  .price-competitor {

  }
}

@include respond-down('small') {
  .price-competitor {

  }
}