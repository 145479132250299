.orders-table {
  border: 1px solid black;
  margin-top: 100px;

  &__table-row {
    display: flex;
  }

  &__cell {
    min-width: 120px;
    &_body {
      display: flex;
      flex-direction: column;
    }

    &_header,
    &_body {
      &:not(:last-child) {
        border-right: 1px solid black;
      }
    }

    &_header {
      display: flex;
      justify-content: center;
    }
  }

  &__cell-info {
    padding: 10px;
    //&:not(:last-child) {
      border-bottom: 1px solid black;
    //}
  }
}

@include respond-up('large') {
  .orders-table {
    overflow: hidden;
    &__table-row {
      &_header {
        border-bottom: 1px solid black;
      }
    }
  }
}

@include respond-up('medium') {
  .orders-table {

  }
}

@include respond('medium') {
  .orders-table {

  }
}

@include respond-down('medium') {
  .orders-table {
    overflow: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    &__cell {
      &_header {
        border-bottom: 1px solid black;
      }
    }
  }
}

@include respond-down('small') {
  .orders-table {

  }
}