.manufacturers {
  &__head {
    display: flex;
    justify-content: space-between;
  }

  &__title-wrapper {
    display: flex;
  }

  &__title {

  }

  &__tabs {

  }

  &__tab-item {

  }

  &__red-link {
    &_mobile {
      display: none;
    }
  }

  &__tab-name {

  }

  &__link-wrapper {

  }

  &__red-link {

  }

  &__body {

  }

  &__list {
    display: grid;

  }

  &__item {
    transition: var(--default-transition);

    &:hover {
      box-shadow: 0 7px 40px rgba(50, 59, 86, 0.12);
      border-radius: 3px;
    }
  }

  &__item-wrapper {
    height: 100%;
    background-color: white;
    box-shadow: inset -1px -1px 0px var(--border-gray-color);

  }

  &__logo {

  }

  &__logo-title {
    font-size: 14px;
    line-height: 120%;
    color: var(--text-color-light);
    text-align: center;
  }


}

@include respond-up('large') {
  .manufacturers {
    margin-bottom: 90px;
    &__head {
      margin-bottom: 28px;
    }

    &__title {
      margin-right: 40px;
    }

    &__list {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    &__item-wrapper {
      padding: 27px 59px;

    }

    &__logo-title {

    }
  }
}
@include respond('medium') {
  .manufacturers {
    margin-bottom: 45px;
    &__head {
      margin-bottom: 24px;
    }

    &__title-wrapper {
      flex-direction: column;
    }

    &__title {
      margin-bottom: 24px;
    }

    &__link-wrapper {
      display: flex;
      align-items: flex-end;
    }

    &__list {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &__item {
      &:nth-child(n+7) {
        display: none;
      }
    }

    &__item-wrapper {
      padding: 27px 56px;
    }
  }
}

@include respond-down('small') {
  .manufacturers {
    margin-bottom: 30px;
    &__head {
      margin-bottom: 14px;
    }

    &__title-wrapper {
      flex-direction: column;

    }

    &__title {
      margin-bottom: 14px;
    }

    &__red-link {
      display: none;
      &_mobile {
        display: flex;
      }
    }

    &__link-wrapper {
      display: flex;
      align-items: flex-end;
    }

    &__list {
      grid-template-columns: 1fr 1fr;
    }

    &__item {
      &:nth-child(n+7) {
        display: none;
      }
    }

    &__item-wrapper {
      padding: 21px 40px;
      flex-direction: column;
      display: flex;
      align-items: center;
    }
  }
}