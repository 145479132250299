.modal-information {
  &__wysiwyg-header-background-wrapper {
    background: var(--black-color);
    border-radius: 3px 3px 0 0;
  }

  &__wysiwyg-header-wrapper {
    padding: 45px 50px 0;
    margin-bottom: 34px;
  }

  &__wysiwyg-header {
    display: flex;
    align-items: center;
  }

  &__wysiwyg-heading {
    color: white;
    flex-basis: 569px;
  }

  &__wysiwyg-image {
    margin-bottom: -34px;
    margin-right: 37px;
    flex-basis: 180px;
  }

  &__wysiwyg-content-main-wrapper {
    padding: 66px 50px 50px;
  }
}

@include respond-down('medium'){
  .modal-information {
    &__wysiwyg-heading {
      flex-basis: 432px;
    }

    &__wysiwyg-content-main-wrapper {
      padding: 30px;
    }

    &__wysiwyg-header-wrapper {
      padding: 30px 30px 0;
    }

    &__wysiwyg-image {
      margin-bottom: -31px;
      margin-right: 30px;
      flex-basis: 169px;
    }
  }
}

@include respond-down('small'){
  .modal-information {
    &__wysiwyg-heading {
      display: none;
    }

    &__wysiwyg-content-main-wrapper {
      padding: 20px 19px;
    }

    &__wysiwyg-header-wrapper {
      padding: 20px 19px 0;
    }

    &__wysiwyg-image {
      margin-bottom: -26px;
      margin-right: 0;
      flex-basis: 95px;
    }
  }
}