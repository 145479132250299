.steps-result {
  display: flex;
  justify-content: center;
  align-items: center;

  &__inner {
    background-color: #fff;
    width: 100%;
  }

  &__heading {
    box-shadow: 0px -1px 0px #E7E7E7, 0px 1px 0px #E7E7E7;
  }

  &__item {
    display: flex;
    align-items: center;
    padding: 8px 0;

    font-style: normal;
    font-weight: 400;

    &:not(:last-child) {
      border-bottom: 1px solid var(--border-gray-color);
    }
  }

  &__item-title {
    color: var(--text-color-light);
  }
}

@include respond-up('large') {
  .steps-result {
    &__inner {
      max-width: 890px;
    }

    &__heading {
      padding: 30px 50px;
    }

    &__main {
      padding: 40px 50px;
    }

    &__button {
      margin-top: 40px;
    }
  }
}

@include respond-up('medium') {
  .steps-result {
    &__item {
      font-size: 15px;
      line-height: 133%;
    }

    &__item-title {
      flex: 0 0 calc(50% - (var(--grid-gap) / 2));
    }

    &__item-value {
      flex: 0 0 calc(50% - (var(--grid-gap) / 2));
      margin-left: var(--grid-gap);
    }
  }
}

@include respond('medium') {
  .steps-result {
    &__inner {
      max-width: var(--grid-column6);
    }

    &__heading,
    &__main {
      padding: 30px;
    }

    &__button {
      margin-top: 30px;
    }
  }
}

@include respond-down('medium') {
  .steps-result {

  }
}

@include respond-down('small') {
  .steps-result {
    &__inner {
      max-width: var(--grid-column4);
    }

    &__heading {
      padding: 14px 19px;
    }

    &__main {
      padding: 20px 19px;
    }

    &__item {
      font-size: 14px;
      line-height: 120%;
    }

    &__item-value {
      flex: 0 0 37%;
    }

    &__item-value {
      flex: 0 1 100%;
      margin-left: 25px;
    }

    &__button {
      margin-top: 20px;
    }
  }
}