.title {
  font-family: var(--h-font);
  font-size: var(--h-size);
  line-height: 1.17;
  font-weight: 500;

  &_small {
    line-height: 1.24;
  }
}

@include respond-up('medium') {
  .title {
    font-size: 24px;

    &_big {
      font-size: 30px;
    }

    &_small {
      font-size: 19px;
    }
  }
}

@include respond-down('small') {
  .title {
    font-size: 20px;

    &_big {
      font-size: 23px;
    }

    &_small {
      font-size: 18px;
    }
  }
}