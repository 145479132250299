.marketplace-popular-offers {
  margin-top: 20px;
  margin-bottom: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-width: 800px;
  grid-gap: 30px;

  &__title {
    margin-bottom: 20px;
  }

  td {
    padding: 5px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &__chart-wrapper {
    display: none;

    &._opened {
      display: block;
    }
  }
}