.sidebar-fieldset {
  background-color: #fff;

  &__ninja-button {
    position: absolute;
    top: 0;
    left: calc(100% - 1px);
    padding: 12px 24px;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-50%);
    z-index: 1000;
    transition:
            opacity var(--default-transition),
            background-color var(--default-transition),
            top var(--default-transition);

    &._show {
      opacity: 1;
      pointer-events: auto;
    }

    &:hover {
      &::before {
        border-color: $t var(--primary-color-hover) $t $t;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 6px 5px 6px 0;
      border-color: $t var(--primary-color) $t $t;
      transition: border-color var(--default-transition);
    }
  }

  &__buttons {
    border-top: 1px solid var(--border-gray-color);
  }

  .side-filter {
    &__button {
      &_mobile {
        display: none;
      }
    }
  }
}

@include respond-up('large') {
  .sidebar-fieldset {
    &__main {
      position: relative;
    }

    &__buttons {
      padding: 24px;
    }
  }
}

@include respond-up('medium') {
  .sidebar-fieldset {
    &__heading {
      display: none;
    }
  }
}

@include respond('medium') {
  .sidebar-fieldset {
    padding: 70px 0 115px;

    &__heading {
      height: 70px;
      padding: 0 var(--spacer-medium);
    }

    &__buttons {
      height: 92px;
      padding: 0 var(--spacer-medium);
      grid-gap: 23px;
    }
  }
}

@include respond-down('medium') {
  .sidebar-fieldset {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 2000;
    overflow: auto;

    &._open {
      display: block;
    }

    &__ninja-button {
      display: none;
    }

    &__heading {
      display: flex;
      align-items: center;
      box-shadow: 0 1px 0 var(--border-gray-color);
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      background-color: white;
      z-index: 600;
    }

    &__closer {
      display: flex;
      align-items: center;
      margin-right: 12px;
    }

    &__buttons {
      position: fixed;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white;
      border-top: solid var(--border-gray-color);

      &::before {
        content: '';
        position: absolute;
        pointer-events: none;
        left: 0;
        right: 0;
        bottom: calc(100% + 1px);
        height: 65px;
        background: linear-gradient(180deg, $t 0, white 50%, white 100%);
      }
    }

    .side-filter {
      &__button {
        &_desktop {
          display: none;
        }

        &_mobile {
          display: block;
        }
      }
    }
  }
}

@include respond-down('small') {
  .sidebar-fieldset {
    padding: 50px 0 110px;

    &__heading {
      height: 50px;
      padding: 0 var(--spacer-small);
    }

    &__buttons {
      height: 84px;
      padding: 0 var(--spacer-small);
      grid-gap: 14px;
    }
  }
}