.model-choice {
  margin-bottom: 70px;

  &__help {
    color: var(--link-gray-color);
    font-size: 15px;
    display: flex;
    padding: 20px;
    margin: -20px;
  }

  &__help-mobile {
    display: none;
    padding: 15px 19px;
    margin: 0;
    justify-content: center;
  }

  &__help-desktop {
    display: flex;
  }

  &__heading-wrapper {
    padding: 41px 50px 24px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-bottom: 1px solid var(--border-gray-color);
  }

  &__help-icon {
    background: url("../images/svg/help-icon.svg");
    width: 22px;
    height: 22px;
    margin-right: 10px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;

    .model-choice {

      &__link {
        padding: 20px 30px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        width: 100%;
        font-size: 15px;
        line-height: 20px;
      }

      &__image-wrapper {
        margin-right: 15px;
        height: 28px;
        display: flex;
        justify-content: center;
        flex-basis: 39px;
      }

      &__image {
        max-width: 39px;
        max-height: 28px;
        object-fit: contain;
      }

      &__item {
        flex-basis: 25%;
        display: flex;
        align-items: center;
        box-shadow: 0px 1px 0px var(--border-gray-color), inset -1px 0px 0px var(--border-gray-color);
        transition: var(--default-transition);

        &:hover {
          box-shadow: 0px 7px 40px rgba(50, 59, 86, 0.12);
          border-radius: 3px;
        }

        &:nth-child(4*n) {
          box-shadow: 0px 1px 0px var(--border-gray-color);
        }
      }
    }
  }
}

@include respond-down('medium') {
  .model-choice {
    margin-bottom: 46px;

    &__heading-wrapper {
      padding: 30px 30px 20px;

      .title {
        font-size: 19px;
      }
    }

    &__list {
      .model-choice {
        &__item {
          flex-basis: 33.33%;
          box-shadow: 0px 1px 0px var(--border-gray-color), inset -1px 0px 0px var(--border-gray-color);

          &:nth-child(4*n) {
            box-shadow: 0px 1px 0px var(--border-gray-color), inset -1px 0px 0px var(--border-gray-color);
          }

          &:nth-child(3*n) {
            box-shadow: 0px 1px 0px var(--border-gray-color);
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .model-choice {
    margin-bottom: 30px;

    &__help {
      display: none;
    }

    &__help-mobile {
      display: flex;
    }

    &__help-desktop {
      display: none;
    }

    &__list {
      .model-choice {
        &__link {
          padding: 20px 19px;
          font-size: 14px;
        }

        &__item {
          flex-basis: 50%;

          &:nth-child(4*n) {
            box-shadow: 0px 1px 0px var(--border-gray-color), inset -1px 0px 0px var(--border-gray-color);
          }

          &:nth-child(3*n) {
            box-shadow: 0px 1px 0px var(--border-gray-color), inset -1px 0px 0px var(--border-gray-color);
          }

          &:nth-child(2*n) {
            box-shadow: 0px 1px 0px var(--border-gray-color);
          }
        }
      }
    }
  }
}