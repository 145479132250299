.producers {
  &.main-page{
    position: relative;
    .producers{
      &__items {
        display: flex;
      }

      &__item {
        border-right: 1px solid var(--border-gray-color);
      }
    }
  }

  &__items {
    display: grid;
    grid-gap: 1px;
  }

  &__item {
    background-color: white;
    border-right: 1px solid var(--border-gray-color);
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 7px 40px rgba(50, 59, 86, 0);
    border-radius: 0;
    transition: box-shadow var(--default-transition), border-radius var(--default-transition);
    height: 100%;

    &:hover {
      position: relative;
      background: white;
      box-shadow: 0 7px 40px rgba(50, 59, 86, 0.12);
      border-radius: 3px;
    }
  }

  &__img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &__name {
    font-size: 14px;
    line-height: 1.2em;
    text-align: center;
    color: var(--text-color-light);
  }
}

@include respond-up('large') {
  .producers {
    &.main-page{
      .producers{
        &__items {
          grid-gap: 0;
        }

        &__card {
          padding: 30px 60px 40px;
        }

        &__item {
          height: 200px;
        }

        &__logo {
          height: 100px;
          width: 180px;
        }
      }
    }

    &__items {
      grid-template-columns: repeat(5, 1fr);
    }

    &__logo {
      height: 60px;
      width: 121px;
    }
  }
}

@include respond-up('medium') {
  .producers {
    &.main-page{
      .producers{
        position: relative;
        &__arrow{
          position: absolute;
          &_left{
            left: -22px;
            top: calc(50% - 22px);
          }
          &_right{
            right: -22px;
            top: calc(50% - 22px);
          }
        }
      }
    }

    &__card {
      padding: 27px 24px;
    }

    &__logo {
      margin-bottom: 7px;
    }
  }
}

@include respond('medium') {
  .producers {
    &.main-page{
      .producers{
        &__card {
          padding: 20px 45px 25px;
        }
        &__item {
          height: 154px;
        }
        &__logo {
          height: 77px;
          width: 139px;
        }
      }
    }

    &__items {
      grid-template-columns: repeat( auto-fit, minmax(230px, 1fr) );
    }
    &__logo {
      height: 60px;
      width: 116px;
    }
  }
}

@include respond-down('small') {
  .producers {
    &.main-page{
      margin: 0 -20px;
      .producers{
        &__items{
          .tns-slide-active{
            margin-left: 20px;
          }
        }
        &__item {
          height: 110px;
        }
        &__arrow {
          display: none;
        }

        &__items {
          display: flex;
          overflow: scroll;

          &::-webkit-scrollbar{
            display: none;
          }
        }

        &__card {
          padding: 15px 32px 20px;
          width: 164px;
        }
        &__logo {
          height: 55px;
          width: 99px;
        }
      }
    }

    &__items {
      grid-template-columns: repeat( auto-fit, minmax(165px, 1fr) );
    }
    &__card {
      padding: 21px 16px 18px;
    }
    &__logo {
      margin-bottom: 5px;
    }
    &__logo {
      height: 48px;
      width: 86px;
    }
  }
}
