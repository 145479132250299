.sizes-filter {
  background-color: white;
  box-shadow: inset 0px -1px 0px var(--border-gray-color);

  &__blocks {
    display: flex;
    position: relative;
  }

  &__block {
    flex: 1 1 auto;
  }

  &__title {
    font-size: 15px;
    line-height: 133%;
    color: var(--text-color-light);
    margin-bottom: 14px;

  }

  &__size-list-category {

  }

  &__size-list-category-item {
    margin-bottom: 10px;
  }

  &__size-list-category-name {
    margin-bottom: 5px;
    font-weight: 500;
  }

  &__size-list {
  }

  &__size-item {

  }
}

@include respond-up('large') {
  .sizes-filter {
    padding: 37px 33px 60px;

    &__button-wrapper {
      display: none;
    }
  }
}

@include respond('medium') {
  .sizes-filter {
    padding: 30px;
  }
}

@include respond-down('medium') {
  .sizes-filter {

  }
}

@include respond-down('small') {
  .sizes-filter {
    padding: 18px 19px 20px;
    flex-direction: column;

    &__blocks {
      flex-direction: column;
    }

    &__block {
      &:nth-child(n+1) {
        padding-top: 14px;
      }
    }
  }
}