:root {
  --nav-name-height: 52;
}

.hamburg-nav {
  &__body {
    display: none;
  }

  &__catalog-search {
    display: flex;
    align-items: center;

    input[type="text"] {
      border: none;
    }

    .hamburg-nav__link-icon {
      margin-right: 0;
    }
  }

  &__catalog-title {
    margin: 24px 0;
    border: 1px solid rgba(225, 39, 39, 0.3);
    box-sizing: border-box;
    border-radius: 3px;
    padding: 12px 0;
    display: inline-block;
    color: var(--primary-color);
    line-height: 120%;
  }

  &__children {
    position: relative;
  }

  &__child-link {
    color: var(--text-color-light);
    font-size: 16px;
    line-height: 120%;
  }
}
@include respond('medium') {
  .hamburg-nav {
    &__button {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
      text-align: center;
      border-radius: 0;

      &_mobile {
        display: none;
      }
    }

    &__bottom-wrapper {
      position: relative;
      display: grid;
      grid-template-columns: 1fr auto;
      grid-gap: var(--grid-gap);
    }

    &__schedule {
      grid-column: 1/3;
    }
  }
}

@include respond-down('medium') {
  .hamburg-nav {
    &__contact-phone {
      &_second {
        margin-top: 10px;
      }
    }

    &__body {
      opacity: 0;
      pointer-events: none;
      transition: var(--default-transition);

      &._opened {
        z-index: 100;
        display: block;
        opacity: 1;
        pointer-events: auto;
        transition: var(--default-transition);
        position: fixed;
        top: 0;

        left: 0;
        width: 100%;
        height: 100%;
        overflow: scroll;
        background-color: white;
      }
    }

    &__list-wrapper {
      overflow: hidden;
    }

    &__children {
      padding: 24px 0 24px 65px;
    }

    &__items {
      height: 100%;
      overflow: scroll;
    }

    &__link-icon {
      margin-right: 14px;
      display: flex;
    }

    &__catalog-menu {
      display: inline-block;
      font-weight: 500;
      font-size: 19px;
      line-height: 124%;
      padding: 23px 0;
    }

    &__catalog-back {
      display: flex;
      padding: 23px 16px 23px 0;
    }

    &__child {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__link-name {
      flex-grow: 1;
    }

    &__link {
      padding: 15px 0;
      display: flex;
      align-items: center;
      font-size: 17px;
      line-height: 124%;

      &_arrow {
        padding: 15px 39px;
        border-bottom: 1px solid var(--border-gray-color);
        display: flex;
        justify-content: space-between;
      }

      svg {
        transition: transform var(--default-transition);
      }

      &_title {
        font-weight: 500;
        font-size: 19px;
        line-height: 124%;
      }
    }

    &__item, &__catalog-item {
      display: block;
      padding: 0 39px;
      border-bottom: 1px solid var(--border-gray-color);

      &:first-child {
        min-height: 54px;
        display: flex;
        align-items: center;
      }

      &.accordion {
        height: calc(var(--nav-name-height) * 1px);
        overflow: hidden;
        transition: height var(--default-transition);
      }

      &_parent {
        padding: 0;
      }

      &_head {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      &_bottom {
        padding: 40px 39px 78px;
        border-bottom: none;
      }

      &__bottom-wrapper {
        display: flex;
        flex-direction: column;
      }

      &_nav-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &__city {
      display: flex;
      margin-bottom: 40px;
    }

    &__city-icon {
      margin-right: 8px;
    }

    &__city-name {
      font-size: 15px;
      line-height: 133%;
      color: var(--primary-color);
    }

    &__numb {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 20px;
      grid-gap: var(--grid-gap);
    }

    &__contact-phone {
      font-weight: 500;
      font-size: 15px;
      line-height: 113%;
    }

    &__contact-name {
      font-weight: 400;
      font-size: 14px;
      line-height: 120%;
      color: var(--text-color-light);
      margin-bottom: 4px;
    }

    &__schedule {
      font-size: 14px;
      line-height: 120%;
      color: var(--text-color-light);
      background-color: var(--gray-layer);
      border-radius: 3px;
      padding: 17px;
      text-align: center;
    }

    &__catalog-title {
      padding: 14px 38px 14px 38px;
      font-size: 16px;
      text-align: center;
      display: block;
    }

    &__head-wrapper {
      display: flex;
      align-items: center;
    }

    &__catalog-list {
      display: none;

      &._opened {
        z-index: 10;
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        background-color: white;
        position: absolute;
        top: 0;
      }
    }

    &__item-wrapper {
      &.open {
        .hamburg-nav__link {
          svg {
            transform: rotate(-180deg);
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .hamburg-nav {
    &__body {
      &._opened {
        top: 0;
      }
    }

    &__button {
      width: 100%;
      border-radius: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 133%;
      text-align: center;
    }

    &__numb {
      margin-top: 20px;
    }

    &__link {
      font-size: 15px;
      line-height: 133%;

      &_title {
        font-weight: normal;
        font-size: 18px;
        line-height: 150%;
      }

      &_arrow {
        padding: 14px 19px;
        border-bottom: 1px solid var(--border-gray-color);
      }
    }

    &__item-icon {
      display: block;
      height: 23px;
    }

    &__item {
      padding: 0 19px;

      &_parent {
        padding: 0;
      }

      &_bottom {
        margin: 29px 0 50px;
      }
    }

    &__city {
      margin-bottom: 29px;
    }

    &__city-name {
      font-size: 14px;
    }

    &__children {
      padding: 20px 19px;
    }

    &__child {
      margin-bottom: 16px;
    }

    &__catalog-item {
      padding: 0 19px;
    }

    &__catalog-title {
      padding: 12px 0;
      font-size: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &__catalog-wrapper {
      width: 100%;
    }

    &__catalog-icon {
      display: block;
      margin-right: 10px;
    }

    &__catalog-text {
      display: block;
    }

    &__child-link {
      font-size: 15px;
      padding-left: 0;
    }

    &__catalog-list {
      &._opened {
        top: 0;
        z-index: 100;
        position: fixed;
      }
    }

    &__catalog-menu {
      font-weight: normal;
      font-size: 18px;
      padding: 12px 0;
    }

    &__catalog-back {
      padding: 17px 14px 17px 0;
    }

    &__button {
      &_tablet {
        display: none;
      }

      &_mobile {
        margin-top: 24px;
        border-radius: 3px;
      }
    }
  }
}