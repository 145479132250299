h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: var(--h-font);
  font-size: var(--h-size);
  font-weight: 500;
}

h1, h2, .h2 {
  line-height: 1.17em;
}

h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  line-height: 1.24em;
}

h5, .h5, h6, .h6 {
  font-size: 17px;
}

@include respond-up('medium') {
  h1 {
    --h-size: 30px;
  }

  h2, .h2 {
    --h-size: 24px;
  }

  h3, .h3 {
    --h-size: 19px;
  }

  h4, .h4 {
    --h-size: 18px;
  }
}

@include respond-down('small') {
  h1 {
    --h-size: 23px;
  }

  h2, .h2 {
    --h-size: 20px;
  }

  h3, .h3 {
    --h-size: 18px;
  }

  h4, .h4 {
    --h-size: 17px;
  }
}