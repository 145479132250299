.link-arrow {
  padding-right: 7px;
  position: relative;
  display: inline-flex;
  align-items: center;
  font-family: var(--font);
  font-weight: 500;
  font-size: 15px;
  line-height: 1.35em;
  color: var(--primary-color);
  white-space: nowrap;

  &_to-bottom {
    &::after {
      transform: rotate(90deg);
    }
  }

  &::before {
    content: '';
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;
    display: block;
    height: 1px;
    background-color: var(--t);
    transition: background-color var(--default-transition);
  }

  &::after {
    content: '';
    height: 10px;
    flex: 0 0 7px;
    margin-left: 6px;
    background: url('../images/svg/arrows/right-red.svg') no-repeat 50% 50% / contain;
    transition: transform .3s ease-in-out;
  }

  &:hover {
    &::before {
      background-color: var(--primary-color-opacity-light);
    }
  }
}