.index-section {
  background: #fff;
  border-radius: 3px;

  &__title {
    line-height: 117%;
    width: fit-content;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      right: 0;
      bottom: 50%;
      transform: translate3d(0, 70%, 0);
      height: 12px;
      width: 10px;
      background: url('../images/svg/arrows/right-red.svg') no-repeat 50% 50% / contain;
    }
  }

  &__catalog-list {
    display: flex;
    gap: 8px;
  }

  &__catalog-item {
    border-radius: 3px;
    overflow: hidden;
  }

  &__catalog-link {
    background: var(--background-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: background var(--default-transition);
  }

  &__catalog-name {
    font-family: var(--font);
    font-size: 17px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0;
    text-align: left;
    color: var(--black-color);
    transition: color var(--default-transition);
    padding: 22px 0 22px 20px;
  }

  &__catalog-picture {
    width: 128px;
    height: 65px;
  }
}

@include respond-up('large') {
  .index-section {
    padding: 22px 27px 27px 27px;
    &__catalog-list {
      flex-direction: column;
    }

    &__catalog-link {
      &:hover {
        background: var(--black-color);
        .index-section__catalog-name {
          color: #fff;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .index-section {
    &__title {
      font-size: 30px;
      padding-right: 19px;
    }

    &__catalog-list {
      margin-top: 22px;
    }
  }
}

@include respond('medium') {
  .index-section {
    &__catalog-list {
      flex-wrap: wrap;
    }

    &__catalog-item {
      &:not(:last-child) {
        flex: 0 0 calc(50% - 4px);
      }

      &:last-child {
        flex: 0 0 100%;
      }
    }
  }
}

@include respond-down('small') {
  .index-section {
    &__title {
      font-size: 23px;
      padding-right: 15px;
    }

    &__catalog-list {
      margin-top: 14px;
      flex-direction: column;
    }
  }
}