.tabs__items .steps__item {
  box-shadow: none;
  padding-left: 5px;
  margin-left: -5px;

  &:after{
    z-index: 70;
    right: 8px;
  }
}

.steps {
  overflow: hidden;

  &__icon {
    font-size: 15px;
    color: var(--button-border-color);
    margin-right: 25px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: -5px;
      left: -12px;
      border: 1px solid var(--button-border-color);
      border-radius: 50px;
      width: 30px;
      height: 30px;
    }
  }

  &__items {

    ._active {

      &:after {
        background-color: var(--border-gray-color);
        width: 5px;
        right: 0;
        left: auto;
        z-index: 55;
      }

      &:before {
        right: 4px;
      }

      .steps {
        &__icon {
          color: var(--primary-color);
        }
      }
    }

    .steps {
      &__link {
        position: relative;
        box-shadow: none;
        padding: 10px 36px 10px 45px;
        height: 74px;

        &:before, &:after {
          content: '';
          position: absolute;
          width: 43px;
          height: 1px;
          right: -12px;
          background-color: var(--border-gray-color);
          z-index: 50;
        }

        &:before {
          top: calc(50% - 1px);
          transform: rotate(-60deg);
          transform-origin: right;
          box-shadow: 0px 2px 0 2px white;
        }

        &:after {
          bottom: calc(50% - 1px);
          transform: rotate(60deg);
          transform-origin: right;
          box-shadow: 0px -2px 0 2px white;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .steps {
    overflow: scroll;
    background: white;
    border-radius: 3px 3px 0 0;

    &__items {
      ._active{
        &:before {
          left: 2px;
        }
      }

      .steps {
        &__link {
          padding: 10px 16px 10px 36px;
          height: 68px;

          &:before, &:after {
            right: -14px;
            width: 40px;
          }
        }
      }
    }
  }
}