.model-page {
  &__other-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

   &__other-link {
     padding-right: 7px;
   }
}

@include respond-up('large') {
  .model-page {
    &__container {
      padding-bottom: 70px;
    }

    &__title,
    &__other-head {
      margin: 70px 0 28px;
    }

    &__main {
      display: grid;
      grid-template-columns: repeat(12, 1fr);
      grid-gap: var(--gutter-large);
    }

    &__content {
      grid-column: 1/10;
    }

    &__bar {
      grid-column: 10/13;
    }

    &__model-price-box {
      position: sticky;
      top: 107px;
    }

    &__info {
      margin-top: var(--gutter-large);
    }
  }
}

@include respond-up('medium') {
  .model-page {
    &__other-link {
      &_mobile {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .model-page {
    &__container {
      padding-bottom: 45px;
    }

    &__title,
    &__other-head {
      margin: 45px 0 24px;
    }

    &__info {
      margin-top: var(--gutter-medium);
    }
  }
}

@include respond-down('medium') {
  .model-page {
    &__bar {
      display: none;
    }
  }
}

@include respond-down('small') {
  .model-page {
    &__container {
      padding-bottom: 30px;
    }

    &__other-link {
      &_desktop {
        display: none;
      }
    }

    &__title,
    &__other-head {
      margin: 30px 0 14px;
    }

    &__info {
      margin-top: var(--gutter-small);
    }
  }
}