.company-block {
  &__body {
    position: relative;
    width: 100%;
    color: #ffffff;
  }

  &__main {
    height: 100%;
    width: 100%;
    border-radius: 3px;
    background-image: url("/static/images/temp/about-img-24.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  &__wrapper {
    font-family: Roboto, sans-serif;
    font-style: normal;
    background-color: #ffffff;
    border-radius: 3px;
    display: flex;
  }

  &__tiles {
    display: flex;
  }

  &__tile {
    display: flex;
    flex-direction: column;
  }

  &__content {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 150%;
    color: #ffffff;
  }

  &__value {
    font-weight: 500;
    line-height: 117%;
    color: var(--primary-color);
    margin-bottom: 22px;
  }

  &__text {
    font-weight: 500;
    line-height: 124%;
    color: var(--black-color);
    margin-bottom: 14px;
  }
}

@include respond-up('large') {
  .company-block {
    &__body {
      margin-bottom: -62px;
      min-height: 600px;
    }

    &__main {
      min-height: 485px;
      padding: 101px 60px 192px;
    }

    &__header {
      margin-bottom: 24px;
    }

    &__wrapper {
      position: relative;
      top: -152px;
      width: 90%;
      left: 5%;
      min-height: 267px;
    }

    &__tile {
      padding: 40px 30px;
    }

    &__value {
      margin-bottom: 22px;
    }

    &__text {
      margin-bottom: 14px;
    }

    &__content {
      width: 46%;
    }
  }
}

@include respond-up('medium') {
  .company-block {
    &__tile {
      flex: 0 0 33%;
    }
    
    &__tiles {
      flex-direction: row;
    }

    &__content {
      font-size: 18px;
      line-height: 150%;
    }

    &__value {
      font-size: 30px;
      line-height: 117%;
      margin-bottom: 22px;
    }

    &__text {
      font-size: 19px;
      line-height: 124%;
      margin-bottom: 14px;
    }

    &__tile {
      &:not(:last-child) {
        border-right: 1px solid var(--border-gray-color);
      }
    }
  }
}

@include respond('medium') {
  .company-block {
    &__main {
      height: fit-content;
      padding: 70px 33px 95px 33px;
    }

    &__content {
      width: 61%;
    }
  }
}

@include respond-down('medium') {
  .company-block {
    &__body {
      margin-bottom: 45px;
    }

    &__main {
      border-radius: 3px 3px 0 0;
    }

    &__wrapper {
      position: relative;
      width: 100%;
      border-radius: 0 0 3px 3px;
    }

    &__header {
      margin-bottom: 24px;
    }

    &__tile {
      padding: 30px 19.5px;
    }

    &__value {
      margin-bottom: 20px;
    }

    &__text {
      margin-bottom: 14px;
    }
  }
}

@include respond-down('small') {
  .company-block {
    &__body {
      margin-bottom: 30px;
    }

    &__header {
      margin-bottom: 14px;
    }

    &__content {
      font-size: 17px;
      line-height: 25.5px;
    }

    &__main {
      min-height: 299px;
      background-position: 40%;
      padding: 40px 60px 62px 19px;
    }

    &__tiles {
      flex-direction: column;
    }

    &__tile {
      padding: 19px 20px;

      &:not(:last-child) {
        border-bottom: 1px solid var(--border-gray-color);
      }
    }

    &__value {
      margin-bottom: 14px;
      font-size: 23px;
    }

    &__text {
      margin-bottom: 10px;
      font-size: 18px;
    }
  }
}