.to-favorite {
  svg path {
    transition: fill var(--default-transition);
  }

  &:hover {
    svg path {
      fill: var(--primary-color);
    }
  }

  &_selected {
    svg path {
      fill: var(--primary-color);
    }
  }
}