.catalog-page {
  &__container {
  }

  &__content {
  }

  &__filter {
  }

  &__main {
  }

  &__after {
  }
}

@include respond-up('large') {
  .catalog-page {
    padding-bottom: 70px;
  }
}

@include respond-up('medium') {
  .catalog-page {
  }
}

@include respond('medium') {
  .catalog-page {
    padding-bottom: 45px;
  }
}

@include respond-down('small') {
  .catalog-page {
    padding-bottom: 30px;
  }
}