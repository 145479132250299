.layout {
  display: flex;
  justify-content: space-between;
  min-height: 100vh;
  flex-direction: column;

  &__sub-header {
    position: sticky;
    top: 0;
    z-index: var(--header-z-index);
  }
}