:root {
  --footer-name-height: 19;
}

.footer {
  background-color: var(--black-color);

  &__items,
  &__bottom-items {
    display: grid;
  }

  &__name {
    display: block;
    font-size: 16px;
    line-height: calc(var(--footer-name-height) / 16 * 1em);
    color: white;
  }

  &__children {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 4px;
  }

  &__link {
    display: inline-block;
    width: 100%;
    padding: 3px;
    margin: -3px;
    font-size: 15px;
    line-height: 1.35em;
    color: rgba(255, 255, 255, 0.5);
    transition: color var(--default-transition);
  }

  &__bottom {
    padding-bottom: 40px;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }

  &__bottom-item {
    line-height: 1.2em;
    color: rgba(255, 255, 255, 0.3);
  }

  &__policy {
    color: inherit;
    transition: color var(--default-transition);
    padding: 7px;
    margin: -7px;
  }

  &__r-class {
    color: white;
    border-bottom: 1px solid var(--t);
    transition: border-bottom-color var(--default-transition);
  }
}

@include respond-up('large') {
  .footer {
    &__main {
      padding: 90px 0;
    }

    &__items,
    &__bottom-items {
      grid-gap: 60px;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    &__bottom-item {
      &:first-child {
        grid-column: 1 / 3;
      }

      &:nth-child(2) {
        grid-column: 3 / 5;
      }
    }

    &__bottom {
      padding-top: 34px;
    }

    &__bottom-item {
      font-size: 14px;
    }

    &__link {
      &:hover {
        color: white;
      }
    }

    &__policy {
      &:hover {
        color: white;
      }
    }

    &__r-class {
      &:hover {
        border-bottom-color: rgba(255, 255, 255, 0.3);
      }
    }
  }
}

@include respond-up('medium') {
  .footer {
    &__name {
      svg {
        display: none;
      }
    }

    &__children {
      padding-top: 23px;
    }
  }
}

@include respond('medium') {
  .footer {
    &__main {
      padding: 80px 0 90px;
    }

    &__bottom {
      padding-top: 32px;
    }

    &__items,
    &__bottom-items {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &__items {
      grid-gap: 90px 50px;
    }

    &__item {
      &:last-child {
        grid-column: 2 / 4;
      }
    }

    &__bottom-items {
      grid-gap: 30px 50px;
    }

    &__bottom-item {
      &:first-child {
        grid-column: 1 / 3;
      }
    }
  }
}

@include respond-down('medium') {
  .footer {
    &__bottom-item {
      font-size: 13px;
    }
  }
}

@include respond-down('small') {
  .footer {
    &__main {
      padding: 50px 0 40px;
    }

    &__item {
      &:not(:first-child) {
        margin-top: 20px;
      }

      &:last-child {
        margin-top: 40px;
      }

      &.accordion {
        height: calc(var(--footer-name-height) * 1px);
        overflow: hidden;
        transition: height var(--default-transition);
      }
    }

    &__accordion {
      transition: height var(--default-transition);

      &.open {
        .footer {
          &__name {
            svg {
              transform: rotate(-180deg);
            }
          }
        }
      }
    }

    &__name {
      svg {
        margin-left: 5px;
        transition: transform var(--default-transition);
      }
    }

    &__children {
      padding-top: 20px;
    }

    &__bottom-items {
      grid-template-columns: 1fr;
      grid-gap: 20px;
    }

    &__bottom {
      padding-top: 30px;
    }
  }
}