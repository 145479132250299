.price-box {
  background-color: white;

  &__price {
    font-weight: 500;
    line-height: 1.17em;
  }

  &__button {
    max-height: 44px;

    svg.icon-cart-white {
      height: 18px;
      width: 18px;
      margin-right: 12px;
    }
  }

  &__bonus-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__bonus-question {
    display: flex;
    margin-left: 9px;
  }
}

@include respond-up('large') {
  .price-box {
    border-radius: 3px;

    &__admin-prices-button {
      margin-top: 20px;
      width: 100%;
    }

    &__container {
      padding: 27px 33px 24px;
    }

    &__wrap {
      display: flex;
      justify-content: space-between;
    }

    &__main {
      padding-right: 20px;
    }

    &__credit {
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid var(--border-gray-color);
    }

    &__stock {
      margin-top: 27px;
    }

    &__actions {
      margin-top: 14px;
    }

    &__buttons {
      margin-top: 18px;
    }

    &__button {
      width: 100%;

      &:not(:first-child) {
        margin-top: 12px;
      }
    }

    &__bonus-box {
      margin-top: 24px;
    }

    &__credit-link {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 14px;

      svg.icon-credit-card {
        margin-right: 11px;
      }
    }

    &__favorite {
      align-self: flex-start;
    }
  }
}

@include respond-up('medium') {
  .price-box {
    &__price {
      font-size: 28px;
    }

    &__kit {
      margin-top: 4px;
      font-size: 15px;
      line-height: 1.35em;
    }
  }
}

@include respond('medium') {
  .price-box {
    &__container {
      display: flex;
      justify-content: space-between;
      padding: 30px;
    }

    &__wrap {
      padding-right: 20px;
    }

    &__actions {
      justify-content: flex-end;
    }

    &__stock {
      margin-top: 22px;
    }

    &__actions {
      margin-top: 5px;
      align-items: center;
    }

    &__buttons {
      grid-template-columns: 144px 144px;
      grid-gap: 16px;
    }

    &__bonus-box {
      flex: 0 0 100%;
      justify-content: flex-end;
      margin-top: 30px;
    }

    &__counter {
      margin-right: 30px;
    }
  }
}

@include respond-down('medium') {
  .price-box {
    &__credit,
    &__favorite {
      display: none;
    }

    &__actions {
      display: flex;
      flex-wrap: wrap;
    }

    &__buttons {
      display: grid;
    }
  }
}

@include respond-down('small') {
  .price-box {
    &__container {
      padding: 19px;
    }

    &__price {
      font-size: 20px;
    }

    &__kit {
      font-size: 13px;
      line-height: 1.2em;
      color: var(--text-color);
    }

    &__stock {
      margin-top: 9px;
    }

    &__actions {
      align-items: flex-end;
      justify-content: space-between;
      margin-top: 24px;
    }

    &__counter {
      order: 1;
      margin-right: 5px;
    }

    &__bonus-box {
      order: 2;
      justify-content: flex-end;
    }

    &__buttons {
      order: 3;
      flex: 0 0 100%;
      grid-template-columns: 1fr 1fr;
      grid-gap: 16px;
      margin-top: 20px;
    }

    &__button {
      padding: 12px 16px;
    }
  }

  @include respond-down('x-small') {
    .price-box {
      &__buttons {
        grid-template-columns: 1fr;
      }
    }
  }
}