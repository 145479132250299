.link-mini {
  position: relative;
  font-family: var(--font);
  font-size: 13px;
  line-height: 1.2em;
  color: var(--black-color);
  border-bottom: 1px dashed rgba(31, 33, 41, 0.6);
  transition: color var(--default-transition);

  &:hover {
    color: #555D70;
  }
}