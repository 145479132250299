.cart-success-product {
  display: flex;
  justify-content: space-between;

  &__image-container {

  }

  &__image {

  }

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__name-container {

  }

  &__name {
    line-height: 1.33;
  }

  &__price-container {
    display: flex;
    align-items: center;
  }

  &__price {
    margin-right: 4px;
    font-weight: 500;
    line-height: 1.17;
  }

  &__count {
    line-height: 1.2;
  }

  &__result-container {
    display: flex;
    height: max-content;
    align-items: center;
  }

  &__result-bonus {
    height: max-content;
  }

  &__result {
    margin-left: auto;
    display: flex;
    align-items: center;
  }

  &__result-count {
    line-height: 1.33;
  }

  &__result-price {
    font-weight: 500;
    line-height: 1.17;
  }
}

@include respond-up('large') {
  .cart-success-product {
    padding: 22px 0;

    &__image-container {
      flex: 0 0 121px;
      min-width: 121px;
      margin-right: 20px;
    }

    &__image {

    }

    &__content {
      flex: 1 1 auto;
    }

    &__name-container {

    }

    &__name {
      font-size: 15px;
      margin-bottom: 6px;
    }

    &__price-container {

    }

    &__price {
      font-size: 18px;
    }

    &__count {
      font-size: 14px;
    }

    &__result-container {

    }

    &__result-bonus {
      margin-right: 64px;
    }

    &__result {

    }

    &__result-count {
      margin-right: 27px;
      font-size: 15px;
    }

    &__result-price {
      font-size: 20px;
    }
  }
}

@include respond('medium') {
  .cart-success-product {
    padding: 22px 0;

    &__image-container {
      flex: 0 0 121px;
      min-width: 121px;
      margin-right: 20px;
    }

    &__image {

    }

    &__content {
      flex: 1 1 auto;
    }

    &__name-container {
      margin-right: 92px;
    }

    &__name {
      font-size: 15px;
      margin-bottom: 8px;
    }

    &__price-container {

    }

    &__price {
      font-size: 18px;
    }

    &__count {
      font-size: 14px;
    }

    &__result-container {
      flex-direction: column-reverse;
      align-items: flex-start;
    }

    &__result-bonus {
      margin-top: 32px;
    }

    &__result {

    }

    &__result-count {
      white-space: nowrap;
      margin-right: 27px;
      font-size: 15px;
    }

    &__result-price {
      white-space: nowrap;
      font-size: 20px;
    }
  }
}

@include respond-down('small') {
  .cart-success-product {
    padding: 20px 0;

    &__image-container {
      flex: 0 0 74px;
      min-width: 74px;
      margin-right: 20px;
    }

    &__image {

    }

    &__content {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    }

    &__name-container {
      border-bottom: 1px solid var(--border-gray-color);
      padding-bottom: 20px;
      margin-bottom: 14px;
    }

    &__name {
      font-size: 14px;
      margin-bottom: 5px;
    }

    &__price-container {

    }

    &__price {
      font-size: 16px;
    }

    &__count {
      font-size: 13px;
    }

    &__result-container {
      justify-content: space-between;
    }

    &__result-bonus {

    }

    &__result {

    }

    &__result-count {
      white-space: nowrap;
      margin-right: 20px;
      font-size: 15px;
    }

    &__result-price {
      white-space: nowrap;
      font-size: 18px;
    }
  }
}