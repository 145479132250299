.filter-sort {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1px;

  &__total {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 133%;
    color: var(--text-color-light);
  }

  &__field {
    //max-width: 170px;
  }
}

@include respond-up('large') {
  .filter-sort {
  }
}

@include respond-up('medium') {
  .filter-sort {
    padding: 25px 24px;
  }
}

@include respond('medium') {
  .filter-sort {

  }
}

@include respond-down('medium') {
  .filter-sort {

  }
}

@include respond-down('small') {
  .filter-sort {
    padding: 15px 20px;

    &__total {
      display: none;
    }
  }
}