.service-prices {
  &__list {
    display: flex;
    flex-direction: column;
  }

  &__accordion {
    display: flex;
    background: var(--background-color);
    border-radius: 3px;
    overflow: hidden;
    transition: height var(--default-transition);
  }

  &__item {
    &.open {
      .service-prices {
        &__name {
          color: var(--primary-color);
        }

        &__arrow {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__link {
    display: flex;
    position: relative;
  }

  &__name {
    transition: color var(--default-transition);
  }

  &__arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px;
    transition: transform var(--default-transition);
    position: absolute;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    color: var(--text-color);
  }
}

@include respond-up('large') {
  .service-prices {
    &__price {
      flex: 0 0 163px;
    }
  }
}

@include respond-up('medium') {
  .service-prices {
    &__list {
      row-gap: 6px;
      margin-top: 20px;
    }

    &__link {
      padding: 20px 30px;
      align-items: center;
      justify-content: space-between;
    }

    &__arrow {
      top: 24px;
      right: 30px;
    }

    &__content {
      padding: 10px 30px 30px;
    }
  }
}

@include respond('medium') {
  .service-prices {
    &__price {
      flex: 0 0 calc(var(--grid-column2) - 57px);
    }
  }
}

@include respond-down('medium') {
  .service-prices {

  }
}

@include respond-down('small') {
  .service-prices {
    &__list {
      row-gap: 4px;
      margin-top: 12px;
    }

    &__link {
      padding: 16px 20px;
      flex-direction: column;
      row-gap: 2px;
    }

    &__name {
      &.texts_seventeen {
        font-size: 16px;
        line-height: 120%;
      }
    }

    &__price {
      &.texts_seventeen-medium {
        font-size: 16px;
        line-height: 133%;
      }
    }

    &__arrow {
      top: 20px;
      right: 20px;
    }

    &__content {
      padding: 6px 20px 16px;
    }
  }
}