.record-for-service {
  position: relative;

  &._loading {
    .service {
      &__box {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 15;
          pointer-events: auto;
          //background-color: rgba(120, 123, 131, .4);
        }
      }
    }
  }

  &__box {
    position: relative;
    background-color: white;
  }

  &__wrapper {
    display: grid;
    width: 100%;
    grid-template-columns: 100%;
    grid-template-rows: repeat(4, max-content);
    grid-template-areas: "services" "service-point" "contacts" "result" "confirm"
  }

  &__head {
    display: flex;
    margin-right: -20px;
  }

  &__head-item {
    padding-right: 20px;
    padding-top: 20px;
  }

  &__heading {
    display: flex;
    align-items: center;
  }

  &__heading-number {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--button-border-color);
    border-radius: 100px;
    font-weight: 700;
    color: var(--primary-color);
    line-height: 1.17;
    font-size: 23px;
    height: 46px;
    min-width: 46px;
  }

  &__link {
    display: flex;
    align-items: center;
  }

  &__services {
    grid-area: services;
    background-color: white;
  }

  &__service-point {
    grid-area: service-point;
    background-color: white;
  }

  &__contacts {
    grid-area: contacts;
    background-color: white;
  }

  &__result {
    grid-area: result;
    background-color: white;
  }

  &__confirm {
    grid-area: confirm;
  }
}

@include respond-up('large') {
  .record-for-service {
    padding-bottom: 69px;

    &__wrapper {
      grid-column-gap: var(--gutter-large);
      grid-row-gap: var(--gutter-large);
      grid-template-columns: 1fr (278/1196)*100%;
      grid-template-areas: "services result" "service-point result" "contacts result" "confirm result"
    }

    &__head {
      margin-bottom: 30px;
    }

    &__heading-number {
      margin-right: 20px;
    }

    &__box {
      padding: 30px 50px 40px;
    }

    &__result {
      position: sticky;
      top: 107px;
      height: max-content;
    }

    &__confirm {
      margin-top: 12px;
    }
  }
}


@include respond('medium') {
  .record-for-service {
    padding-bottom: 45px;

    &__wrapper {
      grid-column-gap: var(--gutter-medium);
      grid-row-gap: var(--gutter-medium);
    }

    &__head {
      margin-bottom: 19px;
    }

    &__heading-number {
      margin-right: 20px;
    }

    &__box {
      padding: 20px 30px;

      &:nth-child(2) {
        padding: 20px 30px 24px 30px;
      }
    }

    &__confirm {
      margin-top: 6px;
    }

    &__result {
      display: none;
    }
  }
}


@include respond-down('small') {
  .record-for-service {
    padding-bottom: 30px;

    &__wrapper {
      grid-column-gap: var(--gutter-small);
      grid-row-gap: var(--gutter-small);
    }

    &__head {
      margin-bottom: 20px;
      flex-wrap: wrap;

      &:nth-child(2) {
        margin-bottom: 20px;
      }

      &:nth-child(3) {
        margin-bottom: 12px;
      }
    }

    &__heading-number {
      margin-right: 14px;
    }

    &__box {
      padding: 14px 19px 20px 19px;
    }

    &__confirm {
      margin-top: 6px;
    }

    &__result {
      display: none;
    }
  }
}
