.record-for-service-confirm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@include respond-up('medium') {
  .record-for-service-confirm {
    &__button {
      margin-bottom: 23px;
      min-width: 314px;
    }
  }
}

@include respond-down('small') {
  .record-for-service-confirm {
    &__button {
      margin-bottom: 14px;
      width: 100%;
    }
  }
}