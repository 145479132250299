.preloader {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, .6);
  animation: .4s linear 0s opacity;

  &._simple {
    .preloader__inner {
      display: none;
    }
  }

  &__inner {

  }
  &__bubbles {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: calc(var(--preloader-size) * 2);
  }

  &__bubble {
    flex: 0 0 calc(var(--preloader-size) / 2);
    width: calc(var(--preloader-size) / 2);
    height: calc(var(--preloader-size) / 2);
    margin: 0 calc(var(--preloader-size) / 8);
    border-radius: 100%;
    background-color: var(--primary-color);
    animation: bubbles 1.2s ease-in-out 0s infinite both;
    opacity: .9;

    &:first-child {
      animation-delay: -0.3s;
    }

    &:nth-child(2) {
      animation-delay: calc(-0.3s / 2);
    }
  }
}

@keyframes bubbles {
  0%, 100% {
    opacity: 0;
    transform: scale(0.2);
  }
  40% {
    opacity: 1;
    transform: scale(1.0);
  }
}