.button, button {
  display: inline-block;
  padding: 12px 25px;
  margin: 0;
  border: none;
  border-radius: 3px;
  transition: background-color var(--default-transition), color var(--default-transition);
  background: var(--primary-color);
  text-align: center;
  color: white;
  font-family: var(--font);
  font-weight: 500;
  font-size: 15px;
  line-height: 1.35em;
  cursor: pointer;
  white-space: nowrap;

  &:disabled {
    background-color: var(--button-disabled-color);
    cursor: no-drop;
  }

  &_expand, &_wide {
    width: 100%;
  }

  &_height {
    height: var(--default-input-height);
  }

  &_width {
    min-width: 200px
  }

  &_small {
    padding: 6px 20px;
  }

  &_transparent {
    background-color: var(--t);
    border: 1px solid var(--primary-color-opacity-light);
    transition: border-color var(--default-transition);
    color: var(--primary-color);

    &:disabled {
      background-color: var(--t);
      cursor: no-drop;
    }

    &.button {
      &_gray {
        font-weight: 400;
        border: 1px solid var(--button-border-color);

        &:hover {
          border: 1px solid var(--button-border-color-hover);
        }
      }
    }
  }

  &_light {
    background-color: var(--t);
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 133%;
    text-align: center;
    color: var(--primary-color);
    width: fit-content;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      left: 25px;
      bottom: 12px;
      width: calc(100% - 50px);
      height: 1px;
      transition: background-color .4s ease-in-out;
    }
  }

  &_gray {
    color: var(--black-color);
  }

  &_black {
    background-color: var(--black-color);
  }
}

@include respond-up('large') {
  .button {
    &:hover {
      background: var(--primary-color-hover);
    }

    &_transparent {
      &:hover {
        border: 1px solid var(--primary-color-opacity);
        background: var(--t);
      }
    }

    &_black {
      &:hover {
        background-color: var(--black-color);
      }
    }

    &_light {
      &:hover {
        background-color: var(--t);

        &:before {
          background-color: var(--primary-color);
        }
      }
    }
  }
}