.cart-come-back {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__wrapper {
    display: flex;
    align-items: center;
  }

  &__text {
    font-weight: 500;
    line-height: 1.24;
  }

  &__button {

  }

  &__closer {
    display: flex;
    padding: 10px;
    margin: -10px;
  }

  &__closer-icon {
    display: flex;
    max-height: 16px;
    width: 16px;
  }
}

@include respond-up('large') {
  .cart-come-back {
    padding: 22px 50px;

    &__text {
      font-size: 19px;
      margin-right: 40px;
    }
  }
}

@include respond('medium') {
  .cart-come-back {
    padding: 22px 30px;

    &__text {
      font-size: 19px;
      margin-right: 30px;
    }
  }
}

@include respond-down('small') {
  .cart-come-back {
    padding: 13px 20px;

    &__text {
      font-size: 18px;
      margin-right: 17px;
    }

    &__button {
      border: none;
      font-size: 15px;
      padding: 10px;

      &:hover {
        border: none;
      }
    }
  }
}