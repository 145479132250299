.modal-information {
  &__content {
    margin: -30px;
    width: 900px;
  }

  &__tabs {
    position: sticky;
    top: 0;
    z-index: 150;
    background: white;

    .tabs {
      &__link {
        padding: 0 21px;
      }
    }
  }

  &__content-main {
    margin: 45px 0 0;
    width: 100%;
    background: var(--black-color);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }

  &__content-main-heading {
    color: white;
    font-size: 30px;
    font-weight: bold;
    padding: 30px 0 33px;
    text-align: center;
  }

  &__content-main-wrapper {
    margin: 0 60px 60px;
    display: none;

    &._active {
      display: block;
    }
  }

  &__content-main-image {
    width: 55%;
  }

  &__content-blocks-wrapper {
    display: flex;
    justify-content: space-between;
    padding-top: 57px;
  }

  &__content-blocks-nav-wrapper {
    flex-basis: 50px;
    padding-top: 90px;
    position: relative;
  }

  &__content-blocks-nav-wrap {
    position: sticky;
    top: 150px;
  }

  &__content-blocks-nav {
    display: flex;
    flex-direction: column;
  }

  &__content-blocks-nav-item {
    color: var(--button-disabled-color);
    font-weight: 900;
    margin-bottom: 13px;

    &:last-child {
      margin-bottom: 0;
    }

    &._active {
      color: var(--black-color);
    }
  }

  &__content-block {
    display: flex;
    padding-top: 86px;
    padding-bottom: 50px;

    picture {
      display: initial;
    }
  }

  &__content-block-text {
    max-width: 308px;
    margin-right: 80px;

    &._opened {
      .modal-information {
        &__content-block-text-more {
          display: block;
        }

        &__content-block-text-more-link {
          &:after {
            transform: rotate(270deg);
          }
        }
      }
    }
  }

  &__content-block-text-heading-big {
    font-weight: 900;
    font-size: 40px;
    padding-bottom: 15px;
  }

  &__content-block-text-heading {
    font-weight: 500;
    font-size: 19px;
    padding-bottom: 15px;
  }

  &__content-block-text-typeset {
    font-weight: 400;
    font-size: 16px;
    color: var(--text-color);
  }

  &__content-block-text-typeset-p {
    padding-bottom: 10px;
  }

  &__content-block-pictures {
    margin-top: -86px;
    margin-bottom: 25px;
  }

  &__content-block-text-more-link {
    margin: 18px 0 22px;
  }

  &__content-block-text-more {
    display: none;
  }

  &__content-block-v-list {
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding: 20px 38px 20px 26px;
    background-color: var(--gray-layer);
  }

  &__content-block-v-item {
    margin-bottom: 4px;
    font-weight: 500;
    display: flex;
  }

  &__content-block-v {
    margin-right: 40px;
    color: var(--primary-color);
    flex-basis: 50px;
  }

  &__content-block-kmh {
    white-space: nowrap;
  }

  &__content-block-text-more-disks {
    display: flex;
    justify-content: space-between;
  }

  &__content-block-text-more-disk {
    display: flex;
    flex-direction: column;
    flex-basis: 33%;
    text-align: center;
  }

  &__content-block-overhang-picture {
    padding-bottom: 18px;
  }

  &__content-block-overhang {
    font-size: 13px;
  }

  &__form-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 65px;
    margin-bottom: 30px;
    padding: 0 100px;
  }

  &__form-description-container {
    flex: 0 0 42%;
    margin-top: 20px;
  }

  &__form-description-icon {
    margin-bottom: 30px;
  }

  &__form-description-title {
    font-size: 24px;
    margin-bottom: 20px;
    line-height: calc(28/24);
  }

  &__form-description-description {
    font-weight: 400;
    font-size: 16px;
    color: var(--text-color);
  }

  &__form-container {
    flex: 0 0 44%;
  }

  &__form-data {
    margin-bottom: 30px;

    button {
      margin-top: 20px;
      width: 100%;
    }

    div[data-name="consultation[name]"] {
      margin-bottom: 20px;
    }
  }

  &__form-policy {
    margin-top: 16px;
    display: inline-block;
    line-height: calc(15.6/13);
    font-size: 13px;
    text-align: center;
  }

  &__form-success {

  }
}

.tabs {
  &__name {
    &._mobile {
      display: none;
    }

    &._desktop {
      display: initial;
    }
  }
}

@include respond-down('medium') {
  .modal-information {
    &__content {
      width: 690px;
    }

    &__content-main {
      margin-top: 40px;
    }

    &__content-main-wrapper {
      margin: 0 40px 40px;
    }

    &__content-main-image {
      width: 70%;
    }

    &__content-blocks {
      padding-top: 20px;
    }

    &__content-blocks-wrapper {
      flex-direction: column;
      padding-top: 0;
    }

    &__content-blocks-nav {
      flex-direction: row;
      justify-content: center;
    }

    &__content-blocks-nav-item {
      margin: 0 8px;
    }

    &__content-blocks-nav-wrapper {
      padding: 0;
      flex-basis: auto;
    }

    &__content-blocks-nav-wrap {
      border-bottom: 1px solid var(--border-gray-color);
      padding: 15px 40px;
      margin: 0 -40px;
    }

    &__form-wrapper {
      padding: 0 30px;
    }
  }
}

@include respond-down('small') {

  .tabs__name {
    &._mobile {
      display: initial;
    }

    &._desktop {
      display: none;
    }
  }

  .modal-information {
    &__content {
      width: 375px;
      margin: -30px;
    }

    &__content-main {
      margin-top: 20px;
    }

    &__content-block {
      flex-direction: column-reverse;
      padding-top: 20px;

      picture {
        text-align: center;
      }
    }

    &__content-blocks-wrapper {
      position: relative;
    }

    &__content-blocks-nav-wrap {
      background: white;
    }

    &__content-blocks-nav-wrapper {
      position: sticky;
      top: 47px;
    }

    &__content-block-pictures {
      height: 324px;
      margin-bottom: 16px;
      margin-top: 0;

      img {
        max-height: 100%;
      }
    }

    &__content-block-text {
      max-width: initial;
      margin-right: 0;
    }

    &__content-main-heading {
      font-size: 23px;
      padding: 16px 10px 26px;
    }

    &__content-block-text-heading-big {
      font-size: 28px;
      padding-bottom: 12px;
    }

    &__content-block-text-heading {
      font-size: 18px;
      padding-bottom: 12px;
    }

    &__content-block-text-typeset {
      font-size: 15px;
    }

    &__content-main-wrapper {
      margin: 0 20px 20px;
    }

    &__content-blocks-nav-wrap {
      padding: 15px 20px;
      margin: 0 -20px;
    }



    &__form-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 40px;
      margin-bottom: 0;
      padding: 0;
    }

    &__form-description-container {
      flex: 0 0 100%;
      margin-top: 0;
      margin-bottom: 18px;
    }

    &__form-description-icon {
      display: none;
    }

    &__form-description-title {
      font-size: 20px;
      font-weight: 700;
      text-align: center;
      margin-bottom: 10px;
      line-height: calc(28/24);
    }

    &__form-description-description {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      color: var(--text-color-light-2);
    }

    &__form-container {
      flex: 0 0 44%;
    }

    &__form-data {
      margin-bottom: 30px;

      button {
        margin-top: 20px;
        width: 100%;
      }

      div[data-name="consultation[name]"] {
        margin-bottom: 20px;
      }
    }

    &__form-policy {
      margin-top: 16px;
      display: inline-block;
      line-height: calc(15.6/13);
      font-size: 13px;
    }

    &__form-success {

    }
  }
}