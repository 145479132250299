.cart-contacts {
  &__input-list {
    display: flex;
  }

  &__input-item {

  }

  &__input-item-container {

  }

  &__input-label {
    &.required {
      &::after {
        content: '*';
        color: var(--black-color);
      }
    }
  }

  &__input {
    &.required {

    }
  }

  &__errors {

  }

  &__error {

  }

  &__info {
    color: var(--text-color-light-2);
    line-height: 1.2;
  }
}

@include respond-up('large') {
  .cart-contacts {
    &__title {
      font-size: 24px;
      margin-bottom: 28px;

      &::before {
        margin-right: 20px;
      }
    }

    &__input-list {
      margin: 0 -11px 21px;
    }

    &__input-item {
      padding: 0 11px;
      flex: 0 0 33.3333%;
    }

    &__input-item-container {

    }

    &__input-label {
      &.required {

      }
    }

    &__input {
      &.required {

      }
    }

    &__errors {

    }

    &__error {

    }

    &__info {
      font-size: 13px;
    }
  }
}

@include respond('medium') {
  .cart-contacts {

    &__title {
      font-size: 24px;
      margin-bottom: 18px;

      &::before {
        margin-right: 20px;
      }
    }

    &__input-list {
      margin: 0 -11px 11px;
    }

    &__input-item {
      padding: 0 11px;
      flex: 0 0 33.3333%;
    }

    &__input-item-container {

    }

    &__input-label {
      &.required {

      }
    }

    &__input {
      &.required {

      }
    }

    &__errors {

    }

    &__error {

    }

    &__info {
      font-size: 13px;
    }
  }
}

@include respond-down('small') {
  .cart-contacts {
    &__title {
      font-size: 20px;
      margin-bottom: 12px;

      &::before {
        margin-right: 14px;
      }
    }

    &__input-list {
      margin-bottom: -4px;
      flex-direction: column;
    }

    &__input-item {
      flex: 1 1 100%;
      margin-bottom: 13px;
    }

    &__input-item-container {

    }

    &__input-label {
      &.required {

      }
    }

    &__input {
      &.required {

      }
    }

    &__errors {

    }

    &__error {

    }

    &__info {
      font-size: 13px;
    }
  }
}