.index-page {

  &__action-link {
    flex: 1 0 100%;
    position: relative;
    padding-bottom: 46%;
  }

  &__picture{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  &__offer {
    position: relative;
  }

  &__offer-item {
    position: relative;
  }

  &__image {
    object-fit: cover;
    border-radius: 3px;
    width: 100%;
    height: 100%;
  }

  &__popular-head {
    display: flex;
    justify-content: space-between;
  }

  &__popular-disks {
    position: relative;
  }

  &__offer-button {
    position: absolute;
  }

  &__popular-arrow {
    position: absolute;
    top: 50%;

    &_left {
      left: 0;
      transform: translate3d(-50%, -50%, 0);
    }

    &_right {
      right: 0;
      transform: translate3d(50%, -50%, 0);
    }
  }

  &__list-wrapper {
    position: relative;
  }


  &__offer-image-wrapper {
    display: flex;
  }

  &__selection {
    width: 100%;
  }

  &__counter {
    position: absolute;
    background: rgba(0, 0, 0, 0.70);
    border-radius: 3px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
  }
}

@include respond-up('large') {
  .index-page {
    &__wheel-catalog-link {
      display: none;
    }

    &__popular-tyres {
      margin-top: 70px;
    }

    &__popular-tyres,
    &__popular-disks {
      //что-то вылезает за край, но на планшете диски в слайдере
      //overflow: hidden;
      margin-bottom: 70px;
    }

    &__action-link {
      &:hover {
        .link-arrow {
          &::after {
            transform: translateX(5px);
          }
        }
      }
    }

    &__offer-wrapper {
      margin-bottom: 70px;
    }

    &__popular-arrow {
      display: none;
    }

    &__popular-head {
      margin-bottom: 28px;
    }

    &__popular-items {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    &__popular-items-disks {
      /*display: flex;*/
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    &__popular-item {
      &:nth-child(5), &:nth-child(10) {
        border-right: none;
      }
    }

    &__selection {
      margin-right: 28px;
      grid-column: 1/6;
    }

    &__offer {
      grid-column: 6/13;
    }

    &__image {
      width: 100%;
    }

    &__offer-button {
      bottom: 21px;
      left: 29px;
    }
  }
}

@include respond-up('medium') {
  .index-page {
    &__arrow {
      position: absolute;
      top: 50%;

      &_left {
        left: 0;
        transform: translate3d(-50%, -50%, 0);
      }

      &_right {
        right: 0;
        transform: translate3d(50%, -50%, 0);
      }
    }

    &__counter {
      bottom: 14px;
      left: 14px;
      font-size: 15px;
      line-height: 133%;
    }
  }
}

@include respond('medium') {
  .index-page {
    &__wheel-catalog-link {
      grid-column: 1/7;
      padding: 22px 27px;
      margin-top: 24px;
    }

    &__wheel-catalog-text {
      font-size: 30px;

      &:after {
        right: -22px;
        transform: translate3d(0, 70%, 0);
      }
    }

    &__popular-tyres {
      //что-то вылезает за край, но на планшете диски в слайдере
      //overflow: hidden;
      margin-top: 45px;
    }

    &__selection,
    &__offer {
      grid-column: 1/7;
    }

    &__selection {
      padding: 22px 27px 27px;
    }

    &__offer {
      margin-top: 25px;
    }
  }
}

@include respond-down('medium') {
  .index-page {
    margin-bottom: 45px;

    &__wheel-catalog-link {
      background: #fff;
      display: flex;
      align-items: center;
      border-radius: 3px;
    }

    &__wheel-catalog-text {
      width: fit-content;
      position: relative;

      font-family: var(--font);
      font-style: normal;
      font-weight: 500;
      line-height: 117%;

      &::after {
        content: '';
        position: absolute;
        bottom: 50%;
        height: 12px;
        width: 10px;
        background: url('../images/svg/arrows/right-red.svg') no-repeat 50% 50% / contain;
      }
    }

    &__offer-wrapper {
      margin-bottom: 45px;
    }

    &__selection {
      background: #fff;
      border-radius: 3px;
    }

    &__popular-head {
      margin-bottom: 24px;
    }

    &__popular-tyres, &__popular-disks {
      margin-bottom: 45px;
    }

    &__popular-item {
      &:nth-child(n+7) {
        display: none;
      }
    }


    &__popular-disks {
      position: relative;

      .product-list__items {
        display: block;
      }
    }

    &__offer {
      flex: 0 0 100%;
    }

    &__image {
      width: 100%;
    }

    &__offer-button {
      bottom: 22px;
      left: 46px;
    }
  }
}

@include respond-down('small') {
  .index-page {
    margin-bottom: 30px;

    &__wheel-catalog-link {
      grid-column: 1/5;
      padding: 20px;
      margin-top: 20px;
    }

    &__wheel-catalog-text {
      font-size: 23px;

      &:after {
        right: -18px;
        transform: translate3d(0, 60%, 0);
      }
    }

    &__selection,
    &__offer {
      grid-column: 1/5;
    }

    &__services {
    }

    &__popular-tyres {
      margin-top: 30px;
    }

    &__offer-wrapper {
      margin-bottom: 30px;
    }

    &__popular-head {
      margin-bottom: 14px;
    }

    &__popular-item {
      &:nth-child(n+7) {
        display: none;
      }
    }

    &__list-wrapper {
      margin-right: -10px;
    }

    &__popular-tyres, &__popular-disks {
      margin-bottom: 30px;
    }

    &__popular-arrow {
      display: none;
    }

    &__popular-disks {
      position: relative;

      .product-list__items {
        display: flex;
        overflow: scroll;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    &__selection {
      padding: 20px;
    }

    &__offer {
      margin-top: 20px;
    }

    &__offer-button {
      bottom: 20px;
      left: 20px;
    }

    &__arrow {
      display: none;
    }

    &__counter {
      bottom: 6px;
      left: 6px;
      font-size: 14px;
      line-height: 120%;
    }
  }
}
