.steps-contacts {
  &__description {
    font-weight: normal;
    font-size: 13px;
    line-height: 1.2em;
    background-color: var(--gray-layer);
    color: var(--text-color-light);
  }
}

@include respond-up('large') {
  .steps-contacts {
    &__description {
      padding: 12px 17px;
      margin-top: 40px;
      max-width: 468px;
    }
  }
}

@include respond-up('medium') {
  .steps-contacts {

  }
}

@include respond('medium') {
  .steps-contacts {

  }
}

@include respond-down('medium') {
  .steps-contacts {
    &__description {
      display: none;
    }
  }
}

@include respond-down('small') {
  .steps-contacts {

  }
}