.vacancies-list {
  display: flex;
  flex-direction: column;
  row-gap: var(--grid-gap);
}

@include respond-up('large') {
  .vacancies-list {

  }
}

@include respond-up('medium') {
  .vacancies-list {

  }
}

@include respond('medium') {
  .vacancies-list {

  }
}

@include respond-down('medium') {
  .vacancies-list {

  }
}

@include respond-down('small') {
  .vacancies-list {

  }
}