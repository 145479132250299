.arrows-slider {
  display: flex;
  justify-content: space-between;

  &__item {
  }

  &__icon {
    width: 45px;
    height: 45px;
  }

  &__link {
    display: flex;

    svg path {
      transition: fill var(--default-transition);
    }

    &:hover {
      svg path {
        fill: var(--primary-color);
      }
    }
  }
}

@include respond-up('large') {
  .arrows-slider {
    &__item {

    }

    &__left {

    }

    &__right {

    }

    &__link {

    }


  }
}