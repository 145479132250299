.vue-preloader {
  position: fixed;
  bottom: 60px;
  left: 3%;
  z-index: 10000;
  width: var(--body-max-width);
  transform: translate(0);

  &__content {
    background-color: #fff;
    padding: 15px 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    font-size: 18px;
    margin-right: 25px;
    font-weight: 500;
  }

  &__icon {

  }
}

.vue-preloader-enter-active, .vue-preloader-leave-active {
  transition: all .5s;
}
.vue-preloader-enter, .vue-preloader-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
  transform: translate(-40px);
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 3em;
  height: 3em;
}
.loader {
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 0.3em solid rgba(255, 255, 255, 0.1);
  border-right: 0.3em solid rgba(255, 255, 255, 0.1);
  border-bottom: 0.3em solid rgba(255, 255, 255, 0.1);
  border-left: 0.3em solid var(--black-color);
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@include respond-down('medium'){
  .vue-preloader {
    bottom: 30px;
  }
  .vue-preloader-enter, .vue-preloader-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
    transform: translate(0, 30px);
  }
}

@include respond('medium'){
  .vue-preloader {
    right: var(--spacer-medium);
    left: var(--spacer-medium);
  }
}

@include respond-down('small'){
  .vue-preloader {
    right: var(--spacer-small);
    left: var(--spacer-small);
  }
}