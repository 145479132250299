.pay-and-delivery {
  &__item {
    background-color: white;
  }

  &__heading {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-gray-color);
  }

  &__heading-number {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--button-border-color);
    border-radius: 100px;
    font-weight: 700;
    color: var(--primary-color);
    line-height: 1.17;
    font-size: 23px;
    height: 46px;
    min-width: 46px;
    margin-right: 20px;
  }

  &__see-also {
    display: none;
  }
}

@include respond-up('large') {
  .pay-and-delivery {
    padding-bottom: 70px;

    &__payment {
      margin-bottom: 28px;
    }

    &__wrapper {
      grid-column: 1/10;
    }

    &__see-also {
      grid-column: 10/13;
      display: block;
      position: sticky;
      top: 107px;
      height: max-content;
    }

    &__heading {
      padding: 30px 50px;
    }
  }
}

@include respond('medium') {
  .pay-and-delivery {
    padding-bottom: 45px;

    &__wrapper {
      grid-column: 1/7;
    }

    &__heading {
      padding: 30px 30px;
    }

    &__payment {
      margin-bottom: 24px;
    }
  }
}

@include respond-down('small') {
  .pay-and-delivery {
    padding-bottom: 30px;

    &__wrapper {
      grid-column: 1/5;
    }

    &__heading {
      padding: 14px 19px;
    }

    &__payment {
      margin-bottom: 20px;
    }
  }
}