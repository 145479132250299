.article {
  &__slider-link {
    height: 100%;
  }

  &__main {
    background-color: white;
    border-radius: 3px;
  }

  &__created-at {
    font-size: 14px;
    line-height: 18px;
    color: var(--text-color-light-2);
  }

  &__long-text {
    line-height: 150%;
    font-weight: 400;
    color: var(--black-color);
  }

  &__img {
    border-radius: 3px;
    object-fit: cover;
    width: 100%;
  }

  &__sticky {
    background-color: white;
    border-radius: 3px;
  }

  &__sticky-item {
    border-top: 1px solid var(--border-gray-color);
  }

  &__sticky-wrapper {
    display: flex;
    flex-direction: column;
    padding: 18px 43px 16px 33px;

    &:hover {
      .article__sticky-name {
        color: var(--primary-color);
      }
    }
  }

  &__sticky-created-at {
    color: var(--text-color-light-2);
    font-size: 14px;
    line-height: 120%;
    margin-bottom: 6px;
  }

  &__sticky-name {
    font-size: 15px;
    line-height: 133%;
    transition: var(--default-transition);
  }

  &__slider-link {
    display: flex;
    flex-direction: column;
    background-color: white;
  }

  &__bottom {
    position: relative;
  }

  &__slider {
    position: relative;
  }

  &__slider-arrow {
    position: absolute;

    &_right {
      z-index: 10;
      top: 50%;
      right: 0;
      transform: translate(-50%, -50%);
    }

    &_left {
      z-index: 10;
      top: 50%;
      left: 0;
      transform: translate(50%, -50%);
    }
  }

  &__slider-list {
    border-radius: 3px;
    display: flex;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__slider-item {
    border-right: 1px solid var(--border-gray-color);

    &:last-child {
      border-right: none;
    }
  }

  &__slider-img {
    border-radius: 3px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
  }

  &__slider-picture {
    display: flex;
    width: 100%;
  }

  &__slider-created-at {
    color: var(--text-color-light-2);
    font-size: 14px;
    line-height: 120%;
  }

  &__slider-name {
    font-size: 17px;
    line-height: 124%;
    color: black;
  }

  &__text {
    overflow: scroll hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

@include respond-up('large') {
  .article {
    padding-bottom: 70px;

    &__slider {
      margin-top: 23px;
    }

    &__slider-img {
      //width: 242px;
      //height: 130px;
    }

    &__slider-picture {
      height: calc((var(--grid-column3) - 36px) * 130/242);
    }

    &__slider-created-at {
      margin-bottom: 10px;
    }

    &__top {
      display: grid;
      grid-template-columns: 1fr 278px;
      grid-column-gap: 28px;
    }

    &__main {
      padding: 30px 50px;
      margin-bottom: 70px;
    }

    &__slider-arrow {
      ._show {
        display: flex;
      }
    }

    &__title {
      margin-bottom: 20px;
    }

    &__created-at {
      margin-bottom: 21px;
    }

    &__sticky-block {
    }

    &__sticky {
      position: sticky;
      top: 100px;
      margin-bottom: 70px;
    }

    &__sticky-title {
      padding: 18px 43px 16px 33px;
    }
  }
}

@include respond-up('medium') {
  .article {
    &__long-text {
      font-size: 18px;
      margin-top: 45px;
    }

    &__slider-link {
      padding: 28px;
    }

    &__slider-created-at {
      margin-top: 15px;
    }
  }
}

@include respond('medium') {
  .article {
    padding-bottom: 55px;

    &__main {
      padding: 30px;
      margin-bottom: 45px;
    }

    &__slider-item {
      flex: 0 0 284px;

      &:last-child {
      }
    }

    &__slider {
      margin-top: 20px;
    }

    &__slider-created-at {
      margin-bottom: 10px;
    }

    &__slider-img {
      //width: 242px;
      //height: 130px;
    }

    &__slider-picture {
      //height: calc((var(--grid-column2) + var(--grid-gap)) * 120/236);
      height: 110px;
    }

    &__slider-name {
      font-size: 17px;
      line-height: 124%;
      color: black;
    }

    &__slider-list {
      display: flex;
      overflow-x: scroll;
    }

    &__title {
      margin-bottom: 20px;
    }

    &__created-at {
      margin-bottom: 20px;
    }

    &__picture {
      margin-bottom: 45px;
    }

    &__sticky-block {
      display: none;
    }

    &__slider-arrow {
      display: none;
    }
  }
}

@include respond-down('medium') {
  .article {

  }
}

@include respond-down('small') {
  .article {
    padding-bottom: 30px;

    &__main {
      padding: 20px 19px;
      margin-bottom: 30px;
    }

    &__slider {
      margin-top: 12px;
    }

    &__slider-item {
      flex: 0 0 212px;

      &:last-child {
      }
    }

    &__slider-link {
      padding: 14px;
    }

    &__slider-created-at {
      margin-bottom: 10px;
      margin-top: 10px;
    }

    &__slider-img {
      //width: 184px;
      //height: 120px;
    }

    &__slider-picture {
      //height: calc((var(--grid-column2) + var(--grid-gap) + 8px) * 120/184);
      height: 90px;
    }

    &__slider-name {
      font-size: 16px;
      line-height: 124%;
      color: black;
    }

    &__slider-list {
      display: flex;
      overflow-x: scroll;
    }

    &__slider-arrow {
      display: none;
    }

    &__title {
      margin-bottom: 15px;
    }

    &__created-at {
      margin-bottom: 15px;
    }

    &__picture {
      margin-bottom: 20px;
    }

    &__long-text {
      font-size: 17px;
      margin-top: 20px;
    }

    &__sticky-block {
      display: none;
    }
  }
}
