.breadcrumbs {
  &__item {
    display: inline;
    margin-right: 2px;

    &_delimiter {
      color: var(--breadcrumbs-color);
      cursor: default;
      white-space: nowrap;
      font-size: 14px;
      line-height: 1.2em;
    }
  }

  &__link {
    .breadcrumbs {
      &__name {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          left: 0;
          bottom: 1px;
          width: 100%;
          height: 1px;
          background-color: var(--t);
          transition: background-color var(--default-transition);
        }
      }
    }
  }

  &__name {
    font-size: 14px;
    line-height: 1.2em;
    color: var(--breadcrumbs-color);
  }
}

@include respond-up('large') {
  .breadcrumbs {
    &__link {
      .breadcrumbs {
        &__name {
          &:hover {
            &::before {
              background-color: #b9b9b9;
            }
          }
        }
      }
    }
  }
}