.sidebar-filter-field {
  padding: 24px;

  &:not(:last-child) {
    border-bottom: 1px solid var(--border-gray-color);
  }

  &__label {
    margin-bottom: 14px;
  }

  .filter-field {
    &__choices-list {
      overflow: hidden;
      max-height: 280px;
      margin: -4px 0;

      &._scrollable {
        overflow-y: scroll;

        &::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 5px;
          background-color: var(--border-gray-color);
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, .5);
          box-shadow: 0 0 1px rgba(255, 255, 255, .5);
        }
      }

      &._open {
        max-height: 100%;
        overflow: hidden;

        & + .filter-field__show-more {
          &::after {
            transform: rotate(-90deg);
          }

          .filter-field {
            &__show-more-title {
              &_show {
                display: none;
              }

              &_hide {
                display: block;
              }
            }
          }
        }
      }
    }

    &__choices-item {
      display: flex;
      padding: 4px 0;
    }

    &__show-more-title_hide {
      display: none;
    }
  }
}

@include respond-up('large') {
  .sidebar-filter-field {
    .filter-field {
      &__show-more {
        margin-top: 12px;
      }
    }
  }
}

@include respond-down('medium') {
  .sidebar-filter-field {
    .filter-field {
      &__show-more {
        margin-top: 10px;
      }
    }
  }
}