.drive-print {
  padding: calc(100vw * 20 / 297) calc(100vw * 40 / 297) calc(100vw * 15 / 297) calc(100vw * 20 / 297);
  font-family: 'Times New Roman', serif;
  height: 100vw * 210 / 297;
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: column;
  position: relative;
  break-inside: avoid;

  &__second-picture {
    position: absolute;
    width: calc(100vw * 62 / 297);
    bottom: calc(100vw * 18 / 297);
    left: calc(50%);
    transform: translate3d(-50%, 0, 0);
  }

  &__first-picture {
    position: absolute;
    bottom: 0;
    width: calc(100vw * 60 / 297);
    left: calc(100vw * 30 / 297);
  }

  &__picture {
  }

  &__line-2 {
    width: calc(100vw * 17 / 297);
    height: 1px;
    background: black;
  }

  &__middle-lined {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__middle-line {
    display: flex;
    align-items: center;
  }

  &__lonely-label {
    margin-bottom: calc(-100vw * 2 / 297);
    margin-top: calc(100vw * 2 * 1.5 / 297);
  }

  &__footer-middle-bottom {
    padding-right: calc(100vw * 20 / 297);
    margin-bottom: calc(-100vw * 2 / 297);
  }

  &__footer-middle-top-columns {
    display: grid;
    grid-template-columns: calc(100vw * 19 / 297) auto;
  }

  &__footer-left-top {
    margin-top: calc(-100vw * 7.7 / 297);
  }

  &__footer-middle-top {
    margin-top: calc(-100vw * 4 / 297);
  }

  &__footer-left,
  &__footer-middle {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__footer-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  &__footer {
    display: grid;
    grid-template-columns: calc(100vw * 68 / 297) auto calc(100vw * 68 / 297);
    grid-gap: calc(100vw * 5 / 297);
    padding-top: calc(100vw * 4 / 297);
    flex: 1 0 auto;
  }

  table {
    .drive-print {
      &__medium,
      &__small,
      &__inscription {
        white-space: normal;
        display: flex;
        justify-content: center;
        line-height: 105%;
      }
    }
  }

  &__small-tables-right-bottom {
    table {
      &:first-child {
        tr {
          &:not(:first-child) {
            height: calc(100vw * 4.5 / 297);
          }
        }
      }
    }
  }

  &__big-table {
    margin-top: calc(100vw * 1.2 / 297);

    table {
      width: 100%;
      text-align: center;
      border-collapse: collapse;

      tr {
        height: calc(100vw * 2 / 297);

        &:last-child {
          height: calc(100vw * 3.5 / 297);
        }
      }

      td, th {
        border: 1px solid black;
        vertical-align: middle;
        height: calc(100vw * 2 / 297);
      }
    }
  }

  &__small-tables-right {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    table {
      word-wrap: break-word;
      width: 100%;
      text-align: center;
      border-collapse: collapse;

      .drive-print__inscription {
        white-space: normal;
        display: flex;
        justify-content: center;
        line-height: 105%;
      }

      td, th {
        border: 1px solid black;
        vertical-align: middle;
      }
    }
  }

  &__small-tables-right-top {
    table {
      height: calc(3 * 100vw * 5 / 297 + 4 * 100vw * 3 / 297);
    }
  }

  &__garage-numbers-table {
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    text-align: right;
    padding-right: 0.3367vw;
  }

  &__left-table-three-lines {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1 0 auto;
    margin-bottom: calc(-100vw * 2 / 297);

    .drive-print__left-table-line {
      &:first-child {
        margin-right: calc(-100vw * 16 / 297);
      }
    }
  }

  &__left-table-four-lines {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1 0 auto;
  }

  &__small-tables-left-top-part {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: calc(100vw * 3 / 297);
  }

  &__left-table-line-with-inscription {
    margin-bottom: calc(100vw * 2 / 297);
  }

  &__left-table-line {
    display: flex;

    &_number {
      width: calc(100vw * 90 / 297);
    }

    &_licence-card {
      width: calc(100vw * 60 / 297);
      gap: calc(100vw * 1 / 297);

      .drive-print__blank {
        flex: 1 0 auto;
      }
    }

    &_licence {
      width: calc(100vw * 75 / 297);
    }

    .drive-print {
      &__inscription {
        display: flex;
        align-items: flex-end;
      }
    }
  }

  &__small-tables {
    display: grid;
    grid-template-columns: calc(100vw * 120 / 297) auto;
    grid-column-gap: calc(100vw * 7 / 297);
    margin-top: calc(100vw * 6 / 297);
  }

  &__sans {
    font-family: 'Arial', 'Helvetica', sans-serif;
    line-height: 100%;
  }

  &__blank {
    position: relative;
    border-bottom: 1px solid black;
    height: calc(100vw * 4.4 / 297);
    text-align: center;

    &_high {
      height: calc(100vw * 5.2 / 297);
    }

    &_registration {
      flex: 1 0 auto;

      &:nth-child(2) {
        margin-right: calc(100vw * 2 / 297);
      }

      &:nth-child(4) {
        margin-left: calc(100vw * 2 / 297);
        flex: 0 0 calc(100vw * 17 / 297);
      }

      &:nth-child(6) {
        flex: 0 0 calc(100vw * 10 / 297);
      }
    }

    &_licence-class {
      flex: 0 0 (100vw * 12 / 297);
    }

    &_licence {
      flex: 1 0 auto;

      &:nth-child(2) {
        margin-right: calc(100vw * 2 / 297);
      }
    }

    &_organization {
      flex: 1 0 auto;
      margin-left: calc(100vw * 2 / 297);
    }

    &_clean {
      flex: 1 0 auto;
    }

    &_space {
      margin-left: calc(100vw * 1 / 297);
      margin-right: calc(100vw * 2 / 297);
    }

    &_date {
      width: calc(100vw * 8 / 297);

      .drive-print {
        &__medium {
          line-height: 160%;
        }
      }
    }

    &_number {
      width: calc(100vw * 20 / 297);
    }

    &_number-second {
      width: calc(100vw * 25 / 297);
    }

    .drive-print {
      &__medium {
        line-height: 200%;
      }

      &__inscription {
        position: absolute;
        bottom: calc(-100vw * 2.5 / 297);
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__inscription {
    // это штучки ПОД линейкой
    // (подпись)
    font-size: calc(100vw * 2.2 / 297);
    line-height: 100%;
    white-space: nowrap;
    letter-spacing: -0.02em;

    &_bold {
      font-weight: bold;
    }
  }

  &__small {
    // Место для штампа организации
    font-size: calc(100vw * 2.8 / 297);
    line-height: 110%;
  }

  &__medium {
    // Организация
    font-size: calc(100vw * 2.8 / 297);
    line-height: 120%;

    &_wide {
      letter-spacing: 0.15em;
    }

    &_upper {
      text-transform: uppercase;
    }

    &.drive-print {
      &__sans {
        line-height: 160%;
      }
    }
  }

  &__larger {
    // Организация
    font-size: calc(100vw * 3.6 / 297);
    line-height: 120%;
  }

  &__h1 {
    font-size: calc(100vw * 11.6 / 891);
    line-height: 120%;
    text-transform: uppercase;
    font-weight: bold;

    &_left {
      //pl5mm
      padding-left: calc(100vw * 12 / 891);
    }
  }

  &__stamp {
    padding-left: calc(100vw * 6 / 891);
    margin-bottom: calc(-100vw * 8 / 891);
  }

  &__h2 {
    font-weight: bold;
    font-size: calc(100vw * 12 / 891);
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__bottom {
    display: flex;
    flex-direction: row;
  }

  &__organization,
  &__date {
    display: flex;
    flex-direction: row;
  }

  &__date {
    align-self: center;
  }

  &__center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__header-bottom {
    display: flex;
    justify-content: space-between;
  }

  &__header-info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1 0 auto;
    margin-right: calc(-100vw * 25 / 891);
  }

  &__code-table-wrapper {
    display: flex;
    flex: 0 0 auto;
  }

  &__code-table-text-wrapper-small-first {
    .drive-print__inscription {
      height: calc(100vw * 9.5 / 891);
    }
  }

  &__code-table-text-wrapper-small-second {
    .drive-print__inscription {
      height: calc(100vw * 14 / 891);
    }
  }

  &__code-table-text-wrapper-small-first,
  &__code-table-text-wrapper-small-second {
    justify-content: space-around;
    display: flex;
    flex-direction: column;
    white-space: nowrap;

    .drive-print__inscription {
      display: flex;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }

  &__code-table-text-wrapper-small {
    padding-top: calc(100vw * 10 / 891);
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    text-align: right;
    padding-right: calc(100vw * 3 / 891);
  }

  &__code-table-text-wrapper {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    text-align: right;
    white-space: nowrap;
    padding-right: calc(100vw * 6 / 891);
  }

  &__code-table {
    width: calc(100vw * 75 / 891);
    display: flex;
    flex-direction: column;
    border-top: 1px solid black;

    &_small {
      width: calc(100vw * 33 / 891);
    }
  }

  &__code-table-cell-wrapper {
    border: 2px solid black;

    .drive-print {
      &__code-table-cell {
        border-left: none !important;
        border-right: none !important;

        &:not(:last-child) {
          border-bottom: 1px solid black;
        }
      }
    }
  }

  &__code-table-cell {
    height: calc(100vw * 13 / 891);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &_normal {
      height: calc(100vw * 11 / 891);
    }

    &_narrow {
      height: calc(100vw * 9 / 891);
    }

    &:first-child {
      border-left: 1px solid black;
      border-right: 1px solid black;
    }
  }
}

@media print {
  @page {
    size: A4;   /* auto is the initial value */
    size: landscape;
    margin: 0 !important;
    padding: 0 !important;
  }

  body {
    -webkit-print-color-adjust: exact;
  }

  .container_spacer {
    padding: 0;
  }

  .drive-print {
    padding: 2cm 4cm 1.5cm 2cm !important;
    border-bottom: none;
    height: 100vh;
    margin: 0 !important;
    overflow: hidden;
  }
}
