.modal-tyre-prices {
  overflow: scroll;
  border-bottom: 1px solid black;

  &__empty {
    padding: 50px 5px;
    border-left: 1px solid black;
    border-right: 1px solid black;
  }

  &__table {
    display: flex;
    flex-direction: column;
  }

  &__row,
  &__column {
    display: flex;
  }

  &__row {
    &:first-child {
      position: sticky;
      top: 0;

      .modal-tyre-prices__column {
        border-top: 1px solid black;
        background-color: var(--background-color);
      }
    }

    &:last-child {
      .modal-tyre-prices__column {
        border-bottom: unset;
      }
    }
  }

  &__column {
    padding: 5px;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    flex: 0 0 150px;

    &:first-child {
      border-left: 1px solid black;
      flex: 0 0 200px;
    }
  }
}

@include respond-up('large') {
  .modal-tyre-prices {
    width: 1250px;
    max-height: 75vh;
  }
}

@include respond-up('medium') {
  .modal-tyre-prices {

  }
}

@include respond('medium') {
  .modal-tyre-prices {

  }
}

@include respond-down('medium') {
  .modal-tyre-prices {
    width: 65vw;
    max-height: 65vh;
  }
}

@include respond-down('small') {
  .modal-tyre-prices {

  }
}