.rating-area {
  display: flex;
  flex-direction: row-reverse;
  max-width: 115px;
  label:active {
    position: relative;
  }

  input[type="radio"]:checked ~ label {
    svg {
      fill: var(--corn-color);
    }
  }

  input[type="radio"]+label:hover ~ label {
    svg {
      fill: var(--corn-color);
    }
  }
  input[type="radio"] {
    display: none;

    + label  {
      padding: 0 2px;
      cursor: pointer;
      svg {
        stroke: transparent;
      }

      &:hover {
        svg {
          fill: var(--corn-color);
        }
      }
    }
  }
}





