.favorites {
  &__preloader {
    background-color: var(--t);
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__heading-counter {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__heading-text {
    margin-right: 5px;
  }

  &__head {
    position: relative;
  }

  &__empty {
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__empty-text {
    font-size: 30px;
    margin-bottom: 40px;
  }

  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-bottom: 1px solid var(--border-gray-color);
    padding: 25px 24px;
  }

  &__top-counter {
    display: flex;
    font-weight: normal;
    font-size: 15px;
    line-height: 1.33em;
    color: var(--text-color-light);
  }

  &__top-text {
    margin-right: 5px;
  }

  &__items {
    &:before {
      display: none;
    }
  }

  &__top-filters {
    display: flex;
  }

  &__top-select-wrap {
    display: flex;
  }

  &__top-select-icon {
    display: flex;
    margin-right: 11px;
  }

  &__top-select-button {
    border: none;
    padding: 0 30px 0 0;
    height: auto;
  }

  &__top-wrap {
    display: flex;
    align-items: center;
  }

  &__top-link {
    display: flex;
    &_active-grid {
      svg {
        path {
          stroke: var(--primary-color);
        }
      }
    }

    &_active-list {
      svg {
        path {
          fill: var(--primary-color);
        }
      }
    }

    &:nth-child(1) {
      margin-right: 14px;
    }
  }
}

@include respond-up('large') {
  .favorites {
    margin-bottom: 70px;

    &__items {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }

    &__top-select-wrap {
      margin-right: 16px;
    }
  }
}

@include respond('medium') {
  .favorites {
    margin-bottom: 45px;

    &__items {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &__top-select-wrap {
      margin-right: 12px;
    }
  }
}

@include respond-down('small') {
  .favorites {
    margin-bottom: 30px;

    &__items {
      grid-template-columns: repeat( auto-fit, minmax(160px, 1fr) );
    }

    &__top-select-icon {
      margin-right: 9px;
    }

    &__top-link {
      &:nth-child(1) {
        margin-right: 10px;
      }
    }

    &__top-counter {
      display: none;
    }

    &__top-filters {
      justify-content: space-between;
      width: 100%;
    }

    &__top {
      padding: 15px 20px;
    }
  }
}

@include respond-down('x-small') {
  .favorites {
    &__items {
      grid-template-columns: repeat( auto-fit, minmax(140px, 1fr) );
    }
  }
}