.vacancy-page {

}

@include respond-up('large') {
  .vacancy-page {
    display: grid;
    grid-template-columns: 1fr 278px;
    grid-gap: var(--grid-gap);
    padding-bottom: 70px;

    &__vacancy-wrap {
      grid-column: 1/2;
    }

    &__sticky-wrap {
      position: relative;
      grid-column: 2/3;
    }
  }
}

@include respond('medium') {
  .vacancy-page {
    padding-bottom: 45px;
  }
}

@include respond-down('medium') {
  .vacancy-page {
    &__sticky-wrap {
      display: none;
    }
  }
}

@include respond-down('small') {
  .vacancy-page {
    padding-bottom: 30px;
  }
}