.popup {
  &__picture {
    display: flex;
    width: 100%;
  }

  &__image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: flex;
  }
}

@include respond-up('large') {
  .popup {
    &__image-wrap {
      width: var(--grid-column6);
    }

    &__picture {
      height: calc(var(--grid-column6) * 304/585);
    }
  }
}

@include respond-up('medium') {
  .popup {

  }
}

@include respond('medium') {
  .popup {
    &__image-wrap {
      width: calc(var(--grid-column5) + var(--grid-gap));
    }

    &__picture {
      width: calc(var(--grid-column5) + var(--grid-gap));
      height: calc((var(--grid-column5) + var(--grid-gap)) * 304/585);
    }
  }
}

@include respond-down('medium') {
  .popup {

  }
}

@include respond-down('small') {
  .popup {
    &__image-wrap {
      width: var(--grid-column4);
    }

    &__picture {
      height: calc(var(--grid-column4) * 176/339);
    }
  }
}