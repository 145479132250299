.side-wrapper {
  position: relative;

  &__sticky {
    position: sticky;
    top: 100px;
  }
}

.question{
  padding: 27px 33px;
  &__title_small{
    padding-bottom: 17px;
  }

  &__button{
    width: 100%;
  }
}