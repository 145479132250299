.type-switcher {
  background-color: white;
  box-shadow: inset 0px -1px 0px var(--border-gray-color);
  //position: relative;

  &__list {
    display: flex;
  }

  &__item {

  }

  &__link {
    padding: 18px 33px;
    color: var(--black-color);
    background-color: white;
    box-shadow:
            inset 0px -1px 0px var(--border-gray-color),
            inset -1px 0px 0px var(--border-gray-color);
    display: flex;
    justify-content: center;
    align-items: center;

    &.active {
      color: var(--primary-color);
      .type-switcher__link-icon {

        path, circle {
          fill: var(--primary-color);
        }
      }
    }
  }

  &__link-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
  }

  &__link-icon {
    display: flex;
    align-items: center;
    margin-right: 12px;
  }
}

@include respond-up('medium') {
  .type-switcher {
    &__link {
      &.active {
        box-shadow:
                inset 0px 3px 0px var(--primary-color),
                inset -1px 0px 0px var(--border-gray-color);
      }
    }
  }
}

@include respond-down('small') {
  .type-switcher {
    &__item {
      flex: 0 0 50%;
    }

    &__link {
      &.active {
        box-shadow:
                inset 0px 3px 0px var(--primary-color),
                inset 0px -1px 0px var(--border-gray-color),
                inset -1px 0px 0px var(--border-gray-color);
      }
    }
  }
}