.link-icon {
  display: inline-flex;
  align-items: center;
  font-family: var(--font);
  font-size: 14px;
  line-height: 1.2em;
  color: var(--link-gray-color);
  transition: color var(--default-transition);

  &_big {
    font-size: 15px;
    line-height: 1.33em;

    svg {
      margin-right: 11px;
    }
  }

  svg {
    margin-right: 6px;
    margin-top: -2px;

    g, path, circle, rect {
      transition: fill var(--default-transition);
    }
  }

  &:hover {
    color: var(--primary-color);

    svg {
      g, path, circle, rect {
        fill: var(--primary-color);
      }
    }
  }
}