.socials {
  &_dark {
    .socials {
      &__link {
        &:hover {
          svg path:first-child {
            stroke-opacity: 0.7;
          }
        }

        svg path {
          &:first-child {
            stroke: rgb(31, 33, 41);
          }

          &:nth-child(2) {
            fill: var(--black-color);
          }
        }
      }
    }
  }

  &__items {
    display: flex;
    align-items: center;
    margin: 0 -4px;
  }

  &__item {
    padding: 0 4px;
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    overflow: hidden;

    &:hover {
      svg path:first-child {
        stroke-opacity: 0.7;
      }
    }

    svg path:first-child {
      transition: stroke-opacity var(--default-transition);
    }
  }
}