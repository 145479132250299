.model-card {
  &__wrap {
    border-radius: 0;
    height: 100%;
  }

  &__image,
  &__image-link {
    position: relative;
  }

  &__image-link {
    display: block;
  }

  &__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
  }

  &__img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &__favorite {
    top: -3px;
    right: -3px;
  }

  &__favorite,
  &__actions,
  &__features {
    position: absolute;
    display: flex;
    flex-direction: column;
  }

  &__actions {
    top: -4px;
    left: -4px;
  }

  &__features {
    bottom: 0;
    left: 0;
  }

  &__action,
  &__feature {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__hidden-target {
    &:hover {
      .model-card {
        &__hidden-name {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }

  &__action-icon {
    display: flex;
    height: 28px;
    width: 28px;
  }

  &__hidden-name {
    position: absolute;
    left: 100%;
    margin-left: 9px;
    padding: 4px 6px;
    background-color: var(--black-color);
    border-radius: 3px;
    font-size: 13px;
    line-height: 1.3em;
    white-space: nowrap;
    color: white;
    opacity: 0;
    pointer-events: none;
    transform: translateX(10px);
    transition: opacity var(--default-transition), transform var(--default-transition);
    z-index: 10;

    &:before {
      content: '';
      position: absolute;
      right: calc(100% - 1px);
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 6px 7px 0;
      border-color: $t var(--black-color) $t $t;
    }
  }

  &__feature-icon {
    display: flex;
    height: 20px;
    width: 20px;
  }

  &__link {
    display: block;

    &:hover {
      .model-card {
        &__name {
          background-size: 100% 100%;
        }
      }
    }
  }

  &__name {
    position: relative;
    color: var(--black-color);
    transition: background-size var(--default-transition);

    // эта линия на 1px выше
    background: linear-gradient($t calc(100% - 2px), var(--product-name-border) calc(100% - 2px), var(--product-name-border) calc(100% - 1px), $t calc(100% - 1px), $t 100%)
                no-repeat 0 / 0 100%;

    //background: linear-gradient(transparent calc(100% - 1px), var(--product-name-border) 1px) no-repeat 0 / 100% 100%;
  }

  &__stock {
    display: inline-flex;
  }

  &__buy {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__prices {
    display: flex;
    align-items: center;
  }

  &__price {
    font-weight: 500;
    color: var(--black-color);
    white-space: nowrap;
    transition: color var(--default-transition);

    &:not(:last-child) {
      margin-right: 8px;
    }

    &_red {
      color: var(--alert-color);
    }

    &_old {
      color: var(--text-color-light);
      text-decoration: line-through;
      font-weight: 400;
    }
  }

  &__kit-price {
    font-size: 13px;
    line-height: 1.2em;
  }

  &__cart {
    display: inline-flex;
    transition: background-color var(--default-transition), border-color var(--default-transition);

    svg path {
      transition: fill var(--default-transition);
    }
  }

  &__caption {
    display: inline-flex;
    margin-top: 14px;
    padding: 2px 6px;
    border-radius: 3px;
    background: var(--background-color);
    font-weight: 400;
    font-size: 13px;
    line-height: 1.2em;
    color: var(--link-gray-color);
  }

  &__footer-wrap {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--border-gray-color);
  }

  &__click {
    margin-right: 6px;
  }

  &__click-name {
    white-space: nowrap;
  }
}

@include respond-up('large') {
  .model-card {
    position: relative;

    &__wrap {
      // отрицательный маржин вниз на высоту футера плиточки
      position: relative;
      padding: 24px 24px 22px;
      background-color: $t;
      box-shadow: 0 7px 40px rgba(50, 59, 86, 0);
      transition: transform var(--default-transition),
                  box-shadow var(--default-transition),
                  border-radius var(--default-transition),
                  background-color var(--default-transition),
                  opacity var(--default-transition),
                  height var(--default-transition),
                  padding-bottom var(--default-transition);

      &:hover {
        position: relative;
        padding-bottom: 22px;
        border-radius: 3px;
        background-color: white;
        box-shadow: 0 7px 40px rgba(50, 59, 86, 0.12);
        z-index: 900!important;

        .model-card {
          &__kit-price {
            color: var(--black-color);
          }

          &__cart {
            border-color: var(--primary-color);
            background-color: var(--primary-color);

            &:hover {
              border-color: var(--primary-color-hover);
              background-color: var(--primary-color-hover);
            }

            svg path {
              fill: white;
            }
          }

          &__footer-wrap {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }
    }

    &__buy {
      margin-top: 27px;
    }

    &__kit-price {
      color: var(--link-gray-color);
      transition: color var(--default-transition);
    }

    &__footer {

    }

    &__footer-wrap {
      margin-top: 14px;
      transform-origin: top;
      opacity: 0;
      pointer-events: none;
      overflow: hidden;
      transition: transform var(--default-transition), opacity var(--default-transition), height var(--default-transition);
    }
  }
}

@include respond-up('medium') {
  .model-card {
    &__image-link {
      padding-top: 175/185*100%;
    }

    &__link {
      margin-top: 18px;
    }

    &__link,
    &__name {
      line-height: 1.35em;
    }

    &__name {
      font-size: 15px;
    }

    &__stock {
      margin-top: 10px;
    }

    &__prices {
      flex-wrap: wrap;
      margin-right: 4px;
    }

    &__price {
      font-size: 20px;
      line-height: 1.17em;

      &_old {
        font-size: 13px;
        line-height: 1.2em;
      }
    }

    &__kit-price {
      margin-top: 3px;
    }

    &__cart {
      align-self: flex-start;

      // все паддинги на минус один из-за бордера
      padding: 9px 11px 9px 10px;

      &_mobile {
        display: none;
      }
    }

    &__click-name {
      &_mobile {
        display: none;
      }
    }

    &__footer-wrap {
      padding-top: 15px;
    }
  }
}

@include respond('medium') {
  .model-card {
    &__wrap {
      padding: 24px 24px 26px;
    }

    &__buy {
      margin-top: 24px;
    }

    &__footer-wrap {
      margin-top: 24px;
    }
  }
}

@include respond-down('medium') {
  .model-card {
    &__cart {
      &.button_transparent {
        border-color: var(--primary-color);
        background-color: var(--primary-color);

        &:hover {
          border-color: var(--primary-color-hover);
          background-color: var(--primary-color-hover);
        }

        svg path {
          fill: white;
        }
      }
    }
  }
}

@include respond('small') {
  .model-card {
    &__wrap {
      padding: 15px 14px 24px;
    }
  }
}

@include respond-down('small') {
  .model-card {
    &__action-name {
      display: none;
    }

    &__image-link {
      padding-top: 157/140*100%;
    }

    &__link {
      margin-top: 14px;
    }

    &__link,
    &__name {
      line-height: 1.2em;
    }

    &__name {
      font-size: 14px;
    }

    &__stock {
      margin-top: 5px;
    }

    &__buy {
      margin-top: 23px;
    }

    &__price {
      font-size: 17px;
      line-height: 1.24em;

      &_old {
        font-size: 12px;
        line-height: 1.2em;
      }
    }

    &__cart {
      // все паддинги на минус один из-за бордера
      padding: 7px 9px 7px 8px;

      &_desktop {
        display: none;
      }
    }

    &__bonus {
      display: none;
    }

    &__click-name {
      &_desktop {
        display: none;
      }
    }

    &__footer-wrap {
      margin-top: 10px;
      padding-top: 10px;
    }
  }
}

@include respond-down('x-small') {
  .model-card {
    &__hidden-name {
      padding: 2px 4px;
      font-size: 11px;
    }

    &__wrap {
      padding: 14px 12px 16px;
    }
  }
}