.text {
  font-family: var(--font);
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
  color: var(--text-color);

  &__medium-20 {
    font-style: normal;
    font-weight: 500;
  }

  &__fourteen {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  &__thirteen {
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
  }

  &_small {
    font-size: 15px;
  }

  &_grey {
    color: var(--text-color-light);
  }

  &_grey-second {
    color: var(--text-color-light-2);
  }

  &_blue-grey {
    color: var(--link-gray-color);
  }

  &_black {
    color: var(--black-color);
  }

  &_red {
    color: var(--primary-color);
  }
}

@include respond-up('medium') {
  .text {
    &__medium-20 {
      font-size: 20px;
      line-height: 117%;
    }
  }
}

@include respond-down('small') {
  .text {
    font-size: 15px;

    &__medium-20 {
      font-size: 17px;
      line-height: 124%;
    }
  }
}