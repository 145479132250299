.guarantee-main {
  &__heading-wrapper {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--border-gray-color);
    margin: 0 -50px 45px;
    padding: 0 50px 30px;
    gap: 70px;

    h1 {
      padding-bottom: 10px;
    }

    picture {
      display: inline-block;

      img {
        max-width: 120px;
      }
    }
  }

  &__description-wrapper {
    border-bottom: 1px solid var(--border-gray-color);
    margin: 0 -50px 45px;
    padding: 0 50px 45px;
  }

  &__additional {
    font-size: 15px;
    color: var(--link-gray-color);
  }
}

.guarantee {
  &__manufacturer-more {
    font-size: 13px;
    color: var(--text-color-light-2);
  }

  &__tyres-list {
    display: flex;
    flex-wrap: wrap;

    li {
      a {
        color: var(--primary-color);
        margin-right: 14px;
        border-bottom: 1px solid var(--t);
        transition: border-color .4s ease-in-out;

        &:hover {
          border-color: rgba(225, 39, 39, 0.3);
        }
      }
    }
  }

  &__manufacturer-block {
    margin-bottom: 45px;

    .guarantee {
      &__heading {
        font-size: 24px;
        padding-bottom: 20px;
      }
    }
  }

  &__manufacturer-inclusions {

    ul {
      gap: 28px;
      flex-wrap: nowrap;
    }

    .guarantee {

      &__heading {
        padding-bottom: 30px;
      }

      &__inclusions-item {
        flex-grow: 1;
        box-shadow: none;
        background-color: var(--gray-layer);
      }

      &__inclusions-icon-1 {
        background-image: url("../images/svg/inclusion-1-white.svg");
      }

      &__inclusions-icon-2 {
        background-image: url("../images/svg/inclusion-2-white.svg");
      }

    }
  }

  &__manufacturer-description {
    font-size: 15px;
    color: var(--text-color);

    p {
      margin-bottom: 20px;
    }
  }

  &__manufacturer-side-wrapper {
    .side-wrapper {
      &__heading {
        border-bottom: 1px solid var(--border-gray-color);
      }

      &__nav {
        li {
          border: none;

          a {
            padding: 5px 33px;

            &:hover {
              background-color: inherit;
            }
          }
        }
      }

      &__show-all-link {
        position: relative;
        color: var(--primary-color);
        display: block;
        padding: 24px 33px;
        font-size: 15px;
        line-height: 1.35em;
        white-space: nowrap;
        background-color: #fff;
        transition: color var(--default-transition), background-color var(--default-transition);

        &:hover {
          background-color: #f5f5f6;
        }

        &:before {
          content: '';
          position: absolute;
          background-image: url("../images/svg/arrows/right-red.svg");
          right: 110px;
          width: 14px;
          height: 12px;
          top: 51%;
          transform: translate(0, -50%);
          background-repeat: no-repeat;
          transition: translate var(--default-transition);
        }
      }
    }
  }
}

@include respond('medium') {
  .guarantee-main {
    padding: 50px;
  }

  .guarantee {
    &__second {
      margin-top: 24px;
    }
  }
}

@include respond-down('medium') {
  .guarantee {
    &__manufacturer-inclusions {
      ul {
        gap: 20px;
      }
    }
  }
}

@include respond-down('small') {
  .guarantee-main {
    padding: 19px;

    &__description-wrapper {
      margin: 0 -19px 30px;
      padding: 0 19px 20px;
    }

    &__heading-wrapper {
      margin: 0 -19px 20px;
      padding: 0 19px 20px;
      flex-wrap: wrap-reverse;
      gap: 14px;

      picture {
        img {
          max-width: 90px;
        }
      }
    }
  }

  .guarantee {
    &__manufacturer-more {
      padding-bottom: 10px;
    }

    &__manufacturer-block {
      margin-bottom: 30px;

      .guarantee {
        &__heading {
          font-size: 20px;
          padding-bottom: 12px;
        }
      }
    }

    &__manufacturer-inclusions {
      .guarantee {
        &__heading {
          padding-bottom: 20px;
        }
      }

      ul {
        gap: 14px;
        flex-wrap: wrap;
      }
    }

    &__tyres-list li a {
      font-size: 14px;
    }
  }

  .guarantee-rules {
    padding: 19px;
  }
}