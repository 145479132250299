.moderation-services-header {
  padding: 60px 0 40px 60px;
  background-color: var(--black-color);

  ul {
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    display: flex;
    align-items: center;
  }

  &__counter {
    border-radius: 100px;
    padding: 1px 5px;
    background-color: red;
    color: white;
  }

  &__counter {
    margin-left: 10px;
  }
}