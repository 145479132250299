.price-competitor-filters {
  &__fields {
    display: flex;
    flex-wrap: wrap;
  }

  &__field {
    flex: 0 0 200px;
    margin-right: var(--grid-gap);
  }

  &__bottom {
    margin-top: var(--grid-gap);
    display: flex;
    flex-wrap: wrap;
  }

  &__low-prices {
    margin-right: var(--grid-gap);
  }
}

@include respond-up('large') {
  .price-competitor-filters {

  }
}

@include respond-up('medium') {
  .price-competitor-filters {

  }
}

@include respond('medium') {
  .price-competitor-filters {

  }
}

@include respond-down('medium') {
  .price-competitor-filters {

  }
}

@include respond-down('small') {
  .price-competitor-filters {

  }
}