.model-box {
  background-color: #fff;
  border-radius: 3px;

  &__slider-wrap {
    position: relative;

    .tns-nav {
      display: block;
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      button {
        position: relative;
        width: 9px;
        height: 9px;
        padding: 0;
        margin: 0 7px;
        border-radius: 100%;
        background-color: var(--button-disabled-color);
        transition: background-color var(--default-transition);

        &:before {
          content: '';
          position: absolute;
          left: -6px;
          top: -6px;
          bottom: -6px;
          right: -6px;
          border: 1px solid $t;
          pointer-events: none;
          box-sizing: border-box;
          border-radius: 100%;
          transition: border-color var(--default-transition);
        }

        &.tns-nav-active {
          background-color: var(--black-color);

          &:before {
            border-color: var(--button-disabled-color);
          }
        }
      }
    }
  }

  &__header {
    border-bottom: 1px solid var(--border-gray-color);
  }

  &__header-info {
    display: flex;
    align-items: flex-end;
  }

  &__features {
    display: flex;
    align-items: center;
    margin: 0 -6px;
  }

  &__feature {
    padding: 0 6px;

    svg {
      height: 20px;
      width: 20px;
    }
  }

  &__favorite {
    display: flex;

    &:hover {
      svg path {
        fill: var(--primary-color)
      }
    }

    svg path {
      transition: fill var(--default-transition);
    }
  }

  &__rating {
    display: flex;
    justify-content: flex-end;
  }

  &__actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__action-link {
    display: flex;
    align-items: center;
    background: #F5F5F6;
    border-radius: 3px;
  }

  &__action-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    svg {
      height: 28px;
      width: 28px;
    }
  }

  &__action-name {
    font-size: 14px;
    line-height: 1.2em;
    color: var(--black-color);
    white-space: nowrap;
  }

  &__code {
    font-size: 14px;
    line-height: 1.2em;
    color: var(--text-color-light-2);
  }

  &__picture {
    height: 100%;
    width: 100%;
  }

  &__producer-img,
  &__img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &__footer {
    border-top: 1px solid var(--border-gray-color);
  }
}

@include respond-up('large') {
  .model-box {
    .tns-nav {
      bottom: 7px;
    }

    &__header {
      padding: 30px 50px;
    }

    &__producer-logo {
      margin-left: 56px;
    }

    &__rating {
      margin: 0 50px;
    }

    &__image-box {
      height: 100%;
      flex: 0 0 462/890*100%;
    }

    &__params-box {
      padding: calc(40px - 8px);
      flex: 0 0 428/890*100%
    }

    &__picture {
      padding: 20px;
    }

    &__favorite {
      &_mobile,
      &_tablet {
        display: none;
      }
    }

    &__price {
      display: none;
    }

    &__footer {
      padding: 30px 50px;
    }

    &__footer-button {
      display: none;
    }
  }
}

@include respond-up('medium') {
  .model-box {
    &__slider {
      max-height: 540px;
    }

    &__img {
      max-height: 500px;
    }

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__header-info {
      margin-top: 10px;
    }

    &__reviews {
      margin-left: 18px;
    }

    &__producer-logo {
      width: 103px;
      height: 51px;
    }

    &__main {
      display: flex;
    }

    &__producer-box {
      display: none;
    }

    &__producer-logo,
    &__code {
      &_mobile {
        display: none;
      }
    }

    &__actions {
      margin: -6px;
    }

    &__action {
      padding: 6px;
    }

    &__action-link {
      padding: 10px 16px;
    }
  }
}

@include respond('medium') {
  .model-box {
    .tns-nav {
      bottom: 5px;
    }

    &__header {
      padding: 20px 30px;
    }

    &__basic {
      padding-right: 80px;
    }

    &__other {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    &__producer-logo {
      margin: auto 0;
    }

    &__favorite {
      &_mobile,
      &_desktop {
        display: none;
      }
    }

    &__rating {
      margin: 0 40px;
    }

    &__main {

    }

    &__image-box {
      align-self: center;
      height: 100%;
      flex: 0 0 312/768*100vw;
    }

    &__picture {
      padding: 14px 12px;
    }

    &__params-box {
      padding: 30px 30px 30px 20px;
    }

    &__params {
      min-width: 327px;
    }

    &__actions {
      padding: 20px 30px;
    }

    &__footer-button {
      padding: 19px 20px 20px;
    }
  }
}

@include respond-down('medium') {
  .model-box {
    &__reviews {
      margin-left: 14px;
    }

    &__price {
      border-top: 1px solid var(--border-gray-color);
    }

    &__footer-button {
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid var(--border-gray-color);
      font-size: 15px;
      line-height: 1.35em;
      color: var(--link-gray-color);

      svg {
        margin-right: 11px;
      }
    }
  }
}

@include respond-down('small') {
  .model-box {

    .tns-nav {
      bottom: -16px;
    }

    &__header {
      padding: 20px 19px;
    }

    &__heading {
      display: flex;
      justify-content: space-between;
    }

    &__header-info {
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 10px;
    }

    &__features {
      margin-right: 10px;
    }

    &__favorite {
      padding-left: 10px;
    }

    &__favorite,
    &__producer-logo,
    &__code {
      &_tablet,
      &_desktop {
        display: none;
      }
    }

    &__producer-box {
      display: flex;
      justify-content: space-between;
      margin: 20px 20px 16px;
    }

    &__code {
      margin-left: 20px;
    }

    &__image-box {
      margin: 16px 20px 20px;
    }

    &__picture {
      height: 240/375*100vw;
      max-height: 400px;
    }

    &__params-box {
      padding: 20px 19px;
      border-top: 1px solid var(--border-gray-color);
    }

    &__actions {
      margin: -6px;
      padding: 20px 19px;
    }

    &__action {
      flex: 1;
      padding: 6px;
    }

    &__action-link {
      padding: 6px 10px;
    }

    &__producer-logo {
      width: 91px;
      height: 41px;
    }

    &__footer {
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        display: block;
        width: 19px;
        background-image: linear-gradient(90deg, $t 0, white 50%, white 100%);
      }
    }

    &__footer-button {
      padding: 17px 18px 18px;
    }
  }
}

@include respond-down('x-small') {
  .model-box {
    &__reviews {
      display: none;
    }
  }
}