.guarantee {
  &__side-wrapper {
    .side-wrapper {
      &__heading {
        padding: 18px 33px;
        font-weight: 500;
        font-size: 19px;
        line-height: 124%;
        border: none;
      }

      &__nav {
        li {
          border-top: 1px solid var(--border-gray-color);

          a {
            padding: 18px 33px;
          }
        }
      }
    }
  }

  &__heading {
    padding-bottom: 30px;
    font-weight: 500;
  }

  &__description {
    font-size: 18px;
  }

  &__case {
    margin-bottom: 14px;

    &:nth-child(3) {
      margin-bottom: 40px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__progress {
    height: 2px;
    background-color: var(--border-gray-color);
    margin-bottom: 20px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      height: 2px;
      width: 353px;
      left: 2px;
      background-color: var(--guarantee-term-1);
    }

    &:after {
      content: "";
      position: absolute;
      height: 8px;
      width: 8px;
      border-radius: 4px;
      left: 2px;
      top: -3px;
      background-color: var(--guarantee-term-1);
      box-shadow: 0 0 0 5px var(--guarantee-term-1-opacity);
    }
  }

  &__progress-2 {
    &:before {
      width: 527px;
      background-color: var(--guarantee-term-2);
    }

    &:after {
      background-color: var(--guarantee-term-2);
      box-shadow: 0 0 0 5px var(--guarantee-term-2-opacity);
    }
  }

  &__progress-3 {
    &:before {
      width: 100%;
      background-color: var(--guarantee-term-3);
    }

    &:after {
      background-color: var(--guarantee-term-3);
      box-shadow: 0 0 0 5px var(--guarantee-term-3-opacity);
    }
  }

  &__subheading-wrapper {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    font-weight: 500;
  }

  &__subheading {
    font-size: 18px;
  }

  &__term {
    color: var(--button-disabled-color);
    font-size: 16px;
  }

  &__subheading-1 {
    color: var(--guarantee-term-1);
  }

  &__subheading-2 {
    color: var(--guarantee-term-2);
  }

  &__subheading-3 {
    color: var(--guarantee-term-3);
  }

  &__inclusions, &__advanced {
    ul {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      li {
        transition: box-shadow var(--default-transition);
        box-shadow: 0 15px 40px rgba(50, 59, 86, 0);
      }
    }
  }

  &__advanced-item {
    flex-basis: 20%;
    text-align: center;
    border-radius: 0;

    a {
      padding: 24px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &:hover {
      box-shadow: 0 15px 40px rgba(50, 59, 86, 0.12);
      z-index: 50;
      border-radius: 3px;
    }
  }

  &__advanced-icon {
    height: 60px;
    width: 120px;
    margin-bottom: 7px;
    object-fit: contain;
  }

  &__inclusions-item {
    padding: 40px 30px;
    flex-basis: 25%;
    border-radius: 0;
  }

  &__inclusions-icon {
    width: 71px;
    height: 71px;
    margin-bottom: 20px;
  }

  &__inclusions-icon-1 {
    background-image: url("../images/svg/inclusion-1.svg");
  }

  &__inclusions-icon-2 {
    background-image: url("../images/svg/inclusion-2.svg");
  }

  &__inclusions-icon-3 {
    background-image: url("../images/svg/inclusion-3.svg");
  }

  &__inclusions-icon-4 {
    background-image: url("../images/svg/inclusion-4.svg");
  }

  &__inclusions-heading {
    font-weight: 500;
    font-size: 19px;
    padding-bottom: 15px;
  }

  &__inclusions-description {
    font-weight: 400;
    font-size: 15px;
    color: var(--text-color);
  }

  &__disclaimer {
    border-left: 3px solid var(--primary-color);
    padding-left: 24px;
    margin-top: 20px;
    margin-bottom: 10px;
    position: relative;

    &::before {
      position: absolute;
      content: '';
      top: -20px;
      height: 1px;
      background: var(--border-gray-color);
    }
  }
}

@include respond-up('large') {
  .guarantee {
    &__disclaimer {
      &::before {
        left: -50px;
        right: -50px;
      }
    }

    &__manufacturer {
      padding-bottom: 70px;
    }

    &__terms {
      padding: 30px 102px 50px 50px;
      margin-top: 20px;
    }

    &__description {
      font-size: 16px;
    }

    &__description-1 {
      max-width: 353px;
    }

    &__description-2 {
      max-width: 527px;
    }

    &__main {
      padding: 30px 50px 45px;

      h1 {
        padding-bottom: 30px;
      }
    }

    &__picture {
      margin-bottom: 45px;
    }

    &__content-block {
      padding: 30px 50px;
    }

    &__page-wrapper {
      padding-bottom: 70px;
    }

    &__wrapper {
      grid-column: 1/10;
    }

    &__side-wrapper {
      grid-column: 10/13;
    }

    &__block {
      margin-top: 70px;
    }

    &__second {
      margin-top: 28px;
    }

    &__part {
      padding: 50px;
    }
  }
}

@include respond-up('medium') {
  .guarantee {
  }
}

@include respond('medium') {
  .guarantee {
    &__disclaimer {
      &::before {
        left: -30px;
        right: -30px;
      }
    }

    &__wrapper {
      grid-column: 1/7;
    }

    &__manufacturer {
      padding-bottom: 45px;
    }
  }
}

@include respond-down('medium') {
  .guarantee {
    &__page-wrapper {
      padding-bottom: 45px;
    }

    &__block {
      margin-top: 45px;
    }

    &__side-wrapper {
      display: none;
    }

    &__heading {
      padding-bottom: 24px;
    }

    &__inclusions-item {
      flex-basis: 50%;
      padding: 20px 30px;
    }

    &__advanced-item {
      flex-basis: 33%;
    }

    &__content-block {
      padding: 30px;
    }

    &__terms {
      padding: 30px;
    }
  }
}

@include respond-down('small') {
  .guarantee {
    &__disclaimer {
      &::before {
        left: -19px;
        right: -19px;
      }
    }

    &__subheading {
      font-size: 16px;
      padding-bottom: 10px;
    }

    &__subheading-2 {
      padding-bottom: 0;
    }

    &__term {
      font-size: 14px;
    }

    &__disclaimer {
      font-size: 15px;
    }

    &__heading {
      padding-bottom: 16px;
    }

    &__case {
      margin-bottom: 20px;

      &:nth-child(3) {
        margin-bottom: 20px;
      }
    }

    &__subheading-wrapper {
      flex-direction: column;
      padding-bottom: 16px;
    }

    &__progress-1 {
      &:before {
        width: 59px;
      }
    }

    &__progress-2 {
      &:before {
        width: 136px;
      }
    }

    &__description {
      font-size: 17px;
    }

    &__inclusions-item {
      flex-basis: 100%;
      padding: 20px;
    }

    &__inclusions-icon {
      margin-bottom: 14px;
    }

    &__inclusions-heading {
      padding-bottom: 10px;
    }

    &__advanced {
      ul {
        justify-content: flex-start;
      }
    }

    &__advanced-icon {
      height: 40px;
    }

    &__advanced-item {
      flex-basis: 50%;

      a {
        padding: 21px;
      }
    }

    &__page-wrapper {
      padding-bottom: 30px;
    }

    &__block {
      margin-top: 30px;
    }

    &__content-block {
      padding: 20px 19px;
    }

    &__main {
      padding: 20px 19px;

      h1 {
        padding-bottom: 15px;
      }
    }

    &__picture {
      margin-bottom: 20px;
    }

    &__terms {
      padding: 20px 19px;
      margin-top: 20px;
    }

    &__description {
      font-size: 15px;
    }

    &__wrapper {
      grid-column: 1/5;
    }

    &__second {
      margin-top: 14px;
    }

    &__manufacturer {
      padding-bottom: 30px;
    }
  }
}
