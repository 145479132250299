.model-params {
  &__item {
    display: flex;

    &:not(:last-child) {
      border-bottom: 1px solid var(--border-gray-color);
    }
  }

  &__name {
    color: var(--text-color-light);
  }

  &__value {
    flex: 1;
    color: var(--black-color);
  }

  &__description {
    line-height: 1.5;
    color: var(--text-color);
  }

  &__button {

  }
}

@include respond-up('large') {
  .model-params {
    &__name {
      flex: 0 0 196/362*100%;
    }

    &__button {
      display: none;
    }
  }
}

@include respond-up('medium') {
  .model-params {
    &__items {
      margin-bottom: 30px;
    }

    &__item {
      padding: 7px 0 8px;
    }

    &__name,
    &__value {
      padding-right: 8px;
      font-size: 15px;
      line-height: 1.35em;
    }

    &__description {
      font-size: 15px;
      margin-bottom: 30px;
      max-width: 335px;
    }
  }
}

@include respond('medium') {
  .model-params {
    &__name {
      flex: 0 0 179/327*100%;
    }
  }
}

@include respond-down('small') {
  .model-params {
    &__items {
      margin-bottom: 20px;
    }

    &__item {
      padding: 9px 0 9px;
    }

    &__name {
      flex: 0 0 157/300*100%;
    }

    &__name,
    &__value {
      padding-right: 9px;
      font-size: 14px;
      line-height: 1.2em;
    }

    &__description {
      font-size: 15px;
      margin-bottom: 20px;
    }

    &__button {
      width: 100%;
    }
  }
}