.marketplace-accountant-incoming {
  padding: 50px 0;

  table {
    margin-top: 20px;
    margin-bottom: 30px;
    width: 100%;

    tr {
      th {
        font-weight: bold;
      }

      th, td {
        padding: 5px;
        border-bottom: 1px solid #dadada;
        text-align: left;

        a {
          color: #0c4a6e;
          text-decoration: underline;
        }
      }
    }
  }
}