.arrow-round {
  height: 45px;
  width: 45px;
  border-radius: 100px;
  padding: 0;
  margin: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 10px 18px rgba(50, 59, 86, 0.1);

  &:hover {
    background-color: #fff;

    path {
      fill: var(--primary-color);
    }
  }

  &_left {
    .arrow-round {
      &__icon {
        margin-left: 4px;
      }
    }
  }

  &_right {
    .arrow-round {
      &__icon {
        margin-right: 4px;
      }
    }
  }

  &._hide {
    display: none;
  }

  &__icon {
    display: block;
    max-width: 16px;
    max-height: 16px;

    path {
      transition: fill .3s;
    }
  }
}

@include respond-up('large') {
  .arrow-round {
    &_left {

    }

    &_right {

    }

    &__icon {

    }
  }
}

@include respond('medium') {
  .arrow-round {

  }
}

@include respond-up('small') {
  .arrow-round {

  }
}