.header {
  &__top {
    background-color: var(--header-top-background-color);
  }

  &__top-container {
    display: flex;
    align-items: center;
  }

  &__logo {
    display: flex;

    svg {
      height: 100%;
      width: 100%;
    }

    &_index {
      pointer-events: none;
    }
  }

  &__city {
    display: flex;
  }

  &__link-city {
    padding: 16px 20px 15px 0;
  }

  &__time {
    display: flex;
  }

  &__schedule {
    white-space: nowrap;
  }
}

@include respond-up('large') {
  .header {
    &__main {
      padding: 15px 0;
      background-color: white;
      border-bottom: 1px solid var(--border-gray-color);
    }

    &__main-container {
      display: flex;
      align-items: center;
    }

    &__logo-container {
      flex: 0 0 140px;
      display: flex;
      padding-right: 20px;
    }

    &__search-form {
      position: relative;
      flex: 0 0 514px;

      input[type="search"] {
        padding-right: 55px;
      }
    }

    &__search-label {
      display: none;
    }

    &__search-button {
      position: absolute;
      top: 5px;
      right: 5px;
      bottom: 5px;
      display: inline-flex;
      padding: 10px 12px 9px 13px;

      svg.icon-search {
        width: 15px;
        height: 16px;
      }
    }

    &__contact {
      margin-left: auto;
      margin-right: 48px;
      padding-left: 20px;
      display: flex;
    }

    &__contact-wrapper {
      display: flex;
      flex-direction: column;
    }

    &__contact-name {
      margin-bottom: 2px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 1.2em;
      color: var(--text-color-light-2);
    }

    &__contact-phone {
      font-size: 20px;
      line-height: 1.17em;
      color: var(--black-color);

      &_second {
        margin-left: 20px;
      }
    }

    &__city {
      min-width: 210px;
    }

    &__time {
      flex: 1;
    }

    &__button-top {
      display: none;
    }
  }
}

@include respond('medium') {
  .header {
    &__city {
      flex: 1;
    }

    &__time {
      justify-content: flex-end;
    }

    &__button-top {
      background-color: rgba(255, 255, 255, 0.1);
      margin-left: 40px;

      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
  }
}

@include respond-down('medium') {
  .header {
    &__main,
    &__nav-top {
      display: none;
    }
  }
}

@include respond-down('small') {
  .header {
    &__top {
      display: none;
    }
  }
}