.error-page{
  background: #fff;
  border-radius: 3px;
  padding: 75px;
  text-align: center;
  margin: 50px 0 70px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image{
    margin-bottom: 40px;
  }

  &__description{
    color: var(--text-color);
    font-size: 16px;
    padding: 15px 0 45px;
    max-width: 350px;
  }

  &__link{
    width: 154px;
  }
}

@include respond-down('medium'){
  .error-page{
    padding: 60px 50px;
    margin: 30px 0 45px;
  }
}

@include respond-down('small'){
  .error-page{
    margin: 15px 0 30px;

    &__image{
      margin-bottom: 30px;
    }

    &__description{
      font-size: 15px;
      padding: 10px 0 35px;
    }
  }
}