.product-info {
  &__description {
    strong {
      font-weight: 600;
    }
  }

  &__main {
    background-color: white;
    border-radius: 0 0 3px 3px;
  }
}

@include respond-up('large') {
  .product-info {
    &__description {
      padding: 40px 50px;
    }
  }
}

@include respond-up('medium') {
  .product-info {
    &__accord {
      display: none;
    }
  }
}

@include respond('medium') {
  .product-info {
    &__description {
      padding: 30px;
    }
  }
}

@include respond-down('small') {
  .product-info {
    &__tabs {
      display: none;
    }

    &__content {
      overflow: hidden;
      transition: height var(--default-transition);

      &:not(:last-child) {
        border-bottom: 1px solid var(--border-gray-color);
      }

      &.smart-content {
        display: block;
      }
    }

    &__accordion {
      &.open {
        .product-info {
          &__accord {
            color: var(--primary-color);
          }
        }
      }
    }

    &__accord {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px 19px;
      font-weight: 500;
      font-size: 15px;
      line-height: 1.35em;
      color: var(--black-color);
      border-bottom: 1px solid var(--border-gray-color);
      transition: color var(--default-transition);
      cursor: pointer;

      svg path {
        fill: var(--black-color);
      }
    }

    &__description {
      padding: 20px 19px;
    }

    &__params {
      padding: 20px 19px;
    }
  }
}