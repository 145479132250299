.personal-area {
  &__items {
    display: flex;
    align-items: center;
  }

  &__link {
    display: flex;
    align-items: center;

    &:hover {
      .personal-area {
        &__icon {
          svg {
            path {
              fill: var(--primary-color);
            }
          }
        }

        &__name {
          color: var(--primary-color);
        }
      }
    }
  }

  &__icon {
    display: flex;
    position: relative;

    svg {
      path {
        transition: fill var(--default-transition);
      }
    }
  }

  &__counter {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 5px;
    background-color: var(--primary-color);
    border-radius: 100px;
    font-weight: 700;
    font-size: 12px;
    line-height: 14/12*1em;
    color: white;
  }

  &__name {
    display: inline-block;
    margin-left: 12px;
    font-size: 14px;
    line-height: 1.2em;
    color: var(--black-color);
    transition: color var(--default-transition);

    &_sum {
      font-size: 16px;
    }
  }
}

@include respond-up('large') {
  .personal-area {
    &_compact {
      .personal-area {
        &__items {
          margin: 0 -13px;
        }

        &__link {
          padding: 13px;
        }

        &__name {

          &_sum {
            display: inline-block;
          }
        }
      }
    }

    &__items {
      margin: 0 -18px;
    }

    &__link {
      padding: 18px;
    }
  }
}

@include respond-up('medium') {
  .personal-area {
    &__counter {
      left: calc(100% - 4px);
      bottom: calc(100% - 3px);
    }
  }
}

@include respond('medium') {
  .personal-area {
    &__items {
      margin: 0 -13px;
    }

    &__link {
      padding: 13px;
    }

    &__name {
      display: none;

      &_sum {
        display: inline-block;
      }
    }
  }
}

@include respond-down('small') {
  .personal-area {
    &__items {
      margin: 0 -10px;
    }

    &__link {
      padding: 14px 10px 13px;
    }

    &__counter {
      left: calc(100% - 7px);
      bottom: calc(100% - 7px);
    }

    &__name {
      display: none;
    }
  }
}