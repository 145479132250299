.contacts-page {

  &__slider-wrap {
    position: relative;
  }

  &__main-info {
    background-color: var(--border-gray-color);
    display: grid;
    grid-gap: 1px;
    border-radius: 3px;
  }

  &__info-cell {
    background-color: white;
    display: flex;
    flex-direction: column;
  }

  &__info-label {
    color: var(--text-color-light-2);
    font-size: 14px;
    line-height: 120%;
  }

  &__info-value {
    color: var(--black-color);
    font-size: 17px;
    line-height: 124%;
    margin-top: 2px;
    transition: var(--default-transition);

    &_phone {
      font-size: 20px;
    }

    &:hover {
      color: var(--primary-color);
    }
  }

  &__socials {
    margin-top: 10px;
  }

  &__form-wrapper {
    display: flex;
    background-color: white;
  }

  &__form-info {

  }

  &__form-icon {
    width: 71px;
    height: 71px;
    border-radius: 100px;
    background-color: var(--gray-layer);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__form-title {
    font-weight: 500;
    line-height: 117%;
    color: var(--black-color);
  }

  &__form-subtitle {
    line-height: 150%;
    color: var(--text-color);
  }

  &__form-additional {
    font-size: 13px;
    line-height: 120%;
    color: var(--text-color-light-2);
  }

  &__form {
  }

  &__requisites-block {
  }

  &__requisite-list {
    display: grid;
  }

  &__requisite-item {
    display: flex;
    padding: 7px 0;
  }

  &__requisite-name {
    font-size: 15px;
    line-height: 133%;
    color: var(--text-color-light);
  }

  &__requisite-value {
    color: var(--black-color);
    font-size: 15px;
    line-height: 133%;
  }

  &__slider-image-wrapper{
    position: relative;
  }

  &__slider-picture{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__slider-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
  }

  &__arrow {
    position: absolute;
    top: 50%;

    &_left {
      left: 0;
      transform: translate3d(-50%, -50%, 0);
    }

    &_right {
      right: 0;
      transform: translate3d(50%, -50%, 0);
    }
  }

  &__counter {
    position: absolute;
    background: rgba(0, 0, 0, 0.70);
    border-radius: 3px;
    padding: 5px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-family: var(--font);
    font-style: normal;
    font-weight: 500;
  }
}

@include respond-up('large') {
  .contacts-page {

    &__slider-image-wrapper{
      padding-bottom: calc(352/685 * 100%);
    }

    &__wrapper {
      max-height: 352px;
      margin-bottom: 70px;
    }

    &__main-info {
      grid-column: 8/13;
      grid-template-rows: repeat(3,1fr);
      height: 352px;
    }

    &__slider-wrap {
      grid-column: 1/8;
      height: 352px;
    }

    &__info-cell {
      padding: 38px;

      &:first-child {
        border-radius: 3px 3px 0px 0px;
      }

      &:last-child {
        justify-content: center;
        padding: 28px 38px;
        border-radius: 0px 0px 3px 3px;
      }
    }

    &__form-wrapper {
      padding: 50px 60px 60px;
      margin: 70px 0;
      justify-content: space-between;
    }

    &__form-icon {
      margin-bottom: 23px;
    }

    &__form-title {
      max-width: 346px;
    }

    &__form-subtitle {
      max-width: 346px;
      margin-bottom: 40px;
    }

    &__form-additional {
      border-top: 1px solid var(--border-gray-color);
      padding-top: 16px;

      &_small {
        display: none;
      }
    }

    &__requisites-block {
      margin-bottom: 70px;
    }

    &__requisite-list {
      grid-template-columns: 1fr 1fr;
      grid-gap: 0 131px;
      padding-top: 30px;
    }

    &__requisite-item {
      &:nth-child(n+3) {
        border-top: 1px solid var(--button-border-color);
      }
    }


    &__requisite-name {
      flex: 0 0 170/481*100%;
    }


  }
}

@include respond-up('medium') {
  .contacts-page {

    &__form-title {
      font-size: 24px;
      margin-bottom: 20px;
    }

    &__form-subtitle {
      font-size: 16px;
      margin-bottom: 30px;
    }

    &__counter {
      bottom: 14px;
      left: 14px;
      font-size: 15px;
      line-height: 133%;
    }
  }
}

@include respond('medium') {
  .contacts-page {

    &__slider-image-wrapper{
      padding-bottom: calc(355/690 * 100%);
    }

    &__wrapper {
      margin-bottom: 53px;
    }

    &__slider-wrap {
      grid-column: 1/7;
      grid-row: 2/2;
    }

    &__main-info {
      grid-template-columns: 1fr 1fr 1fr;
      grid-column: 1/7;
      grid-row: 1/2;
      margin-bottom: 17px;
    }

    &__info-cell {
      height: 102px;
      padding: 30px;

      &:first-child {
        border-radius: 3px 0 0 3px;
      }

      &:last-child {
        padding-bottom: 17px;
        border-radius: 0 3px 3px 0;
      }
    }

    &__form-wrapper {
      padding: 30px 30px 45px;
      margin: 45px 0;
    }

    &__form-icon {
      margin-bottom: 30px;
    }

    &__form-additional {
      padding-top: 10px;
    }

    &__requisites-block {
      margin-bottom: 45px;
    }

    &__requisite-name {
      flex: 0 0 194/690*100%;
    }
  }
}

@include respond-down('medium') {
  .contacts-page {
    //&__info-cell {
    //  &:last-child {
    //    display: none;
    //  }
    //}

    &__form-wrapper {
      flex-direction: column;
    }

    &__form-additional {
      &_large {
        display: none;
      }
    }

    &__requisite-list {
      grid-template-columns: 1fr;
      padding-top: 27px;
    }

    &__requisite-item {
      &:nth-child(n+2) {
        border-top: 1px solid var(--button-border-color);
      }
    }
  }
}

@include respond-down('small') {
  .contacts-page {
    &__wrapper{
      grid-template-rows: 1fr auto;
    }

    &__slider-image-wrapper{
      padding-bottom: calc(153/298 * 100%);
    }

    &__slider-wrap {
      grid-column: 1/5;
      grid-row: 2/2;
      border-radius: 0px 0px 3px 3px;
      margin-top: 20px;
    }

    &__main-info {
      grid-template-rows: auto auto auto;
      grid-column: 1/5;
      grid-row: 1/2;
    }

    &__info-cell {
      padding: 20px;

      &:first-child {
        border-radius: 3px 3px 0px 0px;
      }

      &:last-child {
        padding-top: 15px;
        margin-bottom: 1px;
      }
    }

    &__form-wrapper {
      padding: 20px 20px 30px;
      margin: 20px 0 30px;
    }

    &__form-icon {
      margin-bottom: 20px;
    }

    &__form-title {
      margin-bottom: 20px;
      font-size: 20px;
    }

    &__form-subtitle {
      margin-bottom: 30px;
      font-size: 15px;
    }

    &__form-additional {
      padding-top: 8px;
    }

    &__requisites-block {
      margin-bottom: 30px;
    }

    &__requisite-name {
      flex: 0 0 135/338*100%;
    }

    &__arrow {
      display: none;
    }

    &__counter {
      bottom: 6px;
      left: 6px;
      font-size: 14px;
      line-height: 120%;
    }
  }
}