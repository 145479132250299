.cart-block {
  padding: 30px 50px;

  &_wide {
    padding: 0;

    .cart-block {
      &__head {
        padding: 30px 50px 0;
      }
    }
  }

  &_wide {

  }

  &__content {

  }

  &__head {
    display: flex;
    align-items: center;
  }

  &__num {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--button-border-color);
    border-radius: 100px;
    font-weight: 700;
    color: var(--primary-color);
    line-height: 1.17;
    font-size: 23px;
    height: 46px;
    min-width: 46px;
  }

  &__title {
    font-weight: 500;
    line-height: 1.17;
  }

  &__content {

  }
}

@include respond-up('large') {
  .cart-block {

    &__head {
      margin-bottom: 28px;
    }

    &__title {
      font-size: 24px;
    }

    &__num {
      margin-right: 20px;
    }
  }
}

@include respond('medium') {
  .cart-block {


    &__head {
      margin-bottom: 28px;
    }

    &__title {
      font-size: 24px;
    }

    &__num {
      margin-right: 20px;
    }
  }
}

@include respond-down('small') {
  .cart-block {
    padding: 14px 19px;

    &_wide {
      padding: 0;

      .cart-block {
        &__head {
          padding: 14px 19px 0;
        }
      }
    }

    &__head {
      margin-bottom: 12px;
    }

    &__title {
      font-size: 20px;
    }

    &__num {
      margin-right: 14px;
    }
  }
}