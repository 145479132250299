.product-list {
  &__items {
    position: relative;
    display: grid;
    background-color: white;

    &::before,
    &::after {
      content: '';
      position: absolute;
      background-color: white;
      pointer-events: none;
    }

    &::before {
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
    }

    &::after {
      top: 0;
      right: 0;
      bottom: 0;
      width: 1px;
    }

    &_list {
      display: flex;
      flex-direction: column;
    }
  }

  &__item {
    box-sizing: border-box;
    border-bottom: 1px solid var(--border-gray-color);
    border-right: 1px solid var(--border-gray-color);
  }

  &__arrow {
    &_left {

    }

    &_right {

    }
  }
}

@include respond-up('large') {
  .product-list {
    &__items {
      grid-template-columns: repeat(4, 25%);
      //grid-template-columns: 1fr 1fr 1fr 1fr;
      //grid-template-columns: repeat( auto-fit, minmax(230px, 1fr) );

      &_producer {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      }
    }
  }
}

@include respond('medium') {
  .product-list {
    &__items {
      grid-template-columns: repeat(3, calc(100%/3));
      //grid-template-columns: 1fr 1fr 1fr;
      //grid-template-columns: repeat( auto-fit, minmax(230px, 1fr) );
    }
  }
}

@include respond('small') {
  .product-list {
    &__items {
      grid-template-columns: repeat( auto-fit, minmax(160px, 1fr) );
    }
  }
}

@include respond-down('x-small') {
  .product-list {
    &__items {
      grid-template-columns: repeat( auto-fit, minmax(140px, 1fr) );
    }
  }
}