/*
spacer - Задаёт фиксированные отступы по краям контейнера.


Использование в шаблоне:

<div class="container container_spacer">
  ...
</div>

По умолчанию в качестве единиц измерения для отступа используются - 'px'.
При необходимости их можно изменить переменной $spacer-unit в файлике ../_common/_container.scss
*/

$small-breakpoint: 375;
$medium-breakpoint: 768;
$large-breakpoint: 1280;

$spacer-smaller: 12;
$spacer-small: 19;
$spacer-medium: 39;
$spacer-large: 39;

$gutter-smaller: 14;
$gutter-small: 14;
$gutter-medium: 24;
$gutter-large: 28;

$grid: (
  x-small: (
    from: 0,
    to: $small-breakpoint - 1,
    spacer: $spacer-smaller,
    gutter: $gutter-smaller
  ),
  small: (
    from: $small-breakpoint,
    to: $medium-breakpoint - 1,
    spacer: $spacer-small,
    gutter: $gutter-small
  ),
  medium: (
    from: $medium-breakpoint,
    to: $large-breakpoint - 1,
    spacer: $spacer-medium,
    gutter: $gutter-medium
  ),
  large: (
    from: $large-breakpoint,
    spacer: $spacer-large,
    gutter: $gutter-large
  )
);

$t: transparent;

:root {
  --small-breakpoint-value: #{$small-breakpoint};
  --medium-breakpoint-value: #{$medium-breakpoint};
  --large-breakpoint-value: #{$large-breakpoint};

  --small-breakpoint: #{$small-breakpoint}px;
  --medium-breakpoint: #{$medium-breakpoint}px;
  --large-breakpoint: #{$large-breakpoint}px;

  --max-row-width: calc(1196 + #{$spacer-large} * 2);

  --spacer-smaller: #{$spacer-smaller}px;
  --spacer-small: #{$spacer-small}px;
  --spacer-medium: #{$spacer-medium}px;
  --spacer-large: #{$spacer-large}px;

  --gutter-smaller: #{$gutter-smaller}px;
  --gutter-small: #{$gutter-small}px;
  --gutter-medium: #{$gutter-medium}px;
  --gutter-large: #{$gutter-large}px;

  --body-font-size: 16px;
  --body-line-height: 1.5;

  --font: "Roboto", sans-serif;
  --h-font: "Roboto", sans-serif;

  --t: transparent;

  --default-transition: .4s ease-in-out;

  --header-z-index: 1000; // sticky header

  --primary-color: #E12727;
  --primary-color-hover: #F72525;
  --primary-color-opacity: rgba(225, 39, 39, 0.45);
  --primary-color-opacity-light: rgba(225, 39, 39, 0.3);

  --alert-color: var(--primary-color);
  --alert-color-opacity: var(--primary-color-opacity);
  --success-color: #39AC26;
  --success-color-opacity: rgba(57, 172, 38, 0.5);
  --black-color: #1f2129;

  --background-color: #F1F2F4;
  --background-color-opacity-30: rgba(241, 242, 244, .3);
  --header-top-background-color: var(--black-color);
  --moderation-slot-background: #FFEDED;
  --moderation-slot-border: #E7B7B7;

  --button-disabled-color: #B6BCCA;
  --button-border-color: #D8D8D8;
  --button-border-color-hover: #C3C4CC;

  --border-gray-color: #E7E7E7;

  --text-color: #787B83;
  --text-color-light: #949494;
  --text-color-light-2: #A6A6A6;

  --breadcrumbs-color: var(--text-color-light);

  --input-border-color: var(--button-border-color);
  --input-border-color-hover: var(--button-border-color-hover);

  --checkbox-border-color: #C3C4CC;

  --link-gray-color: #798297;
  --link-gray-color-opacity: rgba(121, 130, 151, 0.5);

  --gray-layer: #F5F5F6;
  --gray-layer-3: #F7F7F9;

  --corn-color: #FFA321;
  --rating-color: var(--corn-color);
  --bonus-color: var(--corn-color);

  --product-name-border: rgba(31, 33, 41, 0.3);

  --blue-color: #3AA5E1;
  --blue-opacity-color: rgba(58, 165, 225, 0.3);

  --preloader-size: 2em;
  --preloader-color: var(--primary-color);

  --guarantee-term-1: #57BD3D;
  --guarantee-term-2: #3DBD88;
  --guarantee-term-3: #FF5E2C;

  --guarantee-term-1-opacity: rgba(87, 189, 61, 0.3);
  --guarantee-term-2-opacity: rgba(61, 189, 135, 0.3);
  --guarantee-term-3-opacity: rgba(255, 94, 44, 0.3);
}