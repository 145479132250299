.steps-service-tabs {
  .tabs__item {
    &._active {
      ~ .tabs__item {
        cursor: not-allowed;

        .tabs {
          &__link {
            pointer-events: none;
          }

          &__index,
          &__name {
            color: var(--text-color-light);
          }
        }
      }
    }
  }
}

@include respond-up('large') {
  .steps-service-tabs {

  }
}

@include respond-up('medium') {
  .steps-service-tabs {

  }
}

@include respond('medium') {
  .steps-service-tabs {

  }
}

@include respond-down('medium') {
  .steps-service-tabs {

  }
}

@include respond-down('small') {
  .steps-service-tabs {

  }
}