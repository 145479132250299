@include respond-up('large') {
  .about-block {
    &__body {
      padding-top: 20px;
      margin-bottom: 90px;
      display: flex;
      justify-content: space-between;
    }

    &__content {
      flex: 0 0 calc((584 / 1196) * 100%);
    }

    &__image {
      max-height: 320px;
      flex: 0 0 calc((482 / 1196) * 100%);
    }

    &__picture {
      height: 100%;
      width: 100%;
    }

    &__img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 3px;
    }

    &__link {
      margin-top: 24px;
    }
  }
}

@include respond-up('medium') {
  .about-block {
    &__text {
      margin-top: 24px;
    }
  }
}

@include respond('medium') {
  .about-block {
    &__body {
      margin-bottom: 37px;
    }
  }
}

@include respond-down('medium') {
  .about-block {
    &__link {
      margin-top: 20px;
    }

    &__image {
      display: none;
    }
  }
}

@include respond-down('small') {
  .about-block {
    &__body {
      margin-bottom: 30px;
    }

    &__text {
      margin-top: 14px;
    }
  }
}