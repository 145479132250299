.bonus {
  display: inline-flex;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 16/14*1em;
  color: var(--bonus-color);

  &_small {
    font-size: 12px;
    line-height: 14/12*1em;

    svg.icon-prize-yellow {
      margin-right: 5px;
    }
  }

  svg.icon-prize-yellow {
    height: 14px;
    width: 14px;
    margin-right: 6px;
  }
}