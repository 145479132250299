.tabs-mini {

  ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    li {
      display: flex;

      &._active {
        a {
          color: white;
          background-color: var(--black-color);
        }
      }

      a {
        padding: 8px 10px;
        background: white;
        border-radius: 3px;
        color: var(--black-color);
        transition: background-color var(--default-transition), color var(--default-transition);

        &:hover {
          color: white;
          background-color: var(--black-color);
        }
      }
    }
  }

  &_gray ul li a {
    background-color: var(--background-color);
  }

  &_dark {
    ul {
      li {
        &._active {
          a {
            color: var(--text-color);
            background-color: white;
          }
        }

        a {
          &:hover {
            color: var(--text-color);
            background-color: white;
          }
        }
      }
    }

    ul li a {
      color: white;
      background-color: rgba(255, 255, 255, 0.1);
    }

    li {
      &._active {
        a {
          color: var(--text-color);
          background-color: white;
        }
      }
    }
  }
}

@include respond-up('medium') {
  .tabs-mini {
    ul {
      margin: -6px;

      li {
        padding: 6px;

        a {
          font-size: 15px;
          line-height: 1.35em;
        }
      }
    }
  }
}

@include respond-down('small') {
  .tabs-mini {
    ul {
      margin: -5px;

      li {
        padding: 5px;

        a {
          font-size: 13px;
          line-height: 1.2em;
        }
      }
    }
  }
}