.checkbox-list {
  li {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 10px;
    }

    label {
      span.qty {
        margin-left: 5px;
        font-size: 12px;
        line-height: 14/12*1em;
        color: var(--text-color-light-2);
      }
    }
  }
}