.tabs {
  &_wide {
    .tabs {
      &__item {
        flex: 1;

        &:last-child {
          box-shadow: none;
        }
      }
    }
  }

  &_arrowed {
    .tabs {
      &__item {
        display: flex;
        box-shadow: unset;

        &::before {
          left: 0;
          right: 12px;
          z-index: 5;
          clip-path: polygon(0% 0%,99.5% 0%,100% 100%,0 100%);
          transition: left 0s .4s, background-color var(--default-transition);
        }

        &::after {
          right: 13px;
        }

        &._active {
          &::before {
            transition: left 0s, background-color var(--default-transition);
          }

          &::after {
            background-color: #fff;
          }

          &:not(:first-child) {
            &::before,
            &::after {
              left: -13px;
            }
          }

          .tabs {
            &__index {
              color: var(--primary-color);
            }
          }
        }

        // prev el
        &:has(+ ._active) {
          .tabs__item-arrow {
            border-bottom-color: #fff;
          }
        }
      }

      &__name {
        margin-left: 10px;
      }
    }
  }

  &__items {
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 3px 3px 0 0;
    box-shadow: inset 0 -1px 0 var(--border-gray-color);

    &.smart-tabs li a {
      display: flex;
    }
  }

  &__item {
    position: relative;
    box-shadow: inset -1px 0 0 var(--border-gray-color);

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: -1px;
      right: 0;
      height: 3px;
      transition: background-color var(--default-transition);
      pointer-events: none;
    }

    &:first-child {
      &::before {
        left: 0;
      }
    }

    &::before {
      top: 0;
      height: 3px;
      background-color: var(--t);
    }

    &::after {
      bottom: 0;
      height: 1px;
      background-color: var(--border-gray-color);
    }

    &._active {
      background-color: white;

      &::before {
        background-color: var(--primary-color);
      }

      &::after {
        background-color: var(--t);
      }

      .tabs {
        &__name,
        &__count {
          color: var(--primary-color);
        }

        &__icon {
          svg {
            path,
            circle {
              fill: var(--primary-color);
            }
          }
        }
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 33px;
    white-space: nowrap;

    &:hover {
      .tabs {
        &__name,
        &__count {
          color: var(--primary-color);
        }

        &__icon {
          svg {
            path,
            circle {
              fill: var(--primary-color);
            }
          }
        }
      }
    }
  }

  &__icon {
    display: flex;
    margin-right: 12px;

    svg {
      path,
      circle {
        transition: fill var(--default-transition);
      }
    }
  }

  &__name {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2em;
    color: var(--black-color);
    transition: color var(--default-transition);
  }

  &__count {
    margin-left: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16/14*1em;
    color: var(--text-color-light-2);
    transition: color var(--default-transition);
  }

  &__item-arrow {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 13px;
    border-bottom: 1px solid var(--border-gray-color);
    pointer-events: none;
    transition: border-color var(--default-transition);
  }

  &__arrow-top-bar {
    position: absolute;
    background-color: transparent;
    top: 18px;
    right: -13px;
    width: 39px;
    height: 1px;
    display: block;
    transform: rotate(71deg);

    &:after {
      content: "";
      background-color: var(--border-gray-color);
      width: 100%;
      height: 1px;
      display: block;
    }
  }

  &__arrow-bottom-bar {
    position: absolute;
    background-color: transparent;
    bottom: 17px;
    right: -13px;
    width: 39px;
    height: 1px;
    display: block;
    transform: rotate(-71deg);

    &:after {
      content: "";
      background-color: var(--border-gray-color);
      width: 100%;
      height: 1px;
      display: block;
    }
  }

  &__index {
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    color: var(--text-color-light);
    width: 30px;
    height: 30px;
    border: 1px solid var(--button-border-color);
    border-radius: 100%;
    transition: color var(--default-transition);
  }
}

@include respond-up('medium') {
  .tabs {
    &_arrowed {
      .tabs {
        &__link {
          min-height: 74px;
        }
      }
    }

    &__link {
      min-height: 60px;
    }
  }
}

@include respond-down('medium') {
  .tabs {
    &_arrowed {
      .tabs {
        &__items {
          overflow-x: scroll;

          &::-webkit-scrollbar {
            display: none;
          }
        }
      }
    }
  }
}

@include respond-down('small') {
  .tabs {
    &_arrowed {
      .tabs {
        &__link {
          min-height: 68px;
        }
      }
    }

    &__link {
      min-height: 47px;
    }
  }
}