.see-also {
  &__title {
    font-weight: 500;
    font-size: 19px;
    line-height: 1.24em;
    padding: 18px 33px;
    border-bottom: 1px solid var(--border-gray-color);
  }

  &__item {
    &:not(:last-child) {
      .see-also {
        &__link {
          border-bottom: 1px solid var(--border-gray-color);
        }
      }
    }
  }

  &__link {
    font-weight: normal;
    font-size: 15px;
    line-height: 1.33em;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 18px 33px;
    transition: color var(--default-transition);

    &:hover {
      color: red;
    }
  }
}

@include respond('large') {
  .see-also {

  }
}

@include respond('medium') {
  .see-also {

  }
}

@include respond-down('small') {
  .see-also {

  }
}