@include respond-up('large') {
  .vacancies {
    display: grid;
    grid-template-columns: 1fr 278px;
    grid-gap: var(--grid-gap);
    padding-bottom: 70px;

    &__list-wrap {
      grid-column: 1/2;
    }

    &__sticky-info {
      position: relative;
      grid-column: 2/3;
    }
  }
}

@include respond('medium') {
  .vacancies {
    padding-bottom: 45px;
  }
}

@include respond-down('medium') {
  .vacancies {
    &__sticky-info {
      display: none;
    }
  }
}

@include respond-down('small') {
  .vacancies {
    padding-bottom: 30px;
  }
}