.steps-record-for-service {
  &__main {
    display: flex;
    background-color: #fff;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-top: 1px solid var(--border-gray-color);
  }

  &__prev {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: 10px;
    }
  }

  &__policy-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__result {
    background-color: #fff;
  }

  &._service {
    .steps-record-for-service {
      &__buttons {
        justify-content: flex-end;
      }
    }
  }
}

@include respond-up('large') {
  .steps-record-for-service {
    padding-bottom: 70px;

    &__buttons {
      padding: 24px 50px;
    }

    &__result {
      display: none;
    }
  }
}

@include respond-up('medium') {
  .steps-record-for-service {
    &__prev,
    &__next {
      &.button {
        min-width: 192px;
      }
    }

    &__submit {
      &.button {
        min-width: 278px;
      }
    }

    &__policy-wrap {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .steps-record-for-service {
    padding-bottom: 45px;

    &__buttons {
      padding: 24px 30px;
    }

    &__result {
      margin-top: 30px;
    }
  }
}

@include respond-down('medium') {
  .steps-record-for-service {

  }
}

@include respond('small') {
  .steps-record-for-service {
    &__buttons {
      padding: 24px #{$spacer-small}px;
    }
  }
}

@include respond-down('small') {
  .steps-record-for-service {
    padding-bottom: 30px;

    &__prev {
      margin-right: 14px;
    }

    &__next,
    &__submit {
      width: 100%;
    }

    &__policy-wrap {
      margin-top: 14px;
    }

    &__result {
      margin-top: 20px;
    }
  }
}

@include respond('x-small') {
  .steps-record-for-service {
    &__buttons {
      padding: 24px #{$spacer-smaller}px;
    }
  }
}