.side-filter {
  background-color: white;

  &__section {
    padding: 24px;

    &:not(:last-child) {
      border-bottom: 1px solid var(--border-gray-color);
    }
  }

  &__param {
    display: flex;
    align-items: center;

    label {
      flex: 0 0 77px;
      margin-bottom: 0;
      margin-right: 10px;
    }
  }

  &__more-box {
    overflow: hidden;

    &._scrollable {
      overflow-y: scroll;

      &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 5px;
        background-color: var(--border-gray-color);
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: rgba(0, 0, 0, .5);
        box-shadow: 0 0 1px rgba(255, 255, 255, .5);
      }
    }

    &._open {
      max-height: 100%;
      overflow: hidden;

      & + .side-filter__more-link {
        &::after {
          transform: rotate(-90deg);
        }

        .side-filter {
          &__more-link-title {
            &_more {
              display: none;
            }

            &_hide {
              display: block;
            }
          }
        }
      }
    }
  }

  select {
    height: 36px;
    padding: 8px 12px;
    box-sizing: border-box;
  }

  &__count {
    display: none;
  }

  &__more-link-title {
    &_hide {
      display: none;
    }
  }
}

@include respond-up('large') {
  .side-filter {

    &__form {
      position: relative;
    }

    &__param {
      &:not(:last-child) {
        margin-bottom: 8px;
      }
    }

    &__more-box {
      max-height: 320px;
    }

    &__more-link {
      margin-top: 12px;
    }

    &__buttons {
      padding: 24px;
    }

    &__button {
      &:not(:last-child) {
        margin-bottom: 14px;
      }
    }

    &__ninja-button {
      position: absolute;
      top: 0;
      left: calc(100% - 1px);
      padding: 12px 24px;
      opacity: 0;
      pointer-events: none;
      transform: translateY(-50%);
      z-index: 1000;
      transition:
              opacity var(--default-transition),
              background-color var(--default-transition),
              top var(--default-transition);

      &._show {
        opacity: 1;
        pointer-events: auto;
      }

      &:hover {
        &::before {
          border-color: $t var(--primary-color-hover) $t $t;
        }
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 6px 5px 6px 0;
        border-color: $t var(--primary-color) $t $t;
        transition: border-color var(--default-transition);
      }
    }
  }
}

@include respond-up('medium') {
  .side-filter {
    &__label {
      label {
        margin-bottom: 14px;
      }
    }

    &__title-block {
      display: none;
    }
  }
}

@include respond('medium') {
  .side-filter {
    padding: 70px 0 115px;

    &__title-block {
      height: 70px;
      padding: 0 var(--spacer-medium);
    }

    &__buttons {
      height: 92px;
      padding: 0 var(--spacer-medium);
      grid-gap: 23px;
    }
  }
}

@include respond-down('medium') {
  .side-filter {
    display: none;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 2000;
    overflow: auto;

    &._open {
      display: block;
    }

    &__title-block {
      display: flex;
      align-items: center;
      box-shadow: 0 1px 0 var(--border-gray-color);
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      background-color: white;
      z-index: 600;
    }

    &__closer {
      display: flex;
      align-items: center;
      margin-right: 12px;
    }

    &__more-link {
      margin-top: 10px;
    }

    &__more-box {
      max-height: 260px;
    }


    &__buttons {
      position: fixed;
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: white;
      border-top: solid var(--border-gray-color);

      &::before {
        content: '';
        position: absolute;
        pointer-events: none;
        left: 0;
        right: 0;
        bottom: calc(100% + 1px);
        height: 65px;
        background: linear-gradient(180deg, $t 0, white 50%, white 100%);
      }
    }

    &__ninja-button {
      display: none;
    }
  }
}

@include respond-down('small') {
  .side-filter {
    padding: 50px 0 110px;


    &__title-block {
      height: 50px;
      padding: 0 var(--spacer-small);
    }

    &__buttons {
      height: 84px;
      padding: 0 var(--spacer-small);
      grid-gap: 14px;
    }
  }
}