.quote-block {

  &__body {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  &__quotes {
    flex: 0 0 6.3%;
    border-right: 1px solid var(--button-border-color);
  }

  &__content {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    color: #1F2129;

    width: 100%;
  }

  &__pic {
    width: 100%;
    height: 100%;
  }

  &__img {
    object-fit: contain;
  }
}

@include respond('large') {
  .quote-block {
    margin-bottom: 70px;

    &__content {
      margin-left: 46px;
    }

    &__img {
      max-width: 30px;
      max-height: 25px;
      margin-right: 46px;
    }
  }
}


@include respond('medium') {
  .quote-block {
    margin-bottom: 45px;

    &__content {
      margin-left: 30px;
    }

    &__img {
      max-width: 30px;
      max-height: 25px;
      margin-right: 30px;
    }
  }
}

@include respond-down('small') {
  .quote-block {
    margin-bottom: 30px;

    &__content {
      margin-left: 16px;
    }

    &__img {
      max-width: 23px;
      max-height: 19px;
      margin-right: 16px;
    }
  }
}