.store-article-finder {
  margin-top: 30px;
  margin-bottom: 60px;

  &__result {
    padding-top: 10px;
  }

  table {
    td {
      padding: 3px 5px;
      border: 1px solid #dadada;
    }
  }
}