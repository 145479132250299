.actions {
  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1px;
  }

  &__item {
    background-color: white;
  }

  &__link {
    display: flex;
    flex-direction: column;
  }

  &__date {
    display: inline-block;
    font-weight: normal;
    font-size: 15px;
    line-height: 1.33em;
    color: var(--text-color-light-2);
    margin-top: 14px;
  }

  &__image-wrap {
    position: relative;
    display: block;
    padding-top: 62%;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 3px;
  }
}

@include respond-up('large') {
  .actions {
    padding-bottom: 70px;
    &__link {
      padding: 50px;
    }

    &__title {
      margin-top: 24px;
    }
  }
}

@include respond-down('medium') {
  .actions {
    &__title {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .actions {
    padding-bottom: 45px;
    &__link {
      padding: 30px;
    }
  }
}

@include respond-down('small') {
  .actions {
    padding-bottom: 30px;
    &__list {
      grid-template-columns: 1fr;
    }

    &__link {
      padding: 20px;
    }

    &__date {
      margin-top: 10px;
      line-height: 1.5em;
    }
  }
}