.price-slider {
  display: flex;
  position: relative;
  border: 1px solid var(--button-border-color);
  border-bottom: 4px solid var(--button-border-color);
  border-radius: 3px;

  &::before {
    content: '';
    position: absolute;
    z-index: 7;
    left: 50%;
    width: 1px;
    top: 0;
    bottom: 0;
    background-color: var(--button-border-color);
  }


  &__input {
    flex: 0 0 50%;
    border: none !important;
    z-index: 5;

  }

  &__slider {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    bottom: -14px;

    .vue-slider-rail {
      background-color: var(--t);
    }

    .vue-slider-process {
      background-color: var(--primary-color);
    }

    .vue-slider-dot-handle {
      box-shadow: 0 4px 10px rgba(50, 59, 86, 0.12);
      display: flex;
      align-items: center;
      justify-content: center;

      &::before {
        content: '';
        height: 9px;
        width: 9px;
        background-color: var(--primary-color);
        border-radius: 100px;
      }
    }
  }
}