.footer-info {
  &__caption {
    font-size: 14px;
    line-height: 1.2em;
    color: rgba(255, 255, 255, 0.5);
    margin-bottom: 2px;

    &_call {
      &:not(:first-child) {
        margin-top: 12px;
        display: flex;
      }
    }
  }

  &__call-phone {
    display: block;
    font-size: 20px;
    line-height: 1.17em;
    color: white;
  }

  &__write-mail {
    display: block;
    font-size: 17px;
    line-height: 1.24em;
    color: white;
  }
}

@include respond-up('large') {
  .footer-info {

    &__write {
      margin-top: 34px;
    }

    &__button {
      margin-top: 22px;
    }

    &__socials {
      margin-top: 20px;
      &_tab {
        display: none;
      }
    }
  }
}

@include respond('medium') {
  .footer-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 22px 50px;

    &__socials {
      display: none;

      &_tab {
        display: flex;
        align-items: center;
        margin-top: 30px;
      }
    }
  }
}

@include respond-down('medium') {
  .footer-info {
    &__write {
      order: 1;
    }

    &__call {
      order: 2;
    }

    &__socials {
      order: 3;
    }

    &__button {
      order: 4;
      max-width: 190px;
      grid-column: 2/3;
    }
  }
}

@include respond-down('small') {
  .footer-info {
    display: flex;
    flex-direction: column;

    &__button,
    &__write {
      margin-top: 25px;
    }

    &__call {
      order: 1;
    }

    &__button {
      order: 2;
    }

    &__write {
      order: 3;
    }

    &__socials {
      margin-top: 20px;
      order: 4;

      &_tab {
        display: none;
      }
    }
  }
}