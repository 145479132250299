.card-review {
  &__head {
    display: flex;
  }

  &__rating {
    display: flex;
    align-items: center;
  }

  &__rating-title {
    margin-right: 24px;
  }

  &__wrapper {
    display: flex;
  }

  &__date {
    color: var(--text-color-light-2);
  }

  &__text-block {
    background-color: var(--gray-layer);
    border-radius: 3px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 14px;
      left: -30px;
      border-left: 32px solid transparent;
      border-top: 32px solid var(--gray-layer);
    }
  }
}

@include respond-up('large') {
  .card-review {
    padding: 40px 50px;
    &__head {
      margin-bottom: 28px;
      justify-content: space-between;
    }

    &__list {
      margin: -14px 0;
    }

    &__item {
      padding: 14px 0;
    }

    &__name {
      margin-bottom: 9px;
    }

    &__date {
      margin-bottom: 17px;
    }

    &__text-block {
      flex: 0 0 70%;
      padding: 26px 33px;

    }
  }
}
@include respond-up('medium') {
  .card-review {

    &__wrapper {
      justify-content: space-between;
    }

    &__name {
      font-weight: 500;
      font-size: 17px;
      line-height: 124%;
    }

    &__date {
      font-size: 14px;
      line-height: 120%;
    }

    &__text-block {
      font-size: 15px;
      line-height: 150%;
    }
  }
}
@include respond('medium') {
  .card-review {
    padding: 30px;

    &__head {
      justify-content: space-between;
      margin-bottom: 24px;
    }

    &__list {
      margin: -12px 0;
    }

    &__item {
      padding: 12px 0;
    }

    &__who-container {
      flex: 0 0 35%;
    }

    &__name {
      margin-bottom: 9px;
    }

    &__date {
      margin-bottom: 17px;
    }

    &__text-block {
      padding: 30px 24px;
    }
  }
}
@include respond-down('small') {
  .card-review {
    padding: 20px 19px;
    &__head {
      flex-direction: column;
      margin-bottom: 30px;
    }

    &__button {
      order: 2;
    }

    &__rating {
      order: 1;
      justify-content: center;
      margin-bottom: 20px;
    }

    &__list {
      margin: -15px 0;
    }

    &__item {
      padding: 15px 0;
    }

    &__wrapper {
      flex-direction: column;
    }

    &__who-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-bottom: 25px;
    }

    &__name {
      flex: 0 0 55%;
      font-weight: 500;
      font-size: 16px;
      line-height: 120%;
      order: 1;
    }

    &__date {
      flex: 0 0 50%;
      order: 3;
      font-size: 13px;
    }

    &__client-rating {
      flex: 0 0 45%;
      order: 2;
      display: flex;
      justify-content: flex-end;
    }

    &__text-block {
      padding: 20px;
      font-size: 15px;
      line-height: 150%;
      &::before {
        content: '';
        position: absolute;
        top: -15px;
        left: 14px;
        border-left: none;
        border-right: 32px solid transparent;
        border-bottom: 32px solid var(--gray-layer);
        border-top: none;
      }
    }
  }
}