.cart-receiving-delivery {
  &__title {
    font-weight: 500;
    line-height: 1.24;
  }

  &__inputs {
    display: flex;
    flex-wrap: wrap;
  }

  &__input-container {
    flex: 0 0 25%;

    &_address {
      flex: 1 1 100%;
    }
  }

  &__input-label {
    &.required {
      &::after {
        content: '*';
        color: var(--black-color);
      }
    }
  }

  &__input {

  }

  &__errors {

  }

  &__error {

  }

  &__info {
    color: var(--text-color-light-2);
    line-height: 1.2;
  }

  &__radio-list {

  }

  &__radio-item {
    &:last-child {
      .cart-receiving-delivery {
        &__label {
          border-bottom: 1px solid var(--border-gray-color);
        }
      }
    }
  }

  &__radio {

  }

  &__input {
    display: none;

    &:checked+label {
      background-color: var(--gray-layer-3);

      &::after {
        background-color: var(--black-color);
      }
    }
  }

  &__label {
    position: relative;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border-top: 1px solid var(--border-gray-color);
    transition: var(--default-transition);
    margin: 0;

    &::before, &::after {
      position: absolute;
    }

    &::before {
      content: '';
      min-height: 19px;
      min-width: 19px;
      border-radius: 100px;
      border: 1px solid var(--button-border-color);
      box-sizing: border-box;
    }

    &::after {
      content: '';
      background-color: var(--t);
      transition: var(--default-transition);
      border-radius: 100px;
      min-height: 9px;
      min-width: 9px;
    }
  }

  &__name-container {
    display: inline-flex;
    flex-direction: column;
  }

  &__name {
    color: var(--black-color);
  }

  &__description {
    color: var(--text-color-light);
    align-self: flex-start;
    line-height: 1.2;
  }

  &__radio-image {

  }

  &__delivery-description {
    margin-top: 15px;
  }
}

@include respond-up('large') {
  .cart-receiving-delivery {
    padding: 0 50px 40px;

    &__title {
      font-size: 19px;
      margin-bottom: 22px;
    }

    &__inputs {
      margin: 0 -11px;
    }

    &__input-container {
      margin-bottom: 20px;
      padding: 0 11px;

      &_address {

      }
    }

    &__input-label {

    }

    &__input {

    }

    &__errors {

    }

    &__error {

    }

    &__info {
      font-size: 13px;
      margin-bottom: 40px;
    }

    &__radio-list {
      margin: 0 -50px;
    }

    &__radio-item {

    }

    &__radio {

    }

    &__input {

    }

    &__label {
      padding: 17px 50px 17px 77px;

      &::before {
        left: 50px;
        top: 18px;
      }

      &::after {
        left: 55px;
        top: 23px;
      }
    }

    &__name-container {

    }

    &__name {
      margin-bottom: 5px;
    }

    &__description {

    }

    &__radio-image {

    }

    &__delivery-description {
      font-size: 16px;
    }
  }
}

@include respond('medium') {
  .cart-receiving-delivery {
    padding: 0 30px 30px;

    &__title {
      font-size: 19px;
      margin-bottom: 22px;
    }

    &__inputs {
      margin: 0 -11px;
    }

    &__input-container {
      margin-bottom: 20px;
      padding: 0 11px;

      &_address {

      }
    }

    &__input-label {

    }

    &__input {

    }

    &__errors {

    }

    &__error {

    }

    &__info {
      font-size: 13px;
      margin-bottom: 40px;
    }

    &__radio-list {
      margin: 0 -30px;
    }

    &__radio-item {

    }

    &__radio {

    }

    &__input {

    }

    &__label {
      padding: 17px 57px;

      &::before {
        left: 30px;
        top: 18px;
      }

      &::after {
        left: 35px;
        top: 23px;
      }
    }

    &__name-container {

    }

    &__name {
      margin-bottom: 5px;
    }

    &__description {

    }

    &__radio-image {

    }
  }
}

@include respond-down('medium') {
  .cart-receiving-delivery {
    &__delivery-description {
      font-size: 14px;
    }
  }
}

@include respond-down('small') {
  .cart-receiving-delivery {
    padding: 0 19px 20px;

    &__title {
      font-size: 18px;
      margin-bottom: 12px;
    }

    &__inputs {
      margin: 0 -8px;
    }

    &__input-container {
      margin-bottom: 13px;
      padding: 0 8px;
      flex: 0 0 50%;

      &_address {
        flex: 1 1 100%;
      }
    }

    &__input-label {

    }

    &__input {

    }

    &__errors {

    }

    &__error {

    }

    &__info {
      font-size: 13px;
      margin-bottom: 20px;
    }

    &__radio-list {
      margin: 0 -19px;
    }

    &__radio-item {

    }

    &__radio {

    }

    &__input {

    }

    &__label {
      padding: 15px 40px 15px 46px;

      &::before {
        left: 19px;
        top: 14px;
      }

      &::after {
        left: 24px;
        top: 19px;
      }
    }

    &__name-container {

    }

    &__name {
      margin-bottom: 5px;
    }

    &__description {

    }

    &__radio-image {

    }
  }
}