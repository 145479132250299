.cart-receiving-pickup {
  &__city {

  }

  &__city-icon {

  }

  &__city-name {

  }

  &__radio-list {

  }

  &__radio-item {
    &:last-child {
      .cart-payment {
        &__label {
          border-bottom: 1px solid var(--border-gray-color);
        }
      }
    }
  }

  &__radio {

  }

  &__input {
    display: none;

    &:checked+label {
      background-color: var(--gray-layer-3);

      &::after {
        background-color: var(--black-color);
      }
    }
  }

  &__label {
    position: relative;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border-top: 1px solid var(--border-gray-color);
    transition: var(--default-transition);
    margin: 0;

    &::before, &::after {
      position: absolute;
    }

    &::before {
      content: '';
      min-height: 19px;
      min-width: 19px;
      border-radius: 100px;
      border: 1px solid var(--button-border-color);
      box-sizing: border-box;
    }

    &::after {
      content: '';
      background-color: var(--t);
      transition: var(--default-transition);
      border-radius: 100px;
      min-height: 9px;
      min-width: 9px;
    }
  }

  &__name-container {
    display: flex;
    flex-direction: column;
  }

  &__name-wrapper {

  }

  &__name {
    color: var(--black-color);
  }

  &__shop-label {
    border-radius: 3px;
    border: 1px solid var(--primary-color-opacity-light);
    font-size: 14px;
    line-height: 1.2;
    color: var(--primary-color);
    padding: 1px 6px;
  }

  &__description {
    color: var(--text-color-light);
    line-height: 1.2;
  }

  &__availability {
    position: relative;
    align-self: flex-start;

    &:hover {
      .cart-receiving-pickup {
        &__availability-list {
          opacity: 1;
          pointer-events: auto;
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  &__availability-name {
    color: var(--success-color);
    border-bottom: 1px dashed var(--success-color);
    line-height: 1.2;
  }

  &__availability-list {
    opacity: 0;
    pointer-events: none;
    transform: translate3d(0, 30px, 0);
    transition: all .25s;

    position: absolute;
    min-width: 221px;
    left: 0;
    top: 24px;
    background-color: var(--black-color);
    border-radius: 3px;
    z-index: 10;
    display: flex;
    flex-direction: column;

    &::before {
      content: '';
      position: absolute;
      min-height: 25px;
      right: 0;
      left: 0;
      top: -25px;
    }
  }

  &__availability-title {
    color: #fff;
    line-height: 1.2;
    font-size: 13px;
    font-weight: 500;
    padding: 10px 15px 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  }

  &__availability-item {
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);
    display: flex;
    justify-content: space-between;
    padding: 6px 15px;
    font-size: 13px;
  }

  &__availability-item-name {
    color: rgba(255, 255, 255, 0.7);
  }

  &__availability-item-count {
    white-space: nowrap;
    color: #fff;
    font-weight: 500;
  }

  &__map {

  }
}

@include respond-up('large') {
  .cart-receiving-pickup {
    &__city {
      margin-left: 50px;
      margin-bottom: 27px;
    }

    &__city-icon {

    }

    &__city-name {

    }

    &__radio-list {

    }

    &__radio-item {

    }

    &__radio {

    }

    &__input {

    }

    &__label {
      padding: 17px 154px 17px 77px;

      &::before {
        left: 50px;
        top: 18px;
      }

      &::after {
        left: 55px;
        top: 23px;
      }
    }

    &__name-container {

    }

    &__name-wrapper {
      margin-bottom: 5px;
    }

    &__name {
      margin-right: 10px;
    }

    &__shop-label {

    }

    &__description {
      font-size: 14px;
    }

    &__availability {

    }

    &__availability-name {
      font-size: 14px;
    }

    &__availability-list {

    }

    &__availability-title {

    }

    &__availability-item {

    }

    &__availability-item-name {

    }

    &__availability-item-count {

    }

    &__map {

    }
  }
}

@include respond('medium') {
  .cart-receiving-pickup {
    &__city {
      margin-left: 30px;
      margin-bottom: 23px;
    }

    &__city-icon {

    }

    &__city-name {

    }

    &__radio-list {

    }

    &__radio-item {

    }

    &__radio {

    }

    &__input {

    }

    &__label {
      padding: 17px 57px;

      &::before {
        left: 30px;
        top: 18px;
      }

      &::after {
        left: 35px;
        top: 23px;
      }
    }

    &__name-container {

    }

    &__name-wrapper {
      margin-bottom: 5px;
    }

    &__name {
      margin-right: 10px;
    }

    &__shop-label {

    }

    &__description {
      font-size: 14px;
    }

    &__availability {

    }

    &__availability-name {
      font-size: 14px;
    }

    &__availability-list {

    }

    &__availability-title {

    }

    &__availability-item {

    }

    &__availability-item-name {

    }

    &__availability-item-count {

    }

    &__map {

    }
  }
}

@include respond-down('small') {
  .cart-receiving-pickup {
    &__city {
      margin-left: 19px;
      margin-bottom: 20px;
    }

    &__city-icon {

    }

    &__city-name {

    }

    &__radio-list {

    }

    &__radio-item {

    }

    &__radio {

    }

    &__input {

    }

    &__label {
      padding: 15px 40px 15px 46px;
      flex-direction: column;
      align-self: flex-start;

      &::before {
        left: 19px;
        top: 14px;
      }

      &::after {
        left: 24px;
        top: 19px;
      }
    }

    &__name-container {
      margin-bottom: 12px;
    }

    &__name-wrapper {
      margin-bottom: 6px;
      display: flex;
      align-self: flex-start;
      flex-direction: column;
    }

    &__name {
      font-size: 14px;
    }

    &__shop-label {
      margin-top: 8px;
    }

    &__description {
      font-size: 13px;
    }

    &__availability {

    }

    &__availability-name {
      font-size: 13px;
    }

    &__availability-list {

    }

    &__availability-title {

    }

    &__availability-item {

    }

    &__availability-item-name {

    }

    &__availability-item-count {

    }

    &__map {

    }
  }
}