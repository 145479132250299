.record-for-service-contacts {
  &__title {
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 1.17;

    &::before {
      content: '3';
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--button-border-color);
      border-radius: 100px;
      font-weight: 700;
      color: var(--primary-color);
      line-height: 1.17;
      font-size: 23px;
      height: 46px;
      min-width: 46px;
    }
  }

  &__input-list {
    display: flex;
  }

  &__input-label {
    &.required {
      &::after {
        content: '*';
        color: var(--black-color);
      }
    }
  }

  &__info {
    color: var(--text-color-light-2);
    line-height: 1.2;
  }

  &__input-item {
    &_phone {
      input {
        box-shadow: none;
        margin: 0;
        padding: 12px;
        width: 100%;
        display: block;
        appearance: none;
        font-family: var(--font);
        font-size: 15px;
        line-height: 1.33em;
        color: var(--black-color);
        border: 1px solid var(--input-border-color);
        border-radius: 3px;
        background-color: white;
        transition: border-color var(--default-transition);
        height: var(--default-input-height);

        &:hover, &:focus {
          border-color: var(--input-border-color-hover);
        }

        &::placeholder {
          font-size: 15px;
          line-height: 1.33em;
          color: var(--text-color-light-2);
        }

        &._error {
          border-color: var(--errors-color)
        }
      }
    }
  }
}

@include respond-up('large') {
  .record-for-service-contacts {

    &__title {
      font-size: 24px;
      margin-bottom: 28px;

      &::before {
        margin-right: 20px;
      }
    }

    &__input-list {
      margin: 0 -11px 21px;
      flex-wrap: wrap;
    }

    &__input-item {
      padding: 0 11px;
      flex: 0 0 50%;
    }

    &__info {
      font-size: 13px;
    }
  }
}

@include respond('medium') {
  .record-for-service-contacts {

    &__title {
      font-size: 24px;
      margin-bottom: 18px;

      &::before {
        margin-right: 20px;
      }
    }

    &__input-list {
      margin: 0 -11px 11px;
      flex-wrap: wrap;
    }

    &__input-item {
      padding: 0 11px;
      flex: 0 0 50%;
    }

    &__info {
      font-size: 13px;
    }
  }
}

@include respond-down('small') {
  .record-for-service-contacts {

    &__title {
      font-size: 20px;
      margin-bottom: 12px;

      &::before {
        margin-right: 14px;
      }
    }

    &__input-list {
      margin-bottom: -4px;
      flex-direction: column;
    }

    &__input-item {
      flex: 1 1 100%;
      margin-bottom: 13px;
    }

    &__info {
      font-size: 13px;
    }
  }
}