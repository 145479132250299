.moderation-services-main {
  padding: 60px;

  &__container {
    display: flex;
    align-items: flex-start;
  }

  &__calendar {
    padding: 33px;
    max-width: 498px;
    background-color: white;
  }

  &__items {
    margin-left: 28px;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    background-color: white;
    max-width: 241px;
    box-shadow: 0px 1px 0px var(--border-gray-color);
    border-right: 1px solid var(--border-gray-color);
  }

  &__item-title {
    display: flex;
    align-items: center;
    min-height: 68px;
    font-weight: 500;
    font-size: 15px;
    line-height: 133%;
    padding: 14px 44px 14px 22px;
    border-bottom: 1px solid var(--border-gray-color);
  }

  &__item-content {
    padding: 20px 22px;
  }

  &__time-list {
    display: flex;
    flex-wrap: wrap;
    margin: -3px;
  }

  &__time {
    padding: 3px;

    &:nth-child(11) {
      .moderation-services-main__link {
        background-color: var(--moderation-slot-background);
        color: var(--primary-color);
        border: 1px solid var(--moderation-slot-border);
      }
    }

    &:nth-child(16) {
      .moderation-services-main__link {
        background-color: var(--moderation-slot-background);
        border: 1px solid var(--moderation-slot-border);
        color: var(--primary-color);
      }
    }
  }

  &__link {
    display: block;
    font-weight: normal;
    font-size: 15px;
    line-height: 133%;
    border: 1px solid #D8D8D8;
    box-sizing: border-box;
    border-radius: 3px;
    padding: 5px 27.5px;
    transition: var(--default-transition);

    &:hover {
      background-color: var(--gray-layer);
      transition: var(--default-transition);
    }
  }
}