.record-for-service-point {
  --transition-calendar: all .3s;

  &__choose-address {
    margin-bottom: 40px;
  }

  &__item a {
    white-space: nowrap;
  }

  &__title {
    margin-bottom: 15px;
  }

  &__main {
    display: flex;
    justify-content: space-between;
    position: relative;
  }

  &__main-locked {
    position: absolute;
    z-index: 100;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255,255,255,0.9);
    color: rgba(0,0,0,0.7);
    font-size: 20px;
    text-align: center;
  }

  &__main-locked-svg {
    //position: absolute;
    //width: 30px;
    //height: 30px;
    //top: 10px;
    //display: flex;
    //align-items: center;
    //justify-content: center;
    //text-align: center;
    //left: 50%;
    //margin-left: -15px;
    font-size: 35px;
    //font-weight: 100;
    color: rgba(0,0,0,0.4);
  }

  &__time-list {
    align-self: flex-start;
    display: flex;
    flex-wrap: wrap;
    margin: -4px 0;
  }

  &__item {
    padding: 4px;
    flex: 0 0 20%;

    &._disabled {
      .record-for-service-point__item-link {
        border-color: var(--button-border-color);
        color: var(--black-color);
        opacity: 0.4;
        pointer-events: none;
      }
    }
  }

  input {
    display: none;

    &:checked + .record-for-service-point__item-link {
      background-color: var(--primary-color);
      color: white;
    }
  }

  &__item-link {
    margin-bottom: 0;
    font-weight: normal;
    font-size: 15px;
    line-height: 133%;
    display: flex;
    justify-content: center;
    padding: 7px 13px;
    border: 1px solid var(--button-border-color);
    border-radius: 3px;
    cursor: pointer;
    transition: var(--default-transition);
    color: var(--black-color);
  }

  &__calendar {
    .vdp-datepicker {
      &__calendar {
        border-radius: 3px;
        border: 1px solid var(--border-gray-color);
      }

      header {
        display: flex;
        padding: 10px 18px;
        border-bottom: 1px solid var(--border-gray-color);
      }

      span.prev, span.next {
        position: relative;
        border-radius: 100px;
        box-shadow: 0 10px 18px rgba(50, 59, 86, 0.1);
        min-width: 40px;
        transition: var(--transition-calendar);

        &:hover {
          background-color: var(--background-color);
        }

        &:after {
          display: none;
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 10;
          background: {
            size: 15px;
            repeat: no-repeat;
            position: center;
          };
        }
      }

      span.prev {
        &:before {
          margin-left: 2px;
          background: {
            image: url('/static/images/svg/arrows/arrow-left.svg');
          };
        }
      }

      span.next {
        &:before {
          margin-right: 3px;
          background: {
            image: url('/static/images/svg/arrows/arrow-right.svg');
          };
        }
      }

      .day__month_btn {
        font-weight: 500;
        font-size: 16px;
        margin: 0 15px;

        &.up {
          transition: var(--transition-calendar);

          &:hover {
            background-color: var(--background-color);
          }
        }
      }

      .cell {
        &.day {
          font-size: 15px;
          position: relative;
          z-index: 5;
          background-color: transparent !important;
          font-weight: 400;

          &:after {
            content: '';
            position: absolute;
            top: 2px;
            right: 0;
            bottom: 0;
            left: 2px;
            border-radius: 100px;
            z-index: -1;
            height: 36px;
            width: 36px;
            background-color: transparent;
            transition: var(--transition-calendar);
          }

          &.selected {
            color: white;

            &:after {
              background-color: var(--primary-color);
            }
          }
        }

        &:not(.blank):not(.disabled).day:hover {
          border: 1px solid transparent;
        }

        &:not(.blank):not(.disabled):not(.selected).day:hover {
          &:after {
            background-color: white;
          }
        }

        &.day-header {
          position: relative;
          text-transform: uppercase;
          font-size: 11px;
          font-weight: 500;

          &:after {
            content: '';
            position: absolute;
            bottom: 0;
            right: -1px;
            left: -1px;
            height: 1px;
            background-color: var(--border-gray-color);
          }
        }
      }

      header + div {
        background-color: var(--gray-layer);
      }
    }
  }
}

@include respond-up('large') {
  .record-for-service-point {

    &__calendar {
      /*margin-right: 55px;*/
      flex: 0 0 46.71%;
    }

    &__time-list {
      flex: 0 0 50%;
    }

    &__item:not(._disabled) {
      &:hover {
        input + .service-point__item-link {
          background-color: var(--primary-color);
          color: white;
        }
      }
    }
  }
}

@include respond-down('medium') {
  .record-for-service-point {
    &__calendar {
      margin-right: 30px;
      flex: 0 0 49%;
    }

    &__time-list {
      flex: 0 0 50%;
    }
  }
}

@include respond-down('small') {
  .record-for-service-point {
    &__main {
      flex-direction: column;
    }

    &__calendar {
      margin-bottom: 20px;
    }

    &__time-list {
      margin: -4.5px 0;
    }

    &__item {
      padding: 4.5px;
    }

    &__item-link {
      padding: 7px 13px;
    }
  }
}