.service {
  position: relative;

  article {
    margin-top: 0;
  }

  &__main-prices,
  &__main-content-item,
  article {
    overflow: scroll hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow: -moz-scrollbars-none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
      width: 0 !important;
      height: 0 !important;
    }
  }

  &._loading {
    .service {
      &__box {
        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 15;
          pointer-events: auto;
          //background-color: rgba(120, 123, 131, .4);
        }
      }
    }
  }

  &__box {
    position: relative;
    background-color: white;
  }

  &__wrapper {
    display: grid;
  }

  &__main {
    background-color: white;
  }

  &__main-title {
    font-weight: 500;
    font-size: 30px;
    line-height: 1.17em;
    margin-bottom: 30px;
  }

  &__main-image {
    width: 100%;
  }

  &__head {
    display: flex;
    margin-right: -20px;
  }

  &__sticky {
    display: none;
  }

  &__main-record-title {
    font-weight: 500;
    line-height: 1.24em;
  }

  &__main-title {
    grid-area: title;
  }

  &__main-picture {
    grid-area: picture;
  }

  &__main-record {
    background-color: var(--background-color);
    grid-area: record;
  }

  &__main-record-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__main-short-text {
    margin-bottom: 45px;
    grid-area: short-text;
  }

  &__main-text {
    grid-area: text;
    margin-bottom: 45px;
  }

  &__main-text-bottom {
    grid-area: text-bottom;
    margin-bottom: 45px;
  }

  &__main-prices-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.17em;
    margin-bottom: 20px;
  }

  &__main-tabs-container {
    margin-bottom: 30px;
  }

  &__bottom-wrap {
    position: relative;
    background-color: white;
    border-radius: 3px;
  }

  &__slider-list {
    overflow: scroll hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow: -moz-scrollbars-none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
      width: 0 !important;
      height: 0 !important;
    }
  }

  &__slider-link {
    position: relative;
    display: flex;
    align-items: flex-end;
    border-radius: 3px;
    background-size: cover;

    &::after {
      position: absolute;
      z-index: 1;
      content: '';
      border-radius: 3px;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background: rgba(31, 33, 41, 0.3);
      opacity: 0;
      transition: opacity var(--default-transition);
    }

    &:hover {
      &::before {
        opacity: 1;
      }
    }
  }

  &__slider-name {
    font-weight: 500;
    line-height: 1.24em;
    color: white;
    display: inline-block;
    z-index: 10;
  }

  &__form {
    .recall-form {
      &__title {
        font-weight: 500;
        font-size: 24px;
        line-height: 1.17em;
        margin-bottom: 20px;
        color: white;
      }

      &__top {
        display: flex;
      }

      &__fields {
        display: flex;
      }

      &__field {
        max-height: 44px;

        label {
          display: none;
        }

        input::-webkit-input-placeholder {
          color: black;
          font-family: var(--font);
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 1.33em;
        }
      }

      &__button {
        button {
          min-width: 200px;
        }
      }

      &__politic {
        color: var(--text-color-light);
        font-weight: normal;
        font-size: 13px;
        line-height: 1.2em;
      }

      &__politic_link {
        color: red;
      }
    }
  }

  &__child-item {
    text-decoration: underline;
    color: var(--blue-color);
  }

  &__main-content {
    overflow: scroll hidden;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    overflow: -moz-scrollbars-none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
      width: 0 !important;
      height: 0 !important;
    }
  }

  &__main-picture {
    display: flex;
    width: 100%;
  }

  &__main-image {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@include respond-up('large') {
  .service {
    padding-bottom: 70px;

    &__main-content-item {
      overflow: scroll hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      overflow: -moz-scrollbars-none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
        width: 0 !important;
        height: 0 !important;
      }
    }

    &__top {
      display: grid;
      grid-column-gap: 28px;
      grid-template-areas: 'main sticky'
                           'form sticky';
      border-radius: 3px;
      margin-bottom: 70px;
    }

    &__main {
      grid-area: main;
      padding: 30px 50px 45px;
      max-width: 890px;
    }

    &__form-wrap {
      grid-area: form;
      max-width: 890px;
    }

    &__sticky {
      grid-area: sticky;
      display: block;
      position: sticky;
      top: 107px;
      height: max-content;
      max-width: 278px;
    }

    &__see-also {
      background-color: white;
      margin-bottom: 28px;
      border-radius: 3px;
    }

    &__record {
      background-color: white;
      padding: 27px 33px 33px;
      border-radius: 3px;
    }

    &__record-title {
      font-weight: 500;
      font-size: 19px;
      line-height: 1.24em;
      margin-bottom: 17px;
    }

    &__record-button {
      width: 100%;
    }

    &__main-record, &__sticky-record {
      display: none;
    }

    &__bottom-wrap {
      padding: 28px;
    }

    &__bottom-title {
      margin-bottom: 28px;
    }

    &__slider-list {
      background-color: white;
    }

    &__slider-link {
      width: 100%;
      height: 184px;
    }

    &__slider-name {
      font-size: 19px;
      padding: 0 28px 15px;
    }

    &__form {
      padding: 40px 46px;
      background: url('../images/service/tyres-desktop.png') no-repeat;
      background-size: cover;

      .recall-form {
        &__top {
          margin-bottom: 16px;
        }

        &__field {
          min-width: 240px;
          margin-right: 22px;
        }
      }
    }

    &__child-container {
      margin-top: 45px;
    }

    &__main-picture {
      height: calc(var(--grid-column8) * 570 / 790);
    }
  }
}

@include respond-up('medium') {
  .service {
    &__main-picture {
      margin-bottom: 45px;
    }
  }
}

@include respond-down('medium') {
  .service {
    &__main-prices {
      overflow: scroll;
    }

    &__main-tab-name {
      white-space: nowrap;
    }

    &__main-tabs-container {
      overflow: scroll hidden;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      overflow: -moz-scrollbars-none; /* Firefox */

      &::-webkit-scrollbar {
        display: none;
        width: 0 !important;
        height: 0 !important;
      }
    }

    &__child-container {
      margin-top: 30px;
    }

    &__main-prices-wrap {
      position: relative;
      overflow-x: hidden;
    }

    &__table-wrap {
      position: relative;
      &:before {
        position: absolute;
        content: '';
        bottom: 0;
        background: linear-gradient(270deg, #FFFFFF 19.12%, rgba(255, 255, 255, 0) 100%);
      }
    }

    &__sticky-record {
      position: sticky;
      display: flex;
      bottom: 20px;
      top: calc(100vh - 150px);

      .button {
        width: 100%;
      }
    }
  }
}

@include respond('medium') {
  .service {
    padding-bottom: 45px;

    &__top {
      margin-bottom: 45px;
    }

    &__main {
      display: grid;
      grid-template-areas: 'title'
                           'picture'
                           //'record'
                           'short-text'
                           'prices'
                           'text'
                           'text-bottom';
      padding: 30px;
    }

    &__prices {
      grid-area: prices;
    }

    &__main-record {
      margin-bottom: 45px;
      padding: 22px 38px 21px 30px;
    }

    &__main-record-button {
      min-width: 212px;
    }

    &__main-record-title {
      font-size: 19px;
      flex: 0 0 22%;
    }

    &__bottom-wrap {
      padding: 24px;
    }

    &__bottom-title {
      margin-bottom: 20px;
    }

    &__slider-link {
      height: calc((140 / 768) * 100vw);

      &:before {
        height: 70%;
      }
    }

    &__slider-name {
      font-size: 19px;
      padding: 20px;
    }

    &__form {
      padding: 30px;
      background: url('../images/service/tyres-tablet.png') no-repeat;
      background-size: cover;

      .recall-form {
        &__top {
          margin-bottom: 15px;
        }

        &__fields {
          width: 100%;
        }

        &__field {
          margin-right: 20px;
          width: 100%;
          max-width: 300px;
        }
      }
    }

    &__table-wrap {
      &:before {
        right: -30px;
        height: 100%;
        width: 69px;
      }
    }

    &__main-picture {
      height: calc((var(--grid-column6) - 60px) * 389 / 630);
    }
  }
}

@include respond-down('small') {
  .service {
    padding-bottom: 30px;

    &__top {
      margin-bottom: 30px;
    }

    &__main {
      display: grid;
      grid-template-areas: 'title'
                           'picture'
                           'short-text'
                           //'record'
                           'prices'
                           'text'
                           'text-bottom';
      padding: 20px 20px 30px;
    }

    &__prices {
      grid-area: prices;
    }

    &__main-title {
      font-size: 23px;
      margin-bottom: 20px;
    }

    &__main-picture {
      margin-bottom: 20px;
      height: calc((var(--grid-column4) - 40px) * 202 / 300);
    }

    &__main-short-text {
      margin-bottom: 30px;
    }

    &__main-record {
      padding: 20px;
      margin-bottom: 30px;
    }

    &__main-record-wrap {
      flex-wrap: wrap;
      margin: -8px;
    }

    &__main-record-title {
      font-size: 18px;
      padding: 8px;
    }

    &__main-record-button-wrap {
      padding: 8px;
    }

    &__main-record-button {
      min-width: 209px;
    }

    &__main-text {
      margin-bottom: 30px;
    }

    &__main-text-bottom {
      margin-bottom: 30px;
    }

    &__main-prices-title {
      font-size: 20px;
      margin-bottom: 12px;
    }

    &__main-tabs-container {
      margin-bottom: 22px;
    }

    &__bottom-wrap {
      padding: 14px 0 4px 14px;
      overflow: hidden;
      margin: 0 -10px 0 0;
    }

    &__bottom-title {
      margin-bottom: 12px;
    }

    &__slider-list {
      overflow: scroll;
      display: flex;
    }

    &__slider-item {
      margin-right: 14px;
      margin-bottom: 10px;
    }

    &__slider-link {
      width: 200px;
      height: 140px;

      &:before {
        height: 90%;
      }
    }

    &__slider-name {
      padding: 20px;
      font-size: 18px;
    }

    &__form {
      padding: 30px 19px;
      background: url('../images/service/tyres-mobile.png') no-repeat;
      background-size: cover;

      .recall-form {
        &__title {
          font-size: 20px;
        }

        &__top {
          margin-bottom: 10px;
        }

        &__fields {
          flex-wrap: wrap;
          width: 100%;
        }

        &__field {
          width: 100%;
          margin-bottom: 14px;
        }

        &__button {
          width: 100%;

          button {
            width: 100%;
          }
        }

        &__politic {
          font-size: 12px;
        }
      }
    }

    &__table-wrap {
      &:before {
        right: -20px;
        height: 100%;
        width: 54px;
      }
    }
  }
}