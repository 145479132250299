.contacts-map {
  position: relative;

  &__item-image {
    min-width: 90px;
    height: 90px;
    align-self: center;
  }

  &__content-wrap {
    display: flex;
    justify-content: space-between;
  }

  &__address-wrapper {
    background-color: white;
    z-index: 10;
  }

  &__local {
    display: flex;
    align-items: center;
  }

  &__local-icon {
    margin-right: 8px;
    width: 8px;
    height: 15px;
  }

  &__local-name {
    font-size: 14px;
    line-height: 120%;
    color: var(--primary-color);
  }

  &__item-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 30;
    pointer-events: none;
  }

  &__item {
    position: relative;
    border-top: 1px solid var(--gray-layer-3);
  }

  &__address {
    font-weight: 500;
    font-size: 17px;
    line-height: 124%;
    margin-bottom: 7px;
    display: block;
  }

  &__contact-value {
    white-space: nowrap;
    font-size: 14px;
    line-height: 120%;
    pointer-events: all;
    transition: var(--default-transition);

    &:hover, &_link {
      color: var(--primary-color);
    }
  }

  &__contact-name {
    font-size: 14px;
    line-height: 120%;
    margin-left: 10px;
  }

  &__schedule {
    font-size: 14px;
    line-height: 120%;
    color: var(--text-color-light);
  }

  &__item-link {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 15;
  }

  &__contact-item {
    display: flex;
    margin-bottom: 7px;
  }
}

@include respond-up('large') {
  .contacts-map {

    &__item-wrapper {
      margin-right: 54px;
    }

    &__address-wrapper {
      width: 482px;
      top: 52px;
      left: calc(((100% - (var(--max-row-width) * 1px)) / 2) + var(--spacer-large));
    }

    &__title {
      padding: 24px 38px 0;
      margin-bottom: 13px;
    }

    &__local {
      padding: 0 38px;
      margin-bottom: 24px;
    }

    &__list {
      display: flex;
      flex-direction: column;
      max-height: 600px;
      //max-height: 511px;
      //overflow-y: scroll;

      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__item {
      padding: 24px 38px;
      border-top: 1px solid var(--border-gray-color);
    }

    &__address {
      margin-bottom: 7px;
    }

    &__number {
      margin-bottom: 7px;
    }

    &__map {
      min-height: 789px;
    }

    &__main {
      display: none;
    }
  }
}

@include respond-up('medium') {
  .contacts-map {

    &__item-image {
      position: relative;
      display: flex;
      z-index: 100;
      pointer-events: none;
    }

    &__tabs-wrapper {
      display: none;
    }

    &__address-wrapper {
      overflow: scroll;
      position: absolute;
      box-shadow: 0 15px 25px rgba(50, 59, 86, 0.07);
    }

    &__item-link {
      transition: var(--default-transition);

      &:hover {
        background-color: var(--gray-layer-3);
      }

      &._active {
        background-color: var(--gray-layer-3);
      }
    }
  }
}

@include respond('medium') {
  .contacts-map {

    &__list {
      max-height: 540px;
    }

    &__item-wrapper {
      margin-right: 43px;
    }

    &__address-wrapper {
      top: 32px;
      width: 452px;
      left: var(--spacer-medium);
    }


    &__title {
      padding: 24px 30px 0;
      margin-bottom: 13px;
    }

    &__local {
      padding: 0 30px 0;
      margin-bottom: 24px;
    }

    &__item {
      padding: 24px 30px;
    }

    &__address {
      margin-bottom: 7px;
    }

    &__number {
      margin-bottom: 7px;
    }

    &__map {
      //min-height: 573px;
      min-height: 786px;
    }

    &__main {
      display: none;
    }
  }
}

@include respond-down('small') {
  .contacts-map {
    padding: var(--spacer-small);

    &__item-image {
      display: none;
    }

    &__title {
      padding: 20px 19px 0;

      &_mobile {
        margin-bottom: 14px;
      }
    }

    &__tabs-wrapper {
      margin: 17px 0 20px;
      padding: 0 19px;
    }

    &__main {
      background-color: white;
    }

    &__local {
      padding: 0 19px 20px;
    }

    &__item {
      padding: 14px 19px;
    }

    &__map {
      display: none;
    }

    &__map-mobile {
      min-height: 373px;
    }

    &._map-opened {
      .contacts-map {
        &__list {
          display: none;
        }

        &__map-container, &__map {
          display: block;
          height: 373px;
        }
      }
    }
  }
}