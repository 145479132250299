.model-price-box {
  background-color: #fff;
  border-radius: 3px;

  &__price-container {

  }

  &__price {
    line-height: 1.17;
    font-weight: 500;
  }

  &__price-complect {
    line-height: 1.33;
  }

  &__availability {
    color: var(--success-color);
    line-height: 1.2;
  }

  &__button {
    width: 100%;
  }

  &__help-button {
    display: flex;
    justify-content: center;
    border-top: 1px solid var(--border-gray-color);
    padding: 17px 0;
  }
}

@include respond-up('large') {
  .model-price-box {
    padding: 27px 33px 0;

    &__price-container {
      margin-bottom: 27px;
    }

    &__price {
      font-size: 28px;
      margin-bottom: 4px;
    }

    &__price-complect {
      font-size: 15px;
    }

    &__availability {
      margin-bottom: 14px;
      font-size: 14px;
    }

    &__button {
      margin-bottom: 24px;
    }

    &__help-button {
      margin: 0 -33px;
    }
  }
}

@include respond('medium') {
  .model-price-box {

  }
}

@include respond-down('small') {
  .model-price-box {

  }
}