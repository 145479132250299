.m-m {
  padding: 100px 0;

  &__tabs {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
  }

  &__head {
    margin-bottom: 20px;
  }

  &__tab {
    padding: 3px 10px;
    color: #8a8a8a;
    background-color: #e0e0e0;
    border-radius: 10px;
    text-transform: uppercase;

    &._selected {
      color: #000;
      background-color: #fff;
    }
  }

  &__marketplace-seller {
    margin-bottom: 50px;
  }

  &__marketplace-seller-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__marketplace-seller-producers {
    padding-left: 20px;
  }

  &__producer {

  }

  &__producer-head {
    padding: 5px 10px;
    display: grid;
    grid-template-columns: 280px 80px 250px 100px;
    grid-column-gap: 10px;
    border-bottom: 1px solid #dadada;
    font-size: 16px;
    font-weight: bold;

    &:hover {
      background-color: #fefefe;
    }
  }

  &__producer-title {
    margin-right: 10px;
  }

  &__producer-percent {
    text-align: right;
  }

  &__producer-models {
    display: none;
    padding-left: 20px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 2px;
      height: calc(100% - 18px);
      background-color: #dadada;
      left: 10px;
      bottom: 18px;
    }
  }


  &__producer._open {
    .m-m__producer-models {
      display: block;
    }
  }

  &__model {
    position: relative;

    &:first-child:before {
      height: 50%;
    }

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 2px;
      background-color: #dadada;
      left: -10px;
      top: 17px;
    }
  }

  &__model-head {
    padding: 5px 10px;
    display: grid;
    grid-template-columns: 260px 80px 250px 100px;
    grid-column-gap: 10px;
    border-bottom: 1px solid #dadada;
    font-size: 16px;

    &:hover {
      background-color: #fefefe;
    }
  }

  &__model-title {

  }

  &__model-title_additional {
    font-size: 11px;
    opacity: 0.4;

    &:hover {
      opacity: 1;
    }
  }


  &__model-percent {
    text-align: right;
  }

  &__model-offers {
    display: none;
    padding-left: 20px;
    position: relative;

    &:before {
      content: '';
      position: absolute;
      width: 2px;
      height: calc(100% - 15px);
      background-color: #dadada;
      left: 10px;
      bottom: 15px;
    }
  }

  &__model._open {
    .m-m__model-offers {
      display: block;
    }
  }

  &__offer {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 2px;
      background-color: #dadada;
      left: -10px;
      top: 15px;
    }
  }

  &__offer-head {
    padding: 5px 10px;
    display: grid;
    grid-template-columns: 240px 80px 250px 100px;

    grid-column-gap: 10px;
    border-bottom: 1px solid #dadada;
    font-size: 13px;
    color: #4f4f4f;

    &:hover {
      background-color: #fefefe;
    }

    &._too-slow {
      background-color: #ffb8b8;
    }

    &._so-hot {
      background-color: #ffe98e;
    }

    &._too-hot {
      background-color: #b8c6ff;
    }
  }

  &__modifier-dot_too-slow {
    background-color: #ffb8b8;
  }

  &__modifier-dot_so-hot {
    background-color: #ffe98e;
  }

  &__modifier-dot_too-hot {
    background-color: #b8c6ff;
  }

  &__modifiers-list {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;

    span {
      width: 15px;
      height: 15px;
      display: block;
      border-radius: 100px;
    }
  }

  &__offer-title {

  }

  &__offer-percent {
    text-align: right;
  }

  &__offer-info {
    display: none;
    padding: 20px 10px 20px 20px;
    font-size: 13px;
  }

  &__offer._open {
    .m-m__offer-info {
      display: block;
    }
  }


  &__markup-form-button-text_success {
    display: none;
  }

  &__markup-form-button-text_error {
    display: none;
  }

  &__markup-form {
    &._success {
      .m-m__markup-form-button-text_default {
        display: none;
      }
      
      .m-m__markup-form-button-text_success {
        display: inline;
      }
    }
  }

  &__markup-form {
    &._error {
      .m-m__markup-form-button-text_default {
        display: none;
      }

      .m-m__markup-form-button-text_error {
        display: inline;
      }
    }
  }

  &__markup-form {
    display: grid;
    grid-template-columns: 1fr 1fr 40px;
    grid-gap: 10px;

    input[type=number], button {
      padding-top: 0;
      padding-bottom: 0;
      height: 25px;
      font-size: 15px;
    }
  }

  &__markup-form-button {
    padding: 0;
    text-align: center;
  }

  &__list {
    &._search {
      .m-m__producer-models {
        display: block;
      }

      .m-m__model-offers {
        display: block;
      }

      .m-m__offer {
        display: none;

        &._matched {
          display: block;
        }
      }

      .m-m__producer {
        display: none;

        &:has(._matched) {
          display: block;
        }
      }

      .m-m__model {
        display: none;

        &:has(._matched) {
          display: block;
        }
      }
    }
  }
}