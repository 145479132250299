.recall-form {

  &__success {
    justify-content: flex-start;
  }

  &__success-wrapper {
    display: flex;
    align-items: center;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
  }

  &__success-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 1.17em;
    color: white;
    margin-bottom: 10px;
  }

  &__success-description {
    font-weight: normal;
    font-size: 15px;
    line-height: 1.33em;
    color: white;
    opacity: 0.5;
    max-width: 244px;
  }

  &__success-svg {
    width: 71px;
    height: 71px;
    border-radius: 100%;
    border: 2px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 29px;

    svg {
      height: 30px;
    }
  }
}

@include respond-up('large') {
  .recall-form {
    &__success {
      padding: 0 64px;
    }
  }
}

@include respond('medium') {
  .recall-form {
    &__success {
      padding: 0 30px;
    }
  }
}

@include respond-down('small') {
  .recall-form {
    &__success {
      text-align: center;
      align-items: flex-start;
      justify-content: center;
      padding: 40px 42px;
    }

    &__success-wrapper {
      flex-direction: column;
    }

    &__success-title {
      font-size: 20px;
    }

    &__success-description {
      font-size: 14px;
    }

    &__success-svg {
      margin-right: 0;
      margin-bottom: 25px;
    }

    &__wrap {
      align-items: center;
    }
  }
}