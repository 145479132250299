.cart-result {
  &__top-container {
    display: flex;
  }

  &__result-wrapper {
    display: flex;
  }

  &__result-container {
    display: flex;
  }

  &__result-text {
    color: var(--text-color-light);
    line-height: 1.33;
  }

  &__result-count {

  }

  &__result-items {

  }

  &__sum-container {
    color: var(--black-color);
    line-height: 1.17;
    font-weight: 500;
  }

  &__sum {

  }

  &__postfix {

  }

  &__delivery {
    display: flex;
    flex-direction: column;
  }

  &__delivery-title {
    color: var(--text-color-light);
    line-height: 1.33;
  }

  &__delivery-text {

  }

  &__buttons-wrapper {
    display: flex;
  }

  &__button {
    padding: 11px 25px;
  }

  &__bonus {

  }

  &__bonus-sum {

  }

  &__bonus-name {

  }

  &__bonus-info {
    display: flex;
    height: 20px;
    width: 20px;
  }

  &__bottom-container {

  }

  &__authorize-container {
    line-height: 1.2;
    background-color: var(--gray-layer);
    color: var(--text-color-light);
  }

  &__authorize {
    color: var(--primary-color);
  }

  &__help {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include respond-up('large') {
  .cart-result {
    padding: 27px 33px 0;

    &__top-container {
      flex-direction: column;
      margin-bottom: 24px;
    }

    &__result-wrapper {
      flex-direction: column;
      margin-bottom: 27px;
    }

    &__result-container {
      flex-direction: column;
      margin-bottom: 24px;
    }

    &__result-text {
      font-size: 15px;
      margin-bottom: 4px;
    }

    &__result-count {

    }

    &__result-items {

    }

    &__sum-container {
      font-size: 28px;
    }

    &__sum {

    }

    &__postfix {

    }

    &__delivery {
      font-size: 15px;
    }

    &__delivery-title {
      margin-bottom: 3px;
    }

    &__delivery-text {

    }

    &__buttons-wrapper {
      flex-direction: column;
      align-items: center;
    }

    &__button {

    }

    &__bonus {
      margin-top: 26px;
    }

    &__bonus-sum {

    }

    &__bonus-name {

    }

    &__bonus-info {
      margin-left: 9px;
    }

    &__bottom-container {

    }

    &__authorize-container {
      padding: 12px 17px;
      font-size: 14px;
    }

    &__authorize {

    }

    &__help {
      font-size: 15px;
      margin: 24px -33px 0;
      padding: 17px 33px;
      border-top: 1px solid var(--border-gray-color);
    }
  }
}

@include respond('medium') {
  .cart-result {
    padding: 30px 30px 0;

    &__top-container {
      margin-bottom: 24px;
      justify-content: space-between;
    }

    &__result-wrapper {
      flex-direction: column;
    }

    &__result-container {
      flex-direction: column;
      margin-bottom: 16px;
    }

    &__result-text {
      font-size: 15px;
      margin-bottom: 4px;
    }

    &__result-count {

    }

    &__result-items {

    }

    &__sum-container {
      font-size: 28px;
    }

    &__sum {

    }

    &__postfix {

    }

    &__delivery {
      font-size: 15px;
    }

    &__delivery-title {
      margin-bottom: 3px;
    }

    &__delivery-text {

    }

    &__buttons-wrapper {
      flex-direction: column;
      align-items: flex-end;
    }

    &__button {
      margin-bottom: 30px;
    }

    &__bonus {

    }

    &__bonus-sum {

    }

    &__bonus-name {

    }

    &__bonus-info {
      margin-left: 9px;
    }

    &__bottom-container {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin: 0 -30px;
      padding: 15px 30px;
      border-top: 1px solid var(--border-gray-color);
    }

    &__authorize-container {
      padding: 12px 17px;
      font-size: 14px;
    }

    &__authorize {
      margin: -10px;
      padding: 10px;
    }

    &__help {
      font-size: 15px;
      padding: 15px;
      margin: -15px;
    }
  }
}

@include respond-down('small') {
  .cart-result {
    padding: 20px 20px 0;

    &__top-container {
      margin-bottom: 24px;
      justify-content: space-between;
      flex-direction: column;
    }

    &__result-wrapper {
      flex-direction: column;
      margin-bottom: 20px;
    }

    &__result-container {
      flex-direction: column;
      margin-bottom: 20px;
    }

    &__result-text {
      font-size: 14px;
      margin-bottom: 5px;
    }

    &__result-count {

    }

    &__result-items {

    }

    &__sum-container {
      font-size: 24px;
    }

    &__sum {

    }

    &__postfix {

    }

    &__delivery {
      font-size: 14px;
    }

    &__delivery-title {
      margin-bottom: 4px;
    }

    &__delivery-text {

    }

    &__buttons-wrapper {
      flex-direction: column;
      align-items: center;
    }

    &__button {
      /*margin-bottom: 20px;*/
    }

    &__bonus {

    }

    &__bonus-sum {

    }

    &__bonus-name {

    }

    &__bonus-info {
      margin-left: 9px;
    }

    &__bottom-container {

    }

    &__authorize-container {
      padding: 12px 17px;
      font-size: 14px;
      display: flex;
      justify-content: center;
    }

    &__authorize {
      margin: -10px;
      padding: 10px;
    }

    &__help {
      border-top: 1px solid var(--border-gray-color);
      font-size: 15px;
      padding: 15px 20px;
      margin: 20px -20px 0;
      min-width: 100%;
    }
  }
}