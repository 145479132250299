.about-page {
}

@include respond-up("large") {
  .about-page {
    padding-bottom: 70px;
  }
}

@include respond-up("medium") {
  .about-page {
  }
}

@include respond("medium") {
  .about-page {
    padding-bottom: 45px;
  }
}

@include respond-down("medium") {
  .about-page {
  }
}

@include respond-down("small") {
  .about-page {
    padding-bottom: 30px;
  }
}
