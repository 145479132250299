.product-techno {
  &__item {
    background-color: white;

    &:not(:first-child) {
      border-top: 1px solid var(--border-gray-color);
    }

    &:first-child {
      border-radius: 3px 3px 0 0;
    }

    &:last-child {
      border-radius: 0 0 3px 3px;
    }
  }

  &__picture {
    height: 100%;
    width: 100%;
  }

  &__img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 3px;
  }
}

@include respond-up('large') {
  .product-techno {
    &__item {
      padding: 40px 50px;
    }

    &__info {
      margin-left: 50px;
    }
  }
}

@include respond-up('medium') {
  .product-techno {
    &__item {
      display: flex;
    }

    &__image-box {
      height: 198px;
      flex: 0 0 211px;
    }

    &__text {
      margin-top: 14px;
    }

    &__link {
      margin-top: 20px;
    }
  }
}

@include respond('medium') {
  .product-techno {
    &__item {
      padding: 30px;
    }

    &__info {
      margin-left: 30px;
    }
  }
}

@include respond-down('small') {
  .product-techno {
    &__item {
      padding: 20px;
    }

    &__image-box {
      display: block;
      width: 120/375*100vw;
      height: 113/375*100vw;
    }

    &__info {
      margin-top: 20px;
    }

    &__text {
      margin-top: 10px;
    }

    &__link {
      margin-top: 12px;
    }
  }
}