:root {
  --sub-header-items-gap: 33px;
}

.sub-header {
  background-color: white;
  //тень не по макету, но не могу, сливается с белым, глаза режет
  box-shadow: 0 1px 2px rgba(231, 231, 231, .5);

  &__logo {
    display: flex;

    svg {
      height: 100%;
      width: 100%;
    }

    &_index {
      pointer-events: none;
    }
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__items {
    display: flex;
    margin: 0 calc(var(--sub-header-items-gap) / -2);
  }

  &__link {
    display: flex;
    padding-right: calc(var(--sub-header-items-gap) / 2);
    padding-left: calc(var(--sub-header-items-gap) / 2);
    font-size: 16px;
    line-height: 1.2em;
    color: var(--black-color);
    transition: color var(--default-transition);

    &:hover {
      color: var(--primary-color)
    }
  }

  &__personal-area {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
}

@include respond-up('large') {
  .sub-header {
    &__button {
      margin: 20px calc(var(--sub-header-items-gap)/2) 20px calc(var(--sub-header-items-gap)/2);
      padding: 10px 18px;
      border-radius: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 120%;
    }

    &__hamburger,
    &__logo {
      display: none;
    }

    &__link {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    &__item {
      position: relative;

      &_parent {
        .sub-header {
          &__link {
            // это квадратики, которые добавляются по краям ссылок,
            // чтобы увеличить область взаимодействия со ссылкой и
            // избежать пропадания дочернего меню при перемещении курсора по диагонали.
            // измените background, чтобы наглядно увидеть как это работает.
            &::before,
            &::after {
              content: '';
              position: absolute;
              bottom: 0;
              display: block;
              height: 20px;
              width: 20px;
              background-color: var(--t);
              pointer-events: none;
              z-index: 10;
            }

            &::before {
              left: 100%;
            }

            &::after {
              right: 100%;
            }

            &:hover {
              &::before,
              &::after {
                pointer-events: auto;
                z-index: 10;
              }
            }
          }

          &__link:hover + ul.sub-header__children,
          &__children:hover {
            opacity: 1;
            visibility: visible;
            pointer-events: auto;
            transform: translateY(0);
          }
        }
      }
    }

    &__children {
      position: absolute;
      top: 100%;
      left: calc(22px - var(--sub-header-items-gap) / 2);
      padding: 10px 0;
      background: white;
      box-shadow: 0 12px 40px rgba(50, 59, 86, 0.15);
      border-radius: 0 0 3px 3px;
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      transform: translateY(20px);
      transition: opacity var(--default-transition), transform var(--default-transition);
      cursor: pointer;
      z-index: calc(var(--header-z-index) + 10);
    }

    &__child-link {
      display: block;
      padding: 8px 22px;
      font-size: 15px;
      line-height: 1.35em;
      white-space: nowrap;
      color: var(--black-color);
      background-color: white;
      transition: color var(--default-transition), background-color var(--default-transition);

      &:hover {
        color: var(--primary-color);
        background-color: #F5F5F6;
      }
    }
  }
}

@include respond('medium') {
  .sub-header {
    &__logo {
      flex: 0 0 80px;
    }

    &__item {
      display: none;

      &_show-tablet {
        display: block;
      }
    }

    &__link {
      padding-top: 20px;
      padding-bottom: 20px;
    }

    &__children {
      display: none;
    }

    &__personal-area {
      padding: 10px 0;
    }
  }
}

@include respond-down('medium') {
  .sub-header {
    &__container {
      display: flex;
      align-items: center;
    }

    &__hamburger {
      display: grid;
      grid-row-gap: 4px;
      grid-template-columns: 1fr;
      width: 24px;
      flex: 0 0 24px;
      margin-right: 20px;
    }

    &__hamburg {
      height: 2px;
      background-color: var(--black-color);
    }

    &__logo {
      display: flex;
      padding: 10px 0;
      margin-right: 40px;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

@include respond-down('small') {
  .sub-header {
    &__logo {
      flex: 0 0 53px;
      width: 53px;
    }

    &__nav {
      display: none;
    }
  }
}