.create-accounting-order {
  max-width: 450px;
  margin: 30px auto;

  &__form {
    margin-top: 30px;
  }

  &__form-line {
    margin-bottom: 20px;
  }
}