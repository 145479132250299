.catalog-category {
  &__list {
    display: grid;
    grid-gap: 1px;
  }

  &__info-title {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 117%;
    color: var(--black-color);
  }

  &__info-description {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: var(--text-color);
    margin-top: 22px;
  }

  &__info {
    background-color: #fff;
  }

  &__info-description {
    &._open {
      .catalog-category {
        &__info-description-content {
          max-height: unset;
        }

        &__info-description-link {
          display: none;
        }
      }
    }
  }

  &__info-description-content {
    max-height: var(--info-description-content);
    overflow: hidden;
    transition: height var(--default-transition);
  }

  &__info-description-link {
    display: flex;
    align-items: baseline;
    margin-top: 22px;
  }

  &__info-description-link-title {
    color: var(--primary-color);
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 133%;
  }

  &__info-description-link-icon {
    display: flex;
    height: max-content;
    margin-left: 6px;

    svg {
      path {
        opacity: 1;
        fill: var(--primary-color);
      }
    }
  }
}

@include respond-up('large') {
  .catalog-category {
    --info-description-content: 192px;
    padding-bottom: 70px;

    &__list {
      grid-template-columns: repeat(5, 1fr);
    }

    &__item {
      min-height: 313px;
    }

    &__info {
      padding: 45px 60px;
      margin-top: 28px;
    }
  }
}

@include respond('medium') {
  .catalog-category {
    --info-description-content: 312px;
    padding-bottom: 45px;

    &__list {
      grid-template-columns: repeat(3, 1fr);
    }

    &__info {
      padding: 30px 40px;
      margin-top: 24px;
    }
  }
}

@include respond-down('small') {
  .catalog-category {
    --info-description-content: 520px;
    padding-bottom: 30px;

    &__list {
      grid-template-columns: repeat(2, 1fr);
    }

    &__info-title {
      font-size: 20px;
    }

    &__info-description {
      font-size: 15px;
      margin-top: 12px;
    }

    &__info {
      padding: 20px;
      margin-top: 14px;
    }

    &__info-description-link {
      margin-top: 12px;
    }
  }
}