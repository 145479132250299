.marketplace-orders-dashboard {
  &__date-input {
    height: var(--default-input-height);
    padding: 0 10px;
  }

  &__more-graphics {
    display: none;

    &._open {
      display: block;
    }
  }

  &__more-graphics-link {
    text-align: center;
    color: #0c4a6e;
    font-size: 12px;
    display: block;
  }

  &__tabs {
    display: flex;
    justify-items: flex-start;
    align-items: center;
    gap: 5px;
  }

  &__tab {
    border-radius: 10px;
    padding: 5px 10px;
    color: #000;
    background-color: #fff;
    opacity: 0.5;
    transition: all 0.3s;
    font-size: 14px;

    &:hover {
      opacity: 1;
    }

    &._active {
      background-color: #000;
      color: #fff;
      opacity: 1;
    }
  }
}