.link-reserved {
  position: relative;
  cursor: default;

  &:hover {
    &::before {
      pointer-events: auto;
    }

    .link-reserved {
      &__items {
        opacity: 1;
        transform: translateY(0);
        pointer-events: auto;
      }
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: 25px;
    right: -14px;
    height: 9px;
    background-color: $t;
    pointer-events: none;
  }


  &__items {
    position: absolute;
    top: calc(100% + 9px);
    left: 25px;
    width: auto;
    padding: 8px 0;
    border-radius: 3px;
    background-color: var(--black-color);
    opacity: 0;
    transform: translateY(10px);
    pointer-events: none;
    transition: opacity var(--default-transition), transform var(--default-transition);
    z-index: 10;

    &::before {
      content: '';
      position: absolute;
      left: 15px;
      bottom: 100%;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 5px 6px;
      border-color: $t $t var(--black-color) $t;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

    &:first-child {
      border-top: 1px solid rgba(255, 255, 255, 0.15);
    }
  }

  &__name,
  &__value {
    line-height: 1.2em;
    color: rgba(255, 255, 255, 0.7);
    white-space: nowrap;
  }

  &__name {
    color: rgba(255, 255, 255, 0.7);
  }

  &__value {
    font-weight: 500;
    color: white;
  }
}

@include respond-up('medium') {
  .link-reserved {
    &__item {
      padding: 6px 15px;
    }

    &__name,
    &__value {
      font-size: 13px;
    }

    &__name {
      min-width: 120px;
      padding-right: 11px;
    }
  }
}

@include respond-down('small') {
  .link-reserved {
    &__name,
    &__value {
      font-size: 11px;
    }

    &__name {
      min-width: 120px;
    }
  }
}

@include respond-down('small') {
  .link-reserved {
    &__items {
      left: -10px;
    }

    &__item {
      padding: 2px 6px;
    }

    &__name {
      padding-right: 5px;
    }
  }
}

@include respond-down('x-small') {
  .link-reserved {
    &__name,
    &__value {
      font-size: 10px;
    }

    &__name {
      min-width: 100px;
    }
  }
}