.steps-base-content {
  background-color: #fff;

  &__title {

  }

  &__inner {
    position: relative;
  }

  &__items {
    display: grid;
  }

  &__item {
    display: block;

    &._active {
      .steps-base-content {
        &__item-link {
          background-color: var(--black-color);
          color: #fff;
        }
      }
    }
  }

  &__item-link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: var(--background-color);
    font-style: normal;
    font-weight: 500;
    transition: background-color var(--default-transition), color var(--default-transition);
    border-radius: 3px;
  }

  &__result {
    border: 1px solid var(--border-gray-color);

    .record-for-service-result__description-container {
      display: none;
    }
  }
}

@include respond-up('large') {
  .steps-base-content {
    padding: 40px 50px 40px;

    &__inner {
      display: flex;
      margin-top: 30px;
    }

    &__items {
      flex: 0 1 100%;
      height: max-content;
    }

    &__item-link {
      &:hover {
        background-color: #E9EAEC;
      }
    }

    &__result {
      flex: 0 0 278px;
      margin-left: 80px;
      height: max-content;
    }
  }
}

@include respond-up('medium') {
  .steps-base-content {
    &__items {
      grid-gap: 8px;
    }

    &__item-link {
      padding: 20px;
      font-size: 17px;
      line-height: 124%;
    }
  }
}

@include respond('medium') {
  .steps-base-content {
    padding: 30px 30px 40px;

    &__inner {
      margin-top: 20px;
    }

    &__items {
      grid-template-columns: repeat(2, 1fr);
    }
  }
}

@include respond-down('medium') {
  .steps-base-content {
    &__result {
      display: none;
    }
  }
}

@include respond('small') {
  .steps-base-content {
    padding: 20px #{$spacer-small}px;
  }
}

@include respond-down('small') {
  .steps-base-content {
    &__inner {
      margin-top: 14px;
    }

    &__items {
      grid-template-columns: 1fr;
      grid-gap: 6px;
    }

    &__item-link {
      padding: 18px;
      font-size: 15px;
      line-height: 133%;
    }
  }
}

@include respond('x-small') {
  .steps-base-content {
    padding: 20px #{$spacer-smaller}px;

  }
}