.partners-block {
  &__companies {
    background: #ffffff;
    border-radius: 3px;
  }

  &__wrapper {
    &:not(:first-child) {
      border-top: 1px solid var(--border-gray-color);
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
  }

  &__logo {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  &__cert {
    height: fit-content;
    flex: 0 0 145px;
    box-shadow: 0 5px 20px rgba(50, 59, 86, 0.12);
  }

  &__pic{
    width: 100%;
    height: 100%;
  }

  &__img {
    width: 100%;
    height: 100%;
  }
}

@include respond-up('large') {
  .partners-block {
    margin-bottom: 70px;

    &__wrapper {
      min-height: 273px;
      padding: 40px 50px 40px 50px;
    }

    &__content {
      margin-right: 80px;
    }

    &__header {
      margin-bottom: 28px;
    }

    &__title {
      margin-bottom: 15px;
    }

    &__img{
      object-fit: contain;
    }

    &__img:nth-of-type(even) {
      object-fit: cover;
    }

    &__logo {
      height: 85px;
      flex: 0 0 163px;
      margin-right: 43px;
    }

    &__cert {
      padding: 8px;
    }
  }
}

@include respond('medium') {
  .partners-block {
    margin-bottom: 45px;

    &__wrapper {
      padding: 30px 56px 30px 30px;
    }

    &__item {
      display: grid;
      grid-template-columns: 105px auto;
      grid-template-rows: 59px auto;
      grid-row-gap: 40px;
    }

    &__logo {
      height: 100%;
      grid-row: 1/1;
      grid-column: 1/1;
    }

    &__cert {
      padding: 4.8px 4.9px;
      width: 89px;
      grid-row: 2/2;
      grid-column: 1/1;
      justify-self: center;
    }

    &__content {
      grid-row: 1/3;
      grid-column: 2/2;
      margin-left: 40px;
    }

    &__header {
      margin-bottom: 24px;
    }

    &__title {
      margin-bottom: 15px;
    }

    &__img:nth-of-type(odd) {
      object-fit: contain;
    }
  }
}

@include respond-down('small') {
  .partners-block {
    margin-bottom: 30px;

    &__header {
      margin-bottom: 14px;
    }

    &__wrapper {
      padding: 20px 19px;
    }

    &__item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    &__title {
      margin-bottom: 12px;
    }

    &__logo {
      flex: 0 0 66px;
      margin-bottom: 20px;
      max-width: 117px;
    }

    &__content {
      flex: 0 0 50%;
      margin-bottom: 20px;
    }

    &__cert {
      flex: 0 0 154px;
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}