.other-product-list {

  &__items-container {
    position: relative;
  }

  &__items {

    /*&::before,
    &::after {
      content: '';
      position: absolute;
      background-color: white;
      pointer-events: none;
    }*/

    &::before {
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
    }

    &::after {
      top: 0;
      right: 0;
      bottom: 0;
      width: 1px;
    }
  }

  &__item {
    background-color: white;
    box-sizing: border-box;
    border-bottom: 1px solid var(--border-gray-color);
    border-right: 1px solid var(--border-gray-color);
  }

  &__arrow {
    position: absolute;
    top: calc(50% - 46px);

    &_left {
      left: -23px;
    }

    &_right {
      right: -23px;
    }
  }
}

@include respond-up('large') {
  .other-product-list {
    margin: 0 -100px;
    padding: 0 100px;

    .tns-ovh {
      overflow: hidden;
      margin: -50px -52px -100px;
      padding: 50px 52px 100px;
    }

    &__items {
      /*grid-template-columns: 1fr 1fr 1fr 1fr;*/
      //grid-template-columns: repeat( auto-fit, minmax(230px, 1fr) );
    }

    &__item {
      opacity: 0;
      transition: var(--default-transition);

      &.tns-slide-active {
        opacity: 1;
      }
    }
  }
}

@include respond('medium') {
  .other-product-list {
    &__items {
      grid-template-columns: 1fr 1fr 1fr;
      //grid-template-columns: repeat( auto-fit, minmax(230px, 1fr) );
    }

    &__item {
      opacity: 0;
      transition: var(--default-transition);

      &.tns-slide-active {
        opacity: 1;
      }
    }
  }
}

@include respond-down('small') {
  .other-product-list {
    &__items-container {
      overflow: hidden;
      margin: 0 -19px;
    }

    &__items {
      display: flex;
      overflow: scroll;
      transform: none;
      padding-left: 19px;
      padding-bottom: 40px;
      margin-bottom: -40px;
    }

    &__item {
      min-width: 169px;
      display: flex;
    }

    &__product-card {
      min-height: 100%;

      .product-card {
        &__wrap {
          min-height: 100%;
          display: inline-flex;
          flex-direction: column;
        }
      }

      .link-stock {
        margin-top: auto;
        width: max-content;
      }
    }

    &__arrow {
      display: none;
    }
  }
}

@include respond-down('x-small') {
  .other-product-list {
    &__items {
      grid-template-columns: repeat( auto-fit, minmax(140px, 1fr) );
    }
  }
}