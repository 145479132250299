.selection-filter {
  &__head {
    display: flex;
  }

  &__clear {
    align-self: center;
  }

  &__accordion {
    background-color: white;
    border-radius: 3px;
  }

  &__info {
    display: flex;
    flex-wrap: wrap;
  }

  &__info-item {
    display: flex;
    align-items: center;
  }

  &__info-key {
    white-space: nowrap;
    font-size: 15px;
    line-height: 1.35em;
    color: var(--text-color-light);
  }

  &__info-value {
    white-space: nowrap;
    font-size: 15px;
    line-height: 1.35em;
  }

  &__help {
    display: flex;
  }

  &__checkboxes-wrapper {
    display: flex;
    align-items: flex-start;
  }

  &__checkbox {
    display: flex;
  }

  &__checkboxes {
    input[type=checkbox] + label {
      align-items: flex-start;
    }

    .checkbox-list {
      display: flex;

      &__icon {
        display: flex;
        align-items: center;
        margin-left: 8px;
      }
    }
  }

  &__form {
    position: relative;
  }

  &__form-main {
    display: grid;
  }

  &__fields {
    grid-area: top-fields;
  }

  &__button-wrapper {
    grid-area: button;
    display: flex;
  }

  &__bottom {
    display: flex;
    //align-items: flex-start;
    grid-area: bottom-fields;
    justify-content: space-between;
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
  }

  &__switcher-wrapper {
    display: flex;
  }
}

@include respond-up('large') {
  .selection-filter {
    &__switcher-wrapper {
      flex-direction: row;
    }

    &__clear-tyre {
      margin-right: -9px;
      margin-top: -12px;
    }

    &__car-pick {
      margin-right: -24px;
    }

    &__clear-mobile {
      display: none;
    }

    &__head {
      padding: 26px 33px 0;
      align-items: center;
      justify-content: space-between;
    }

    &__form-main {
      padding: 25px 33px;
      grid-template-areas:
              "top-fields button"
              "bottom-fields bottom-fields";
    }

    &__fields {
      grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    }

    &__button-wrapper {
      align-items: flex-end;

      .button {
        width: 100%;
      }
    }

    &__bottom {
      display: flex;
    }

    &__form-footer {
      padding: 0 33px 25px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    &__info {
      margin: -10px -20px;
    }

    &__info-item {
      margin: 10px 20px;
    }

    &__info-key {
      margin-right: 7px;
    }
  }
}

@include respond-up('medium') {
  .selection-filter {
    &__switcher {
      &_desktop {
        display: flex;
      }

      &_mobile {
        display: none;
      }
    }

    &__toggle {
      display: none;
    }

    &__form-main {
      grid-gap: 0 22px;
      grid-template-columns: 1fr auto;
    }

    &__fields {
      display: grid;
      grid-gap: 22px;
    }

    &__field {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    &__help {
      margin-left: 30px;

      &_mobile {
        display: none;
      }
    }

    &__bottom {
      padding-top: 24px;
      margin: 0 -15px;
    }

    &__checkboxes {
      display: flex;
      align-items: center;

      .checkbox-list {

        .checkbox-list__item {
          padding: 0 15px;
        }
      }
    }

    &__checkbox {
      padding: 0 15px;
    }
  }
}

@include respond('medium') {
  .selection-filter {
    &__switcher-wrapper {
      margin-top: 30px;
    }

    &__head {
      padding: 23px 30px 0;
    }

    &__form-main {
      padding: 21px 30px 23px;
      grid-template-areas:
              "top-fields top-fields"
              "bottom-fields button";

      &_car {
        grid-template-areas:
                "top-fields top-fields"
                "button button";
      }
    }

    &__fields {
      grid-template-columns: 1fr 1fr;
    }

    &__bottom {
      &._many {
        margin: 0;

        .checkbox-list {
          flex-wrap: wrap;

          .checkbox-list__item {
            flex: 0 0 100%;
            padding: 0;
          }
        }
      }
    }

    &__button-wrapper {
      padding-top: 24px;
    }

    &__button {
      padding: 12px 32px;
    }

    &__form-footer {
      padding: 0 30px 25px;
    }

    &__info {
      margin: -6px -12px;
    }

    &__info-item {
      margin: 6px 12px;
    }
  }
}

@include respond-down('medium') {
  .selection-filter {
    &__head {
      flex-direction: column;
    }

    &__button-wrapper {
      flex-direction: column;
      justify-content: space-between;
    }

    &__clear-desktop {
      display: none;
    }

    &__clear-mobile {
      border: 1px solid var(--primary-color-opacity-light);
      width: 100%;
      margin-top: 10px;
      min-width: 173px;
      padding: 12px 0;
    }

    &__info-key {
      margin-right: 5px;
    }
  }
}

@include respond-down('small') {
  .selection-filter {
    transition: height var(--default-transition);

    &__switcher-wrapper {
      margin-top: 20px;
    }

    &__accordion {
      &.open {
        .selection-filter {
          &__toggle {
            border-bottom-color: var(--border-gray-color);
          }

          &__arrow {
            transform: rotate(-180deg);
          }
        }
      }
    }

    &__toggle {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 13px 19px;
      border-bottom: 1px solid $t;
      transition: border-bottom-color var(--default-transition);
    }

    &__form-main {
      grid-template-areas:
              "top-fields"
              "bottom-fields"
              "button";
    }

    &__bottom {
      flex-wrap: wrap;
      margin: 0 0 20px;


      .checkbox-list {
        flex-wrap: wrap;

        &__item {
          padding: 0 10px;
        }
      }

      &._many {
        .checkbox-list__item {
          flex: 0 0 100%;
          padding: 0;
        }
      }
    }

    &__name {
      display: flex;
      align-items: center;

      svg.icon-steering-wheel {
        height: 24px;
        width: 24px;
        margin-right: 12px;
      }
    }

    &__arrow {
      transform: rotate(0);
      transition: transform var(--default-transition);

      svg.icon-arrow-bottom-gray {
        height: 7px;
        width: 10px;
      }
    }

    &__accordion {
      transition: height var(--default-transition);
      overflow: hidden;
    }

    &__head {
      padding: 20px 19px 0;
    }

    &__form {
      padding: 20px 19px 20px;
    }

    &__fields {
      margin-bottom: 20px;
    }

    &__field {
      &:not(:last-child) {
        margin-bottom: 13px;
      }

      &:last-child {
        margin-top: 20px;
      }
    }

    &__form-footer {
      margin-top: 20px;
    }

    &__info {
      margin: -4px -8px;
    }

    &__info-item {
      margin: 4px 8px;
    }

    &__footer {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 19px 20px;
      flex-direction: column;
    }

    &__help {
      &_desktop {
        display: none;
      }
    }

    &__button {
      width: 100%;
    }
  }
}

@keyframes opacity {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}