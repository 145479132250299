.contacts-index {
  position: relative;

  &__address-wrapper {
    background-color: white;
    position: absolute;
    z-index: 10;
  }

  &__title {

  }

  &__local {
    display: flex;
    align-items: center;
  }

  &__local-icon {
    margin-right: 8px;
    width: 8px;
    height: 15px;
  }

  &__local-name {
    font-size: 14px;
    line-height: 120%;
    color: var(--primary-color);
  }

  &__item-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__list {

  }

  &__item {
    transition: var(--default-transition);
    &:hover {
      background-color: var(--gray-layer-3);
    }

    &._active {
      background-color: var(--gray-layer-3);
    }
  }

  &__address {
    font-weight: 500;
    font-size: 17px;
    line-height: 124%;
  }

  &__number {
    font-size: 14px;
    line-height: 120%;
  }

  &__schedule {
    font-size: 14px;
    line-height: 120%;
    color: var(--text-color-light);
  }

  &__map-container {

  }

  &__map {

  }
}

@include respond-up('large') {
  .contacts-index {
    &__address-wrapper {
      max-width: 380px;
      top: 52px;
      left: calc(((100% - (var(--max-row-width) * 1px)) / 2) + var(--spacer-large));
    }

    &__title {
      padding: 24px 38px 0;
      margin-bottom: 13px;
    }

    &__local {
      padding: 0 38px;
      margin-bottom: 24px;

    }

    &__list {
      display: flex;
      flex-direction: column;
      max-height: 511px;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__item {
      padding: 24px 38px;
      border-top: 1px solid var(--border-gray-color);
    }

    &__address {
      margin-bottom: 7px;
    }

    &__number {
      margin-bottom: 7px;
    }

    &__map {
      min-height: 615px;
    }

    &__main {
      display: none;
    }
  }
}

@include respond('medium') {
  .contacts-index {
    &__address-wrapper {
      top: 32px;
      width: 322px;
      left: var(--spacer-medium);
    }

    &__title {
      padding: 24px 30px 0;
      margin-bottom: 13px;
    }

    &__local {
      padding: 0 30px 0;
      margin-bottom: 24px;
    }

    &__item {
      padding: 24px 30px;
    }

    &__address {
      margin-bottom: 7px;
    }

    &__number {
      margin-bottom: 7px;
    }

    &__schedule {

    }

    &__map-container {

    }

    &__map {
      min-height: 573px;
    }

    &__main {
      display: none;
    }
  }
}

@include respond-down('small') {
  .contacts-index {
    padding: var(--spacer-small);
    &__address-wrapper {
      position: relative;
      display: none;
    }

    &__title {
      padding: 20px 19px 0;
      &_mobile {
        margin-bottom: 14px;
      }
    }

    &__tabs-wrapper {
      margin-bottom: 20px;
      padding: 0 19px;
    }

    &__main {
      background-color: white;
    }

    &__local {
      padding: 0 19px 0;
      margin-bottom: 20px;
    }

    &__local-icon {

    }

    &__local-name {

    }

    &__list {

    }

    &__item {
      padding: 20px 19px;
    }

    &__address {

    }

    &__number {

    }

    &__schedule {

    }

    &__map-container {

    }

    &__map {
      display: none;
    }

    &__map-mobile {
      min-height: 373px;
    }
  }
}