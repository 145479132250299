.vacancy {
  display: flex;
  flex-direction: column;
  row-gap: var(--grid-gap);

  &__header {
    border-bottom: 1px solid var(--border-gray-color);
  }

  &__form-target {
    position: relative;
    top: -130px;
    visibility: hidden;
    pointer-events: none;
  }

  &__labels-wrap {
    display: flex;
    gap: 7px;
    margin-top: 10px;
    flex-wrap: wrap;
  }

  &__label {
    background: var(--background-color);
    border-radius: 3px;
    padding: 4px 10px;
  }

  &__wrap,
  &__form-wrap {
    background: #fff;
    border-radius: 3px;
  }

  &__about {
    margin-top: 0;
    h2 {
      &:first-child {
        margin-top: 0;
      }
    }
  }

  &__policy-wrap {
    display: flex;
    justify-content: center;
  }

  &__policy {
    text-align: center;
  }

  &__policy-link {
    padding: 10px;
    margin: -10px;
  }
}

@include respond-up('large') {
  .vacancy {
    &__header,
    &__form-wrap {
      padding: 30px 50px;
    }

    &__about-wrap {
      padding: 45px 50px 50px;
    }

    &__contact-block {
      display: none;
    }
  }
}

@include respond-up('medium') {
  .vacancy {
    &__header {
      display: grid;
      grid-template-areas: "name salary"
                           "labels labels";
    }

    &__name {
      grid-area: name;
    }

    &__salary {
      grid-area: salary;
      display: flex;
      justify-content: flex-end;
    }

    &__labels-wrap {
      grid-area: labels;
    }
  }
}

@include respond('medium') {
  .vacancy {
    &__header,
    &__form-wrap {
      padding: 30px;
    }

    &__contact-block {
      padding: 30px;
    }

    &__phone-wrap {
      margin-right: 40px;
    }

    &__email-wrap {
      margin-right: 100px;
    }

    &__button {
      width: var(--grid-column2);
    }

    &__about-wrap {
      padding: 45px 30px;
    }
  }
}

@include respond-down('medium') {
  .vacancy {
    &__contact-block {
      display: flex;
      border-bottom: 1px solid var(--border-gray-color);
    }
  }
}

@include respond-down('small') {
  .vacancy {
    &__header,
    &__form-wrap,
    &__contact-block {
      padding: 20px;
    }

    &__contact-block {
      flex-direction: column;
      row-gap: 20px;
    }

    &__button {
      width: 100%;
    }

    &__about-wrap {
      padding: 30px 20px;
    }

    &__policy {
      width: 260px;
    }
  }
}