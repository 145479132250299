.quantity {
  display: inline-flex;
  align-items: center;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 34px;
    flex: 0 0 34px;
    padding: 0;
    border-radius: 50%;
    border: 1px solid var(--button-border-color);
    background-color: $t;
    transition: border-color var(--default-transition);

    &:hover {
      background-color: $t;
      border-color: var(--button-border-color-hover);
    }

    svg {
      &.icon-dec {
        height: 2px;
        width: 8px;
      }

      &.icon-inc {
        height: 8px;
        width: 8px;
      }
    }
  }

  &__value {

  }

  input[type="number"] {
    width: 30px;
    font-size: 14px;
    line-height: 16/14*1em;
    text-align: center;
    border: none;
    padding: 0 3px;
  }

  input[type=number]::-webkit-outer-spin-button,
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    -moz-appearance:textfield;
  }
}