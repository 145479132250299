.vacancy-item {
  background: #fff;
  display: flex;
  flex-direction: column;
  border-radius: 3px;

  &__labels-wrap {
    display: flex;
    gap: 7px;
    margin-top: 10px;
    flex-wrap: wrap;
  }

  &__label {
    background: var(--background-color);
    border-radius: 3px;
    padding: 4px 10px;
  }

  &__bottom-wrap {
    display: flex;
  }

  &__buttons-wrap {
    display: flex;
  }

  &__button {
    height: 44px;
  }
}

@include respond-up('large') {
  .vacancy-item {
    padding: 30px 40px;

    &__bottom-wrap {
      column-gap: 45px;
      justify-content: space-between;
    }
  }
}

@include respond-up('medium') {
  .vacancy-item {
    gap: 30px;

    &__top-wrap {
      display: grid;
      grid-template-areas: "name salary"
                           "labels labels";
    }

    &__name {
      grid-area: name;
    }

    &__salary {
      grid-area: salary;
      display: flex;
      justify-content: flex-end;
    }

    &__labels-wrap {
      grid-area: labels;
    }

    &__buttons-wrap {
      column-gap: 14px;
    }
  }
}

@include respond('medium') {
  .vacancy-item {
    padding: 30px;

    &__bottom-wrap {
      row-gap: 30px;
    }

    &__description {
      width: calc(100% - 90px);
    }

    &__buttons-wrap {
      justify-content: flex-end;
    }
  }
}

@include respond-down('medium') {
  .vacancy-item {
    &__bottom-wrap {
      flex-direction: column;
    }
  }
}

@include respond-down('small') {
  .vacancy-item {
    padding: 20px;
    gap: 20px;

    &__salary {
      margin-top: 6px;
    }

    &__bottom-wrap {
      row-gap: 20px;
    }

    &__buttons-wrap {
      flex-direction: column;
      row-gap: 10px;
    }
  }
}