.pay-and-delivery-content {
  &__address-title {
    font-weight: 500;
    line-height: 124%;
  }

  &__address-point {
    font-weight: 400;
    line-height: 150%;
  }

  &__address-schedule {
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    color: var(--text-color);
    margin-top: 10px;
  }

  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid var(--border-gray-color);
    }
  }

  &__item-wrap {
    display: flex;
    align-items: flex-start;
  }

  &__item-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--gray-layer);
    background-color: var(--gray-layer);
    height: 71px;
    min-width: 71px;
    border-radius: 100px;
  }

  &__item-title {
    font-weight: 500;
    font-size: 19px;
    line-height: 124%;
    margin-bottom: 15px;
  }

  &__item-description {
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: var(--text-color);
  }

  &__list-wrap {
    margin-top: 30px;
  }

  &__item-list {
    margin: -6px 0;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: var(--text-color);
  }

  &__item-point {
    position: relative;
    padding: 6px 0 6px 24px;

    &:before {
      content: '';
      position: absolute;
      top: 14px;
      left: 0;
      width: 6px;
      height: 6px;
      background: red;
    }
  }

  &__images {
    margin-top: 30px;
  }

  &__images-wrap {
    display: flex;
    margin: -15px;

    picture {
      padding: 15px;
    }
  }

  &__button-address {
    margin-top: 20px;
  }

  &__button-address-link {
    border-bottom: 1px solid rgba(225, 39, 39, 0);
    transition: border-bottom-color var(--default-transition);

    &:hover {
      border-bottom-color: rgba(225, 39, 39, 0.3);
    }
  }

  &__button-address-name {
    font-weight: 500;
    font-size: 15px;
    line-height: 133%;
    color: red;
  }

  &__note {
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    border-left: 3px solid red;
    padding-left: 24px;
    margin-top: 30px;
  }

  &__button-more {
    margin-top: 30px;
  }

  &__button-more-link {
    border-bottom: 1px solid rgba(225, 39, 39, 0);
    transition: border-bottom-color var(--default-transition);

    &:hover {
      border-bottom-color: rgba(225, 39, 39, 0.3);
    }
  }

  &__button-more-name {
    font-weight: 500;
    font-size: 15px;
    line-height: 133%;
    color: red;
  }

  &__details{
    background: #F1F2F4;
  }

  &__details-title {
    font: var(--font);
    font-weight: 500;
    line-height:124%;
    color: #1F2129;
  }

  &__details-list{
    display: flex;
  }

  &__details-item{
    font: var(--font);
    font-weight: 400;
    line-height: 140%;
    color: #787B83;
  }
}

@include respond-up('large') {
  .pay-and-delivery-content {
    &__item {
      padding: 45px 50px;
    }

    &__item-wrap {
      margin: 0 -22px;
    }

    &__icon-wrap {
      padding: 0 22px;
    }

    &__item-main {
      padding: 0 22px;
    }

    &__note {
      padding: 0 30px 0 24px;
    }

    &__details-list{
      flex-direction: row;
    }
  }
}

@include respond-up('medium') {
  .pay-and-delivery-content {
    &__address-wrap {
      margin-top: 30px;
    }

    &__address-list {
      margin-top: 15px;
    }

    &__address-title {
      font-size: 17px;
    }

    &__address-point {
      font-size: 16px;
    }
    &__details{
      margin-top: 30px;
      padding: 30px;
    }

    &__details-title {
      font-size: 17px;
    }

    &__details-list{
      gap: 30px;
      margin-top: 15px;
    }

    &__details-item{
      font-size: 14px;
    }
  }
}

@include respond-down('medium') {
  .pay-and-delivery-content {
    &__images-wrap {
      display: flex;
      margin: -8px;

      picture {
        padding: 8px;

        img {
          width: 80px;
          height: 36px;
        }
      }
    }

    &__list-wrap {
      margin-top: 20px;
    }
    &__details-list{
      flex-direction: column;
    }
  }
}

@include respond('medium') {
  .pay-and-delivery-content {
    &__item {
      padding: 30px;
    }

    &__item-wrap {
      margin: 0 -15px;
    }

    &__icon-wrap {
      padding: 0 15px;
    }

    &__item-main {
      padding: 0 15px;
    }
  }
}

@include respond-down('small') {
  .pay-and-delivery-content {
    &__address-wrap {
      margin-top: 20px;
    }

    &__address-list {
      margin-top: 10px;
    }

    &__address-title {
      font-size: 15px;
    }

    &__address-point {
      font-size: 15px;
    }

    &__item {
      padding: 30px 19px;
    }

    &__item-wrap {
      margin: -10px;
      flex-wrap: wrap;
    }

    &__icon-wrap {
      padding: 10px;
    }

    &__item-main {
      padding: 10px;
    }

    &__item-title {
      font-size: 18px;
      margin-bottom: 12px;
    }

    &__item-description {
      font-size: 15px;
    }

    &__item-list {
      font-size: 15px;
      margin: -4px 0;
    }

    &__images-wrap {
      flex-wrap: wrap;
    }

    &__item-point {
      padding: 4px 0 4px 24px;
    }

    &__button-more {
      margin-top: 20px;
    }

    &__note {
      font-size: 15px;
      padding-left: 21px;
    }

    &__details{
      margin-top: 25px;
      padding: 20px;
    }

    &__details-title {
      font-size: 15px;
    }

    &__details-list{
      gap:20px;
      margin-top: 10px;
    }

    &__details-item{
      font-size: 13px;
    }
  }
}