.texts {
  &_main {
    //styleName: Главный текст для портянок;
    font-family: var(--font);
    font-weight: 400;
    line-height: 150%;
  }

  &_seventeen {
    //styleName: 17;
    font-family: var(--font);
    font-size: 17px;
    font-weight: 400;
    line-height: 124%;
  }

  &_seventeen-medium {
    //styleName: 17 медиум;
    font-family: var(--font);
    font-size: 17px;
    font-weight: 500;
    line-height: 124%;
  }
}

@include respond-up('large') {
  .texts {

  }
}

@include respond-up('medium') {
  .texts {
    &_main {
      font-size: 16px;
    }
  }
}

@include respond('medium') {
  .texts {

  }
}

@include respond-down('medium') {
  .texts {

  }
}

@include respond-down('small') {
  .texts {
    &_main {
      font-size: 15px;
    }
  }
}