.cart-payment {
  &__title {
    display: flex;
    align-items: center;
    font-weight: 500;
    line-height: 1.17;

    &::before {
      content: '3';
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--button-border-color);
      border-radius: 100px;
      font-weight: 700;
      color: var(--primary-color);
      line-height: 1.17;
      font-size: 23px;
      height: 46px;
      min-width: 46px;
    }
  }

  &__radio-list {

  }

  &__radio-item {
    //&:last-child {
    //  .cart-payment {
    //    &__label {
    //      border-bottom: 1px solid var(--border-gray-color);
    //    }
    //  }
    //}
  }

  &__radio {

  }

  &__input {
    display: none;

    &:checked+label {
      background-color: var(--gray-layer-3);

      &::after {
        background-color: var(--black-color);
      }
    }
  }

  &__label {
    position: relative;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    border-top: 1px solid var(--border-gray-color);
    transition: var(--default-transition);
    margin: 0;

    &::before, &::after {
      position: absolute;
    }

    &::before {
      content: '';
      min-height: 19px;
      min-width: 19px;
      border-radius: 100px;
      border: 1px solid var(--button-border-color);
      box-sizing: border-box;
    }

    &::after {
      content: '';
      background-color: var(--t);
      transition: var(--default-transition);
      border-radius: 100px;
      min-height: 9px;
      min-width: 9px;
    }
  }

  &__label-title {
    color: var(--black-color);
    line-height: 1.33;
  }

  &__label-description {
    line-height: 1.2;
  }

  &__bonus-container {

  }

  &__bonus-title {
    font-weight: 500;
    line-height: 1.24;
  }

  &__bonus {
    background-color: var(--gray-layer);
    border-radius: 3px;
    display: grid;
  }

  &__bonus-count-container {
    grid-area: bonus;
  }

  &__bonus-count-wrapper {
    background-color: #fff;
    border-radius: 3px;
    width: max-content;
    display: flex;
  }

  &__bonus-icon {
    padding: 12px 14px;
    border-right: 1px solid var(--border-gray-color);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 14px;
      width: 14px;
    }
  }

  &__bonus-text-container {
    display: flex;
    align-items: center;
  }

  &__bonus-text {
    line-height: 1.2;
  }

  &__bonus-count {
    font-weight: 500;
    line-height: 1.2;
  }

  &__bonus-question {
    grid-area: question;
    color: var(--black-color);
    line-height: 1.33;
  }

  &__bonus-available {
    grid-area: available;
    color: var(--text-color-light-2);
    line-height: 1.2;
    display: flex;
    align-items: center;
  }

  &__available-text {
    margin-right: 3px;
  }

  &__available-count {
    margin-right: 6px;
  }

  &__available-question {
    display: block;
    max-height: 20px;
    max-width: 20px;
  }

  &__bonus-input-container {
    grid-area: input;
    position: relative;
  }

  &__bonus-input {
    max-width: 133px;
  }

  &__bonus-button {
    position: absolute;
    right: 6px;
    top: 5px;
    bottom: 5px;
    width: 40px;
    padding: 0;
    min-height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@include respond-up('large') {
  .cart-payment {
    &__title {
      font-size: 24px;
      padding: 30px 50px;

      &::before {
        margin-right: 20px;
      }
    }

    &__radio-list {

    }

    &__radio-item {

    }

    &__radio {

    }

    &__input {

    }

    &__label {
      padding: 17px 77px;

      &::before {
        left: 50px;
        top: 18px;
      }

      &::after {
        left: 55px;
        top: 23px;
      }
    }

    &__label-title {
      font-size: 15px;
      margin-bottom: 3px;
    }

    &__label-description {
      font-size: 14px;
    }

    &__bonus-container {
      padding: 40px 50px;
    }

    &__bonus-title {
      font-size: 19px;
      margin-bottom: 24px;
    }

    &__bonus {
      padding: 23px 33px;
      grid-template-columns: auto max-content max-content;
      grid-template-rows: repeat(2, max-content max-content max-content);
      align-items: center;
      grid-template-areas:
              "bonus question input"
              "bonus available input"
    }

    &__bonus-count-container {

    }

    &__bonus-count-wrapper {

    }

    &__bonus-icon {

    }

    &__bonus-text-container {
      padding: 10px 18px;
    }

    &__bonus-text {
      font-size: 16px;
      margin-right: 3px;
    }

    &__bonus-count {
      font-size: 20px;
    }

    &__bonus-question {
      align-self: flex-end;
      font-size: 15px;
    }

    &__bonus-available {
      align-self: flex-start;
      font-size: 14px;
    }

    &__available-text {

    }

    &__available-count {

    }

    &__available-question {

    }

    &__bonus-input-container {
      margin-left: 28px;
    }

    &__bonus-input {
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }

    &__bonus-button {

    }
  }
}

@include respond('medium') {
  .cart-payment {
    &__title {
      font-size: 24px;
      padding: 20px 30px;

      &::before {
        margin-right: 20px;
      }
    }

    &__radio-list {

    }

    &__radio-item {

    }

    &__radio {

    }

    &__input {

    }

    &__label {
      padding: 17px 57px;

      &::before {
        left: 30px;
        top: 18px;
      }

      &::after {
        left: 35px;
        top: 23px;
      }
    }

    &__label-title {
      font-size: 15px;
      margin-bottom: 3px;
    }

    &__label-description {
      font-size: 14px;
    }

    &__bonus-container {
      padding: 20px 30px;
    }

    &__bonus-title {
      font-size: 19px;
      margin-bottom: 24px;
    }

    &__bonus {
      padding: 23px 33px;
      width: 100%;
      grid-template-rows: repeat(2, max-content max-content);
      align-items: center;
      grid-template-areas:
              "bonus question"
              "available input"
    }

    &__bonus-count-container {

    }

    &__bonus-count-wrapper {

    }

    &__bonus-icon {

    }

    &__bonus-text-container {
      padding: 10px 18px;
    }

    &__bonus-text {
      font-size: 16px;
      margin-right: 3px;
    }

    &__bonus-count {
      font-size: 20px;
    }

    &__bonus-question {
      align-self: flex-start;
      justify-self: flex-end;
      font-size: 15px;
      margin-bottom: 0;
    }

    &__bonus-available {
      align-self: flex-start;
      justify-self: flex-start;
      font-size: 14px;
      margin-top: 18px;
    }

    &__available-text {

    }

    &__available-count {

    }

    &__available-question {

    }

    &__bonus-input-container {
      margin-left: 28px;
      justify-self: flex-end;
      margin-top: -15px;
    }

    &__bonus-input {

    }

    &__bonus-button {

    }
  }
}

@include respond-down('small') {
  .cart-payment {
    &__title {
      font-size: 20px;
      padding: 14px 19px;

      &::before {
        margin-right: 14px;
      }
    }

    &__radio-list {

    }

    &__radio-item {

    }

    &__radio {

    }

    &__input {

    }

    &__label {
      padding: 15px 46px;

      &::before {
        left: 19px;
        top: 14px;
      }

      &::after {
        left: 24px;
        top: 19px;
      }
    }

    &__label-title {
      font-size: 14px;
      margin-bottom: 3px;
    }

    &__label-description {
      font-size: 13px;
    }

    &__bonus-container {
      padding: 20px 19px;
    }

    &__bonus-title {
      font-size: 18px;
      margin-bottom: 14px;
    }

    &__bonus {
      padding: 14px 32px 14px 19px;
      width: 100%;
      grid-template-rows: repeat(2, max-content max-content);
      align-items: center;
      grid-template-areas:
              "bonus"
              "question"
              "available"
              "input"
    }

    &__bonus-count-container {
      margin-bottom: 20px;
    }

    &__bonus-count-wrapper {

    }

    &__bonus-icon {

    }

    &__bonus-text-container {
      padding: 10px 18px;
    }

    &__bonus-text {
      font-size: 14px;
      margin-right: 3px;
    }

    &__bonus-count {
      font-size: 18px;
    }

    &__bonus-question {
      align-self: flex-start;
      justify-self: flex-start;
      font-size: 14px;
      margin-bottom: 3px;
    }

    &__bonus-available {
      align-self: flex-start;
      justify-self: flex-start;
      font-size: 13px;
      margin-bottom: 14px;
    }

    &__available-text {

    }

    &__available-count {

    }

    &__available-question {

    }

    &__bonus-input-container {
      justify-self: flex-start;
    }

    &__bonus-input {

    }

    &__bonus-button {

    }
  }
}