.record-for-service-result {
  &__top-container {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    font-weight: normal;
    font-size: 15px;
    line-height: 1.35em;
  }

  &__item-title {
    color: var(--text-color-light);
  }

  &__description-container {
    font-weight: normal;
    font-size: 13px;
    line-height: 1.2em;
    background-color: var(--gray-layer);
    color: var(--text-color-light);
  }

  &__help {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid var(--border-gray-color);

    span {
      line-height: 100%;
    }
  }
}

@include respond-up('large') {
  .record-for-service-result {
    &__top-container {
      padding: 27px 33px;
    }

    &__item {
      flex-direction: column;

      &:not(:first-child) {
        margin-top: 20px;
      }
    }

    &__item-title {
      margin-bottom: 3px;
    }

    &__description-container {
      padding: 12px 17px;
    }

    &__help {
      border-top: 1px solid var(--border-gray-color);
      padding: 16px 33px;
    }
  }
}

@include respond('medium') {
  .record-for-service-result {
    &__top-container {
      padding: 20px 30px;
    }

    &__items {
      margin-bottom: 20px;
    }

    &__item {
      flex-direction: row;
      padding: 7px 0 8px;
      border-bottom: 1px solid var(--border-gray-color);

      &:nth-child(3) {
        border: none;
      }
    }

    &__item-title {
      margin-bottom: 3px;
      flex: 0 0 53%;
    }

    &__description-container {
      padding: 13px 21px;
    }

    &__help {
      border-top: 1px solid var(--border-gray-color);
      width: 100%;
      padding: 16px 0;
      display: flex;
      justify-content: center;
    }
  }
}

@include respond-down('small') {
  .record-for-service-result {
    &__top-container {
      padding: 20px 19px;
    }

    &__items {
      margin-bottom: 20px;
    }

    &__item {
      flex-direction: column;
      padding: 7px 0 8px;
    }

    &__item-title {
      margin-bottom: 3px;
      flex: 0 0 53%;
    }

    &__description-container {
      padding: 13px 21px;
    }

    &__help {
      border-top: 1px solid var(--border-gray-color);
      width: 100%;
      padding: 16px 0 16px;
      display: flex;
      justify-content: center;
    }
  }
}
