.info-block {
  &__gallery {
    &-image-wrapper {
      display: flex;
    }
  }

  &__body {
    margin-bottom: 90px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }

  &__gallery {
    position: relative;
    top: 0;
    left: 0;
    flex: 0 0 calc((584 / 1196) * 100%);
    border-radius: 3px;
  }

  &__pic {
    width: 100%;
    height: 100%;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__wrapper {
    height: 100%;
    flex: 0 0 calc((612 / 1196) * 100%);
  }

  &__title {
    padding-left: 21%;
    margin-top: 34px;
    margin-bottom: 20px;
    width: 100%;
  }

  &__text {
    padding-left: 21%;
    width: 100%;
  }

  &__arrow {
    position: absolute;
    top: 50%;

    &_left {
      left: 0;
      transform: translate3d(-50%, -50%, 0);
    }

    &_right {
      right: 0;
      transform: translate3d(50%, -50%, 0);
    }
  }
}

@include respond('medium') {
  .info-block {
    &__body {
      margin-bottom: 45px;
    }

    &__wrapper {
      order: -1;
    }

    &__title {
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 15px;
    }

    &__text {
      padding-left: 0;
      width: 100%;
      margin-bottom: 15px;
    }

    &__gallery {
      margin-top: 30px;
    }
  }
}

@include respond-down('medium') {
  .info-block {
    &__body {
      flex-direction: column;
    }

    &__title {
      padding-left: 0;
    }

    &__text {
      padding-left: 0;
    }
  }
}

@include respond-down('small') {
  .info-block {
    &__body {
      margin-bottom: 30px;
    }

    &__wrapper {
      order: -1;
    }

    &__title {
      margin-top: 0;
      margin-bottom: 12px;
    }

    &__text {
      margin-bottom: 30px;
    }

    &__gallery {
      margin-top: 0;
    }

    &__gallery {
      margin: 0 19px;
    }
  }
}