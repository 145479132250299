.product-review {
  position: relative;
  &__head {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &:after {
      content: '';
      position: absolute;
      box-shadow: 0 1px 0 var(--border-gray-color);
      left: -30px;
      top: 97px;
      height: 1px;
    }
  }

  &__picture {
    width: 79px;
    height: 73px;
  }

  &__product-title {
    font-size: 15px;
    line-height: 133%;
  }

  &__title, &__subtitle {
    text-align: center;
  }

  &__rating-wrapper {
    padding: 16px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--gray-layer);
  }

  &__rating-title {
    margin-right: 23px;
    margin-bottom: 0;
  }

  &__form-text-area {
    textarea {
      resize: vertical;
    }
  }
}

@include respond-up('medium') {
  .product-review {
    padding: 97px 30px 20px;

    &__head {
      top: -30px;
      &:after {
        width: 600px;
      }
    }

    &__product-image {
      padding: 12px 0;
      margin-right: 20px;
    }

    &__title {
      margin-bottom: 12px;
    }

    &__subtitle {
      max-width: 480px;
      margin: 0 auto 33px;
    }

    &__rating-wrapper {
      margin-bottom: 33px;
    }

    &__form-wrapper {
      display: flex;
      margin: 0 -11px 22px;
    }

    &__field {
      width: 100%;
      margin: 0 11px;
    }

    &__form-text-area {
      margin-bottom: 22px;
    }

    &__form-button-container {
      display: flex;
      align-items: center;
    }

    &__form-button {
      margin-right: 33px;
    }

    &__policy {
      max-width: 252px;
      display: inline-block;
    }
  }
}

@include respond-down('small') {
  .product-review {
    padding: 137px 0 0;
    &__head {
      &:after {
        min-width: 375px;
      }
    }

    &__title {
      margin-bottom: 9px;
    }

    &__subtitle {
      margin-bottom: 20px;
    }

    &__rating-wrapper {
      margin-bottom: 18px;
    }

    &__field {
      margin-bottom: 15px;
    }

    &__form-text-area {
      margin-bottom: 20px;
    }

    &__form-button-container {
      display: flex;
      flex-direction: column;
    }

    &__form-button {
      margin-bottom: 14px;
      button {
        width: 100%;
      }
    }

    &__policy {
      text-align: center;
      max-width: 252px;
      margin: 0 auto;
    }
  }
}