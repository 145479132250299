.cart-product {
  display: flex;

  button {
    background-color: var(--t);
    padding: 0;

    &:hover {
      background-color: var(--t);
    }
  }

  &__image-container {

  }

  &__image {

  }

  &__container {
    flex: 1 1 auto;
  }

  &__info {
    display: flex;
    justify-content: space-between;
  }

  &__name-container {

  }

  &__name {
    line-height: 1.33;
  }

  &__description {
    line-height: 1.2;
    color: var(--text-color-light-2);
  }

  &__price-container {
    display: flex;
    align-items: flex-end;
  }

  &__price-current {
    line-height: 1.2;

    .cart-product {
      &__price {
        font-weight: 500;
        line-height: 1.17;
        font-size: 18px;
      }

      &__postfix {
        font-weight: 500;
        line-height: 1.17;
        font-size: 18px;
      }
    }
  }

  &__price {

  }

  &__postfix {

  }

  &__count {

  }

  &__price-old {
    color: var(--text-color-light);
    font-size: 13px;
    position: relative;
    display: flex;
    align-items: center;

    &::before {
      content: '';
      display: block;
      position: absolute;
      right: 0;
      left: 0;
      height: 1px;
      background-color: var(--text-color-light);
    }
  }

  &__availability-container {
    color: var(--success-color);
    display: inline-block;
    line-height: 1.2;
    font-size: 13px;
  }

  &__availability-name {

  }

  &__availability-sign {

  }

  &__availability-count {

  }

  &__availability-postfix {

  }

  &__interactive-container {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__interactive {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__interaction {
    display: flex;
    align-items: center;

    &_large {

    }

    &_small {
      display: none;
    }
  }

  &__count-container {
    display: flex;
    align-items: center;

    input[type="number"] {
      width: 30px;
      border: none;
      padding: 0 3px;
      height: 16px;
      text-align: center;
      font-size: 14px;

      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }
    }
  }

  &__interaction-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    min-width: 34px;
    border-radius: 100px;
    border: 1px solid var(--button-border-color);
    color: var(--black-color);

    &_minus {

    }

    &_plus {

    }
  }

  &__interaction-count {

  }

  &__sum-container {
    font-weight: 500;
    line-height: 1.17;
  }

  &__sum {

  }

  &__sum-postfix {

  }

  &__button-list {
    display: flex;
    align-items: center;
  }

  &__button-item {
    height: 22px;
  }

  &__button-icon {
    display: flex;
    min-height: 22px;
    min-width: 22px;
    padding: 5px;
    margin: -5px;
    color: var(--black-color);
  }

  &__bonus-container {
    display: flex;
    justify-content: center;
  }

  &__bonus {

  }

  &__actions {

  }

  &__action-container {

  }

  &__action {
    background-color: var(--gray-layer);
    border-radius: 3px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__action-name-container {
    display: flex;
    align-items: center;
  }

  &__action-icon {
    max-height: 28px;
  }

  &__action-name {
    line-height: 1.2;
  }

  &__condition {
    background-color: #fff;
    line-height: 1.2;
    border-radius: 3px;
    color: var(--primary-color);
  }
}

@include respond-up('large') {
  .cart-product {
    padding: 22px 50px 31px;

    &__image-container {
      margin-right: 20px;
    }

    &__image {
      width: 121px;
    }

    &__container {

    }

    &__info {
      margin-bottom: 27px;
    }

    &__name-container {
      flex: 0 0 292/648*100%;
      margin-right: 37px;
    }

    &__name {
      font-size: 15px;
      margin-bottom: 6px;
    }

    &__description {
      font-size: 14px;
      margin-bottom: 11px;
    }

    &__price-container {
      font-size: 14px;
      margin-bottom: 19px;
    }

    &__price-current {
      .cart-product {
        &__price {
          font-size: 18px;
        }

        &__postfix {
          font-size: 18px;
        }
      }
    }

    &__price {

    }

    &__postfix {

    }

    &__count {

    }

    &__price-old {
      margin-left: 9px;
    }

    &__availability-container {
      color: var(--success-color);
      display: inline-block;
      line-height: 1.2;
    }

    &__availability-name {

    }

    &__availability-sign {

    }

    &__availability-count {

    }

    &__availability-postfix {

    }

    &__interactive-container {

    }

    &__interactive {

    }

    &__interaction {

      &_large {

      }

      &_small {

      }
    }

    &__count-container {
      margin-right: 20px;
    }

    &__interaction-button {
      &_minus {

      }

      &_plus {

      }
    }

    &__interaction-count {

    }

    &__sum-container {
      font-size: 20px;
    }

    &__sum {

    }

    &__sum-postfix {

    }

    &__button-list {
      margin: 0 -10px;
    }

    &__button-item {
      padding: 0 10px;
    }

    &__button-icon {

    }

    &__bonus-container {

    }

    &__bonus {

    }

    &__actions {

    }


    &__action-container {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__action {
      padding: 11px 114px 11px 15px;
    }

    &__action-name-container {

    }

    &__action-icon {
      margin-right: 14px;
      width: 28px;
    }

    &__action-name {
      font-size: 14px;
    }

    &__condition {
      font-size: 14px;
      padding: 1px 6px;
    }
  }
}

@include respond('medium') {
  .cart-product {
    padding: 28px 24px;

    &__image-container {
      margin-right: 24px;
    }

    &__image {
      width: 109px;
    }

    &__container {

    }

    &__info {
      margin-bottom: 24px;
    }

    &__name-container {
      flex: 0 0 174/509*100%;
      margin-right: 32px;
    }

    &__name {
      font-size: 15px;
      margin-bottom: 6px;
    }

    &__description {
      font-size: 14px;
      margin-bottom: 11px;
    }

    &__price-container {
      font-size: 14px;
      margin-bottom: 15px;
    }

    &__price-current {
      .cart-product {
        &__price {
          font-size: 18px;
        }

        &__postfix {
          font-size: 18px;
        }
      }
    }

    &__price {

    }

    &__postfix {

    }

    &__count {

    }

    &__price-old {
      margin-left: 9px;
    }

    &__availability-container {

    }

    &__availability-name {

    }

    &__availability-sign {

    }

    &__availability-count {

    }

    &__availability-postfix {

    }

    &__interactive-container {

    }

    &__interactive {

    }

    &__interaction {

      &_large {

      }

      &_small {

      }
    }

    &__count-container {
      margin-right: 20px;
    }

    &__interaction-button {
      &_minus {

      }

      &_plus {

      }
    }

    &__interaction-count {

    }

    &__sum-container {
      font-size: 20px;
    }

    &__sum {

    }

    &__sum-postfix {

    }

    &__button-list {
      margin: 0 -10px;
    }

    &__button-item {
      padding: 0 10px;
    }

    &__button-icon {

    }

    &__bonus-container {
      margin-bottom: 25px;
    }

    &__bonus {

    }

    &__actions {

    }

    &__action-container {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__action {
      padding: 11px 114px 11px 15px;
    }

    &__action-name-container {

    }

    &__action-icon {
      margin-right: 14px;
      width: 28px;
    }

    &__action-name {
      font-size: 14px;
    }

    &__condition {
      font-size: 14px;
      padding: 1px 6px;
    }
  }
}

@include respond-down('small') {
  .cart-product {
    padding: 20px;

    &__image-container {
      margin-right: 10px;
    }

    &__image {
      width: 74px;
    }

    &__container {
      flex: 1 1 auto;
      width: 214/298*100%;
    }

    &__info {
      margin-bottom: 24px;
      justify-content: space-between;
    }

    &__name-container {
      flex: 0 0 148/214*100%;
      margin-right: 12px;
    }

    &__name {
      font-size: 14px;
      margin-bottom: 6px;
    }

    &__description {
      font-size: 13px;
      margin-bottom: 5px;
    }

    &__price-container {
      font-size: 13px;
      margin-bottom: 13px;
    }

    &__price-current {
      .cart-product {
        &__price {
          font-size: 16px;
        }

        &__postfix {
          font-size: 16px;
        }
      }
    }

    &__price {

    }

    &__postfix {

    }

    &__count {

    }

    &__price-old {
      margin-left: 9px;
    }

    &__availability-container {

    }

    &__availability-name {

    }

    &__availability-sign {

    }

    &__availability-count {

    }

    &__availability-postfix {

    }

    &__interactive-container {
      align-items: flex-end;
    }

    &__interactive {

    }

    &__interaction {

      &_large {
        display: none;
      }

      &_small {
        display: flex;
        margin-bottom: 14px;
        border-top: 1px solid var(--border-gray-color);
        padding-top: 14px;
      }
    }

    &__count-container {
      margin-right: 20px;
    }

    &__interaction-button {
      &_minus {

      }

      &_plus {

      }
    }

    &__interaction-count {

    }

    &__sum-container {
      font-size: 20px;
    }

    &__sum {

    }

    &__sum-postfix {

    }

    &__button-list {
      margin: 0 -8px;
    }

    &__button-item {
      padding: 0 8px;
    }

    &__button-icon {

    }

    &__bonus-container {
      margin-bottom: 5px;
    }

    &__bonus {

    }

    &__actions {

    }

    &__action-container {
      margin-bottom: 8px;
      margin-left: -84px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__action {
      padding: 6px;
    }

    &__action-name-container {

    }

    &__action-icon {
      margin-right: 14px;
      width: 28px;
    }

    &__action-name {
      font-size: 14px;
    }

    &__condition {
      display: none;
    }
  }
}