.marketplace-manager {
  max-width: 1600px;
  margin: 0 auto;
  padding: 30px 0;

  &__filter-form {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 20px;
    margin-bottom: 20px;
  }

  &__search-form {
    margin-bottom: 20px;
  }

  &__offers {
    display: flex;
    gap: 30px;
  }

  &__offers-column {
    flex: 50% auto;
    width: 50%;
  }

  &__latest-orders {
    margin-bottom: 40px;
    font-size: 14px;

    table {
      width: 100%;
    }

    tr:nth-child(even) {
      td {
        background-color: #e8e8e8;
      }
    }

    tr {
      &._hide {
        display: none;
      }

      &._unclosed {
        td {
          background-color: #ffc3c3;
        }
      }

      &._express {
        td {
          background-color: #fff7c3;
        }
      }
    }

    th {
      font-weight: bold;
      text-align: left;
    }

    td, th {
      font-size: 14px;
      padding: 7px 10px;
      transition: all 0.3s;
    }

    tr:hover {
      td {
        background-color: #d9d8d8;
      }
    }

    a {
      text-decoration: underline;
      color: blue;
    }
  }

  &__latest-orders-realization {
    min-width: 160px;
  }

  &__latest-orders-date {
    width: 137px;
  }

  &__latest-orders-status-common {
    width: 40px;
  }

  &__latest-order-ship-date {
    width: 60px;
  }

  &__latest-orders-printed {
    width: 50px;
  }

  &__latest-orders-status {
    min-width: 150px;
  }

  &__latest-orders-actions {
    a {
      margin-right: 3px;
    }
  }

  &__marks {
    position: relative;
  }

  &__empty-orders {
    display: none;
    margin-bottom: 30px;

    &._opened {
      display: block;
    }
  }

  &__empty-orders-link {
    display: block;
    color: #0c4a6e;
    margin-bottom: 10px;
  }

  &__empty-order-link {
    color: #0c4a6e;
    text-decoration: underline;
  }

  &__empty-order-button-wrapper {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__empty-order {
    display: grid;
    grid-template-columns: 40px 200px 100px 100px 1fr 100px 200px;
    padding: 4px 10px;

    &:nth-child(even) {
      background-color: #e8e8e8;
    }

    &:has(input:checked) {
      background-color: #ecabab;
    }

    &._pending {
      background-color: #dab062;
    }
  }
}