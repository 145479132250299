.price-competitor-history-prices {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color-opacity-30);


  &__inner {
    background-color: var(--background-color);
    width: 80vw;
    max-width: 1000px;
    height: max-content;
    box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.1);
  }

  &__chart {
    position: relative;
    width: 80vw;
    max-width: 1000px;
  }

  &__heading {
    display: flex;
    justify-content: space-between;
    padding: 10px;

  }

  &__content {
    min-height: calc((min(80vw, 1000px) / 2));
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__loader {
    display: inline-block;
    position: absolute;
    z-index: 150;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 80px;
    height: 80px;

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid var(--border-gray-color);
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: var(--border-gray-color) transparent transparent transparent;

      &:nth-child(1) {
        animation-delay: -0.45s;
      }
      &:nth-child(2) {
        animation-delay: -0.3s;
      }
      &:nth-child(3) {
        animation-delay: -0.15s;
      }
    }
  }
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}