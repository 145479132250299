.cart {
  --border: 1px solid #3636ff;
  --bg-section: #fff;
  --border-radius-section: 3px;

  &__container {
    display: flex;
    justify-content: space-between;
  }

  &__wrapper {
    display: grid;
    width: 100%;

    grid-template-columns: 100%;
    grid-template-rows: repeat(8, max-content);
    grid-template-areas:
            "come-back"
            "products"
            "result"
            "contacts-info"
            "receiving"
            "payment"
            "order-comment"
            "confirm"
  }

  &__come-back {
    grid-area: come-back;
    margin-bottom: var(--margin-section);
    background-color: var(--bg-section);
    border-radius: var(--border-radius-section);

    &:empty {
      margin-bottom: 0;
    }
  }

  &__products {
    grid-area: products;
    background-color: var(--bg-section);
    margin-bottom: var(--margin-section);
    border-radius: var(--border-radius-section);

    &:empty {
      margin-bottom: 0;
    }
  }

  &__products-list {
    display: flex;
    flex-direction: column;
  }

  &__products-item {
    border-top: 1px solid var(--border-gray-color);

    &:first-child {
      border-top: none;
    }
  }

  &__result {
    grid-area: result;
    background-color: var(--bg-section);
    border-radius: var(--border-radius-section);
  }

  &__contacts-info {
    grid-area: contacts-info;
    margin-bottom: var(--margin-section);
    background-color: var(--bg-section);
    border-radius: var(--border-radius-section);

    &:empty {
      margin-bottom: 0;
    }
  }

  &__receiving {
    grid-area: receiving;
    margin-bottom: var(--margin-section);
    background-color: var(--bg-section);
    border-radius: var(--border-radius-section);

    &:empty {
      margin-bottom: 0;
    }
  }

  &__payment {
    grid-area: payment;
    margin-bottom: var(--margin-section);
    background-color: var(--bg-section);
    border-radius: var(--border-radius-section);

    &:empty {
      margin-bottom: 0;
    }
  }

  &__order-comment {
    grid-area: order-comment;
    background-color: var(--bg-section);
    border-radius: var(--border-radius-section);

    &:empty {
      margin-bottom: 0;
    }
  }

  &__confirm {
    grid-area: confirm;
  }

  &__result-mini {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: var(--bg-section);
    transition: var(--default-transition);

    &._active {
      transform: translate3d(0, 0, 0);
    }
  }

  &__empty {
    min-height: 40vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__empty-text {
    font-size: 30px;
    margin-bottom: 40px;
  }

  &__empty-button {

  }
}

@include respond-up('large') {
  .cart {
    --margin-section: 28px;
    padding-bottom: 69px;

    &__container {

    }

    &__wrapper {
      grid-template-columns: auto (306/1196)*100%;
      grid-template-rows: repeat(7, max-content max-content);
      grid-template-areas:
              "come-back result"
              "products result"
              "contacts-info result"
              "receiving result"
              "payment result"
              "order-comment result"
              "confirm result"
    }

    &__come-back {

    }

    &__products {

    }

    &__result-mini {

    }

    &__result {
      position: sticky;
      top: 107px;
      height: max-content;
      margin-left: 28px;
    }

    &__contacts-info {

    }

    &__receiving {

    }

    &__payment {

    }

    &__order-comment {
      margin-bottom: 40px;
    }

    &__confirm {

    }

    &__result-mini {
      display: none;
    }
  }
}

@include respond('medium') {
  .cart {
    --margin-section: 24px;
    padding-bottom: 45px;

    &__container {

    }

    &__wrapper {

    }

    &__top-container {
      min-height: 80vh;
    }

    &__come-back {

    }

    &__products {

    }

    &__bottom-container {

    }

    &__result {
      margin-bottom: var(--margin-section);
    }

    &__result_medium-down {

    }

    &__contacts-info {

    }

    &__receiving {

    }

    &__payment {

    }

    &__order-comment {
      margin-bottom: 40px;
    }

    &__confirm {

    }

    &__result-mini {
      padding: 0 var(--spacer-medium);
      height: 80px;
      transform: translate3d(0, 80px, 0);
    }
  }
}

@include respond-down('small') {
  .cart {
    --margin-section: 14px;
    padding-bottom: 30px;

    &__container {

    }

    &__wrapper {

    }

    &__top-container {
      min-height: 80vh;
    }

    &__come-back {

    }

    &__products {

    }

    &__bottom-container {

    }

    &__result-mini {

    }

    &__result {
      margin-bottom: var(--margin-section);
    }

    &__result_medium-down {

    }

    &__contacts-info {

    }

    &__receiving {

    }

    &__payment {

    }

    &__order-comment {
      margin-bottom: 20px;
    }

    &__confirm {

    }

    &__result-mini {
      padding: 0 var(--spacer-small);
      height: 60px;
      transform: translate3d(0, 60px, 0);
    }
  }
}