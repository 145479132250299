:root {
  --nav-top-items-gap: 25px;
}

.nav-top {
  &__items {
    display: flex;
    margin: 0 calc(var(--nav-top-items-gap) / -2);
  }

  &__item {
    position: relative;

    &_parent {
      .nav-top {
        &__link {
          // это квадратики, которые добавляются по краям ссылок,
          // чтобы увеличить область взаимодействия со ссылкой и
          // избежать пропадания дочернего меню при перемещении курсора по диагонали.
          // измените background, чтобы наглядно увидеть как это работает.
          &::before,
          &::after {
            content: '';
            position: absolute;
            bottom: 0;
            display: block;
            height: 20px;
            width: 20px;
            background-color: var(--t);
            pointer-events: none;
          }

          &::before {
            left: 100%;
          }

          &::after {
            right: 100%;
          }

          &:hover {
            &::before,
            &::after {
              pointer-events: auto;
              z-index: 10;
            }
          }
        }

        &__link:hover + ul.nav-top__children,
        &__children:hover {
          opacity: 1;
          pointer-events: auto;
          transform: translateY(0);
        }
      }
    }
  }

  &__link {
    display: block;
    padding: 16px calc(var(--nav-top-items-gap) / 2);
    font-size: 14px;
    line-height: 16/14*1em;
    color: rgba(255, 255, 255, 0.7);
    transition: color var(--default-transition);

    &:hover {
      color: white;

      svg {
        transform: rotate(-180deg);

        path {
          fill-opacity: 1;
        }
      }
    }

    svg {
      transition: transform var(--default-transition);

      path {
        transition: fill-opacity var(--default-transition);
      }
    }
  }

  &__children {
    position: absolute;
    top: 100%;
    right: 0;
    padding: 10px 0;
    background: white;
    box-shadow: 0 12px 40px rgba(50, 59, 86, 0.15);
    border-radius: 0 0 3px 3px;
    opacity: 0;
    pointer-events: none;
    transform: translateY(20px);
    transition: opacity var(--default-transition), transform var(--default-transition);
    cursor: pointer;
    z-index: calc(var(--header-z-index) + 10);
  }

  &__child-link {
    display: block;
    padding: 8px 22px;
    font-size: 15px;
    line-height: 1.35em;
    white-space: nowrap;
    color: var(--black-color);
    background-color: white;
    transition: color var(--default-transition), background-color var(--default-transition);

    &:hover {
      color: var(--primary-color);
      background-color: #F5F5F6;
    }
  }
}