.marketplace-orders-table {
  font-size: 14px;

  tr {
    border-bottom: 1px solid #dadada;

    &._decline {
      opacity: 0.3;
    }
  }

  td {
    padding: 5px;
    border-bottom: 1px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }

  &__marketplace-order-number {
    word-break: break-word;
    max-width: 320px;
    opacity: 0.1;

    &:hover {
      opacity: 1;
    }
  }

  .marketplace-orders-table__price {
    text-align: right;
    padding-right: 15px;
  }

  &__date {
    width: 100px;
  }
}