.services-index {

  &__picture {
    position: relative;
    padding-bottom: 70%;
    width: 100%;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
  }

  &__item-wrapper {
    position: relative;
    display: flex;
    align-items: flex-end;
    border-radius: 3px;
    overflow: hidden;
    background-size: cover;

    &:after,
    &:before {
      width: 100%;
      position: absolute;
      z-index: 10;
      content: '';
      left: 0;
    }

    &:after {
      border-radius: 3px 3px 0 0;
      bottom: 0;
      height: 60%;
      background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    }

    &:before {
      border-radius: 0 0 3px 3px;
      height: 70%;
      top: 0;
      background: linear-gradient(0, rgba(0, 0, 0, 0) 0%, #000000 130%);
      opacity: 0;
      transition: opacity var(--default-transition);
    }
  }

  &__list {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__body {
    position: relative;
    background-color: white;
    border-radius: 3px;
  }

  &__arrow {
    position: absolute;
    top: 50%;

    &_left {
      left: 0;
      transform: translate3d(-50%, -50%, 0);
    }

    &_right {
      right: 0;
      transform: translate3d(50%, -50%, 0);
    }
  }

  &__head {
    display: flex;
    justify-content: space-between;
  }

  &__small-title {
    font-weight: 500;
    line-height: 124%;
    color: white;
    display: inline-block;
    z-index: 20;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &__picture {
  }
}

@include respond-up("large") {
  .services-index {
    &__body {
      padding: 28px;
    }

    &__item-wrapper {
      //height: 189px;

      &:hover {
        &::before {
          opacity: 1;
        }
      }
    }

    &__body {
      margin-top: 28px;
    }

    &__small-title {
      font-size: 19px;
      padding: 0 28px 15px;
    }
  }
}

@include respond-up("medium") {
  .services-index {
  }
}

@include respond("medium") {
  .services-index {
    &__small-title {
      font-size: 19px;
      padding: 20px;
    }

    &__body {
      padding: 24px;
      margin-top: 24px;
    }

    &__item-wrapper {
      //height: calc((140 / 768) * 100vw);
    }
  }
}

@include respond-down("medium") {
  .services-index {
  }
}

@include respond-down("small") {
  .services-index {
    &__body {
      padding: 14px 0;
      margin: 14px -19px 0;
      background: transparent;
    }

    &__list {
      overflow: scroll;
      display: flex;
      margin: 0;
      padding: 0 19px;
    }

    &__list-wrapper {
      display: flex;
      margin: 0 -19px;
      padding: 0 26px;
      background: white;
    }

    &__item {
      margin: 14px 7px;
    }

    &__item-wrapper {
      width: 200px;
      height: 140px;

      &:before {
        height: 90%;
      }
    }

    &__small-title {
      padding: 20px;
      font-size: 18px;
    }

    &__arrow {
      display: none;
    }
  }
}
