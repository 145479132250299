.product-list__items_list {
  .product-card {
    &__wrap {
      display: grid;
      grid-column-gap: 35px;
      margin-bottom: 0;
      grid-template-areas: 'main buy'
      'main footer';
    }

    &__main {
      display: grid;
      grid-area: main;
      grid-template-areas: 'picture link'
      'picture features'
      'picture stock';
    }

    &__image {
      grid-area: picture;
    }

    &__features-wrap {
      grid-area: features;
    }

    &__link {
      grid-area: link;
    }

    &__stock-wrap {
      grid-area: stock;
    }

    &__buy {
      grid-area: buy;
      margin-top: 12px;
      justify-content: flex-start;
    }

    &__footer-wrap {
      opacity: 1;
      grid-area: footer;
      border-top: none;
      display: grid;
      grid-template-areas: 'bonus click';
      margin-top: 0;
      padding-top: 0;
    }

    &__click {
      grid-area: click;
    }

    &__bonus {
      grid-area: bonus;
    }

    &__features {
      flex-direction: row;
    }

    &__feature:not(:last-child) {
      margin-bottom: 0;
      margin-right: 8px;
    }
  }
}

.product-card {
  height: 100%;

  &__wrap {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 0;
    position: relative;
  }

  &__image,
  &__image-link {
    position: relative;
  }

  &__image-link {
    display: block;
  }

  &__picture {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
  }

  &__img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  &__favorite {
    top: 21px;
    right: 20.5px;

    svg {
      path {
        width: 21px;
        height: 20px;
      }
    }
  }

  &__favorite,
  &__features {
    position: absolute;
    display: flex;
    flex-direction: column;
  }

  &__actions {
    position: absolute;
    display: grid;
    grid-template-columns: 1fr;
    top: -4px;
    left: -4px;
  }

  &__features {
    bottom: 0;
    left: 0;
  }

  &__action,
  &__feature {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &__hidden-target {
    &:hover {
      .product-card {
        &__hidden-name {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }

  &__action-icon {
    display: flex;
    height: 28px;
    width: 28px;
    justify-content: center;
  }

  &__hidden-name {
    position: absolute;
    left: 100%;
    margin-left: 9px;
    padding: 4px 6px;
    background-color: var(--black-color);
    border-radius: 3px;
    font-size: 13px;
    line-height: 1.3em;
    white-space: nowrap;
    color: white;
    opacity: 0;
    pointer-events: none;
    transform: translateX(10px);
    transition: opacity var(--default-transition), transform var(--default-transition);
    z-index: 10;

    &:before {
      content: '';
      position: absolute;
      right: calc(100% - 1px);
      top: 50%;
      transform: translateY(-50%);
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7px 6px 7px 0;
      border-color: $t var(--black-color) $t $t;
    }
  }

  &__feature-icon {
    display: flex;
    height: 20px;
    width: 20px;
  }

  &__link {
    display: block;

    &:hover {
      .product-card {
        &__title {
          background-size: 100% 100%;
        }
      }
    }
  }

  &__title {
    position: relative;
    color: var(--black-color);
    transition: background-size var(--default-transition);

    // эта линия на 1px выше
    background: linear-gradient($t calc(100% - 2px), var(--product-name-border) calc(100% - 2px), var(--product-name-border) calc(100% - 1px), $t calc(100% - 1px), $t 100%) no-repeat 0 / 0 100%;

    //background: linear-gradient(transparent calc(100% - 1px), var(--product-name-border) 1px) no-repeat 0 / 100% 100%;
  }

  &__producer {
    font-weight: 500;
  }

  &__stock {
    display: inline-flex;
  }

  &__buy {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__prices {
    display: flex;
    align-items: center;
  }

  &__price {
    font-weight: 500;
    color: var(--black-color);
    white-space: nowrap;
    transition: color var(--default-transition);

    &:not(:last-child) {
      margin-right: 8px;
    }

    &_red {
      color: var(--alert-color);
    }

    &_old {
      color: var(--text-color-light);
      text-decoration: line-through;
      font-weight: 400;
    }
  }

  &__kit-price {
    font-size: 13px;
    line-height: 1.2em;
  }

  &__cart {
    display: inline-flex;
    transition: background-color var(--default-transition), border-color var(--default-transition);

    svg path {
      transition: fill var(--default-transition);
    }
  }

  &__caption {
    display: inline-flex;
    margin-top: 14px;
    padding: 2px 6px;
    border-radius: 3px;
    background: var(--background-color);
    font-weight: 400;
    font-size: 13px;
    line-height: 1.2em;
    color: var(--link-gray-color);
  }

  &__footer-wrap {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid var(--border-gray-color);
  }

  &__click {
    margin-right: 6px;
  }

  &__click-name {
    white-space: nowrap;
  }

  &__features-wrap {
    position: relative;
  }

  &__cart-name {
    display: none;
  }
}

@include respond-up('large') {
  .product-list__items_list {
    .product-card {
      &__image {
        max-width: 121px;
      }

      &__wrap {
        grid-template-columns: 62% 34%;
        padding: 22px 40px 22px 48px;
      }

      &__main {
        grid-template-columns: calc((121 / 1500) * 100vw) 1fr;
      }

      &__price-box {
        flex: 0 0 70%;
        margin-top: 14px;
      }

      &__stock-wrap {
        margin-top: 11px;
      }

      &__link {
        margin-top: 12px;
      }

      &__wrap {
        min-height: 155px;
      }

      &__favorite {
        top: 48px;
        right: 40.5px;
      }

      &__buy {
        margin-top: 0;
      }

      &__cart {
        margin-top: 16px;
      }
    }
  }

  .product-card {
    position: relative;

    &__wrap {
      // отрицательный маржин вниз на высоту футера плиточки - не нужен, пока нет футера
      //margin-bottom: -47px;
      position: relative;
      padding: 24px 24px 22px;
      background-color: $t;
      box-shadow: 0 7px 40px rgba(50, 59, 86, 0);
      transition: transform var(--default-transition),
      box-shadow var(--default-transition),
      border-radius var(--default-transition),
      background-color var(--default-transition),
      opacity var(--default-transition),
      height var(--default-transition),
      padding-bottom var(--default-transition);

      /*TODO Вернуть функционирующим - Заказ в 1 клик на маленькой карточке товара*/
      /*&:hover {
        position: relative;
        padding-bottom: 22px;
        border-radius: 3px;
        background-color: white;
        box-shadow: 0 7px 40px rgba(50, 59, 86, 0.12);
        z-index: 900!important;

        .product-card {
          &__kit-price {
            color: var(--black-color);
          }

          &__cart {
            border-color: var(--primary-color);
            background-color: var(--primary-color);

            &:hover {
              border-color: var(--primary-color-hover);
              background-color: var(--primary-color-hover);
            }

            svg path {
              fill: white;
            }
          }

          &__footer-wrap {
            opacity: 1;
            pointer-events: auto;
          }
        }
      }*/
    }

    &__buy {
      margin-top: 27px;
    }

    &__kit-price {
      color: var(--link-gray-color);
      transition: color var(--default-transition);
    }

    &__footer-wrap {
      margin-top: 14px;
      transform-origin: top;
      opacity: 0;
      pointer-events: none;
      overflow: hidden;
      transition: transform var(--default-transition), opacity var(--default-transition), height var(--default-transition);
    }
  }
}

@include respond-up('medium') {
  .product-list__items_list {
    .product-card {

    }
  }

  .product-card {
    &__image-link {
      padding-top: 175/185*100%;
    }

    &__link {
      margin-top: 18px;
    }

    &__link,
    &__name {
      line-height: 1.35em;
    }

    &__name {
      font-size: 15px;
    }

    &__stock {
      margin-top: 10px;
    }

    &__prices {
      flex-wrap: wrap;
      margin-right: 4px;
    }

    &__price {
      font-size: 20px;
      line-height: 1.17em;

      &_old {
        font-size: 13px;
        line-height: 1.2em;
      }
    }

    &__kit-price {
      margin-top: 3px;
    }

    &__cart {
      align-self: flex-start;

      // все паддинги на минус один из-за бордера
      padding: 9px 11px 9px 10px;

      &_mobile {
        display: none;
      }
    }

    &__click-name {
      &_mobile {
        display: none;
      }
    }

    &__footer-wrap {
      padding-top: 15px;
    }
  }
}

@include respond('medium') {
  .product-list__items_list {
    .product-card {
      &__image {
        max-width: 90px;
        max-height: 82px;
      }

      &__wrap {
        grid-template-columns: 54% 40%;
        padding: 21px 30px 30px 41px;
        min-height: 160px;
      }

      &__main {
        grid-template-columns: calc((90 / 768) * 100vw) 1fr;
        grid-column-gap: 20px;
      }

      &__price-box {
        flex: 0 0 63%;
      }

      &__link {
        margin-top: 10px;
      }

      &__features {
        bottom: 3px;
      }

      &__footer-wrap {
        padding-top: 39px;
      }

      &__favorite {
        top: 43px;
        right: 40.5px;
      }

      &__stock-wrap {
        display: flex;
        align-items: flex-end;
      }
    }
  }

  .product-card {
    &__wrap {
      padding: 24px 24px 26px;
    }

    &__buy {
      margin-top: 24px;
    }

    &__footer-wrap {
      margin-top: 24px;
    }
  }
}

@include respond-down('medium') {
  .product-list__items_list {
    .product-card {

    }
  }

  .product-card {
    &__cart {
      &.button_transparent {
        border-color: var(--primary-color);
        background-color: var(--primary-color);

        &:hover {
          border-color: var(--primary-color-hover);
          background-color: var(--primary-color-hover);
        }

        svg path {
          fill: white;
        }
      }
    }
  }
}

@include respond('small') {
  .product-list__items_list {
    .product-card {

    }
  }

  .product-card {
    &__wrap {
      padding: 15px 14px 24px;
    }
  }
}

@include respond-down('small') {
  .product-list__items_list {
    .product-card {
      &__image {
        max-width: 79px;
      }

      &__wrap {
        padding: 16px 20px 20px;
        grid-template-columns: 1fr;
        grid-template-areas: 'main'
        'buy'
        'footer';
      }

      &__main {
        grid-template-columns: calc((79 / 375) * 100vw) 1fr;
        grid-column-gap: 14px;
      }

      &__price-box {
        margin-left: calc((93 / 375) * 100vw);
        display: flex;
        align-items: center;
      }

      &__link {
        font-size: 13px;
        padding-right: 36px;
        margin-top: 13px;
      }

      &__features-wrap {
        margin-top: 25px;
      }

      &__footer {
        border-top: 1px solid var(--border-gray-color);
        padding-top: 10px;
        margin-top: 16px;
      }

      &__footer-wrap {
        grid-template-areas: 'click bonus';
      }

      &__click-name {
        &_mobile {
          display: none;
        }

        &_desktop {
          display: block;
        }
      }

      &__cart-name {
        display: block;
      }

      &__cart {
        min-width: 142px;
        padding: 8.5px 20px;

        &:after {
          content: 'В корзину';
          color: white;
          right: 0;
          top: 0;
          font-weight: 500;
          font-size: 15px;
          line-height: 1.33em;
        }

        svg {
          margin-right: 12px;
        }
      }

      &__prices {
        margin-right: 9px;
      }

      &__buy {
        margin-top: 16px;
      }

      &__favorite {
        top: 21px;
        right: 20.5px;
      }

      &__stock-wrap {
        padding-top: 11px;
      }
    }
  }

  .product-card {
    &__action-name {
      display: none;
    }

    &__image-link {
      padding-top: 157/140*100%;
    }

    &__link {
      margin-top: 14px;
    }

    &__link,
    &__name {
      line-height: 1.2em;
    }

    &__name {
      font-size: 14px;
    }

    &__stock {
      margin-top: 5px;
    }

    &__buy {
      margin-top: 23px;
    }

    &__price {
      font-size: 17px;
      line-height: 1.24em;

      &_old {
        font-size: 12px;
        line-height: 1.2em;
      }
    }

    &__cart {
      // все паддинги на минус один из-за бордера
      padding: 7px 9px 7px 8px;

      &_desktop {
        display: none;
      }
    }

    &__bonus {
      display: none;
    }

    &__click-name {
      &_desktop {
        display: none;
      }
    }

    &__footer-wrap {
      margin-top: 10px;
      padding-top: 10px;
    }

    &__favorite {
      top: 15px;
      right: 14.5px;
    }
  }
}

@include respond-down('x-small') {
  .product-list__items_list {
    .product-card {

    }
  }

  .product-card {
    &__hidden-name {
      padding: 2px 4px;
      font-size: 11px;
    }

    &__wrap {
      padding: 14px 12px 16px;
    }
  }
}