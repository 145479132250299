article {
  table {
    border: 1px solid var(--border-gray-color) !important;
    width: 100% !important;
  }

  td {
    padding: 15px !important;
    font-style: normal !important;
    border: none !important;
    color: var(--text-color) !important;
    font-size: 14px !important;
    line-height: 120% !important;
    font-family: var(--font) !important;

    > * {
      color: var(--text-color) !important;
      font-size: 14px !important;
      line-height: 120% !important;
      font-family: var(--font) !important;
    }

    &:first-child {
      color: var(--black-color) !important;
    }

    &:first-child {
      > * {
        color: var(--black-color) !important;
      }
    }

    &:not(:last-child) {
      border-right: 1px solid var(--border-gray-color) !important;
    }
  }

  tr {
    &:first-child {
      td {
        font-weight: 500 !important;
        color: var(--black-color) !important;
      }
    }

    &:nth-child(odd) {
      background: var(--gray-layer) !important;
    }
  }

  em {
    font-style: italic;
  }

  p, li {
    line-height: 150%;
    color: var(--text-color);
    font-weight: 400;
  }

  strong {
    //font-weight: 500;
    //line-height: 117%;
  }

  blockquote {
    p {
      font-size: 16px;
      line-height: 150%;
      color: black;
      padding-left: 27px;
      position: relative;

      &:before {
        left: 0;
        top: 0;
        position: absolute;
        content: "";
        width: 3px;
        height: 100%;
        background-color: var(--primary-color);
      }
    }
  }

  ol {
    counter-reset: heading;

    li {
      display: flex;
      margin-bottom: 12px;

      &:before {
        counter-increment: heading;
        content: "" counter(heading) ". ";
        color: var(--primary-color);
        font-weight: bold;
        margin-right: 10px;
      }
    }
  }

  ul {
    li {
      position: relative;
      padding-left: 24px;
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }

      &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 9px;
        width: 6px;
        height: 6px;
        background-color: var(--primary-color);
      }
    }
  }

  a {
    color: var(--primary-color);
  }
}

@include respond-up('large') {
  article {
    margin-top: 40px;

    h2 {
      margin-top: 40px;
    }

    a {
      position: relative;

      &:hover {
        &::before {
          opacity: 1;
        }
      }

      &::before {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        bottom: 0;
        border-bottom: 1px solid var(--primary-color-opacity-light);
        opacity: 0;
        transition: opacity var(--default-transition);
      }
    }
  }
}

@include respond-up('medium') {
  article {
    margin-top: 45px;

    p, li {
      font-size: 16px;
    }

    p {
      margin-top: 20px;
    }

    ul, ol {
      margin-top: 30px;
    }

    blockquote {
      margin-top: 35px;
    }
  }
}

@include respond('medium') {
  article {
    p, li {
      font-size: 14px;
    }

    h2 {
      margin-top: 45px;
    }
  }
}

@include respond-down('small') {
  article {
    margin-top: 30px;

    p, li {
      font-size: 15px;
    }

    ul, ol {
      margin-top: 20px;
    }

    ul {
      li {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    strong {
      font-size: 20px;
    }

    blockquote {
      p {
        font-size: 15px;
      }
    }

    p {
      margin-top: 12px;

      img {
        object-fit: contain;
        height: 100%;
        width: 100%;
      }
    }

    h2 {
      margin-top: 30px;
    }

    blockquote {
      margin-top: 20px;
    }
  }
}
