.marketplace-orders-results {
  display: flex;
  margin-bottom: 40px;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 28px;

  &__item {
    flex: 1 0 337px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__label {
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;

    &:first-child {
      font-size: 12px;
      color: #757575;
    }

    &:nth-child(2) {
      border-top: 1px solid #dadada;
      font-size: 16px;
    }
  }
}