.cart-order-comment {
  display: flex;

  &__label {
    flex: 0 0 max-content;
    display: flex;
    flex-direction: column;
  }

  &__title {
    color: var(--black-color);
    font-weight: 500;
    line-height: 1.24;
    white-space: nowrap;
  }

  &__description {
    line-height: 1.33;
    color: var(--text-color-light-2);
  }

  &__comment {
    resize: none;
    flex: 1 1 auto;
    font-size: 15px;
    padding: 12px;
  }
}

@include respond-up('large') {
  .cart-order-comment {
    padding: 40px 50px;

    &__label {

    }

    &__title {
      font-size: 19px;
      margin-bottom: 14px;
    }

    &__description {
      font-size: 15px;
    }

    &__comment {
      margin-left: 88px;
      height: 98px;
    }
  }
}

@include respond('medium') {
  .cart-order-comment {
    padding: 30px;

    &__label {

    }

    &__title {
      font-size: 19px;
      margin-bottom: 14px;
    }

    &__description {
      font-size: 15px;
    }

    &__comment {
      margin-left: 30px;
      height: 98px;
    }
  }
}

@include respond-down('small') {
  .cart-order-comment {
    padding: 20px;
    flex-direction: column;

    &__label {
      margin-bottom: 20px;
    }

    &__title {
      font-size: 18px;
      margin-bottom: 10px;
    }

    &__description {
      font-size: 14px;
    }

    &__comment {
      height: 56px;
      font-size: 14px;
    }
  }
}