.vacancy-form {
  &__field {
    &_full {
      textarea {
        height: 98px;
        resize: none;
        padding: 12px;
      }
    }
  }

  &__bottom {
    display: flex;
  }

  &__success-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__success-svg {
    width: 71px;
    height: 71px;
    border-radius: 50%;
    background: var(--gray-layer);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__success-text-wrap {
    text-align: center;
    margin-top: 25px;
  }

  &__success-description {
    margin-top: 12px;
  }
}

@include respond-up('large') {
  .vacancy-form {
    &__top-fields {
      grid-gap: 22px;
    }
  }
}

@include respond-up('medium') {
  .vacancy-form {
    &__form {
      margin-top: 30px;
    }

    &__top-fields {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }

    &__field {
      &_small {
        grid-column: span 1;
      }

      &_full {
        margin-top: 22px;
      }
    }

    &__bottom {
      justify-content: space-between;
      margin-top: 22px;
    }

    &__button {
      width: 249px;
    }
  }
}

@include respond('medium') {
  .vacancy-form {
    &__top-fields {
      grid-gap: 15px;
    }
  }
}

@include respond-down('medium') {
  .vacancy-form {

  }
}

@include respond-down('small') {
  .vacancy-form {
    &__form {
      margin-top: 14px;
    }

    &__top-fields {
      display: flex;
      flex-direction: column;
      row-gap: 15px;
    }

    &__field {
      &_full {
        margin-top: 15px;
      }
    }

    &__bottom {
      flex-direction: column;
      row-gap: 20px;
      margin-top: 15px;
    }

    &__button {
      width: 100%;
    }
  }
}