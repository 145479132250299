.modal-success {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__svg {
    margin-bottom: 25px;
  }

  &__title {
    max-width: 250px;
    text-align: center;
  }

  &__subtitle {
    max-width: 250px;
    font-size: 15px;
    line-height: 133%;
    text-align: center;
  }
}

@include respond-up('medium') {
  .modal-success {
    padding: 190px 150px;

    &__svg {

    }

    &__title {
      margin-bottom: 12px;
    }

    &__subtitle {

    }
  }
}

@include respond-down('small') {
  .modal-success {
    padding: 70px 30px 260px;
    &__container {

    }

    &__svg {

    }

    &__title {
      margin-bottom: 10px;
    }

    &__subtitle {

    }
  }
}