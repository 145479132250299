.sizes-block {
  position: relative;

  &__total-wrap {
    padding: 25px;
    background: #fff;
    box-shadow: inset 0px -1px 0px var(--border-gray-color);
  }
}

@include respond-down('small') {
  .sizes-block {
    &__total-wrap {
      display: none;
    }
  }
}