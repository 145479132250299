.link-stock {
  position: relative;
  display: inline-block;
  font-size: 14px;
  line-height: 1.2em;
  color: var(--success-color);
  transition: color var(--default-transition) border-bottom-color var(--default-transition);
  text-decoration: underline dashed;
  text-underline-offset: 2px;

  cursor: default;

  &_hoverable {
    &:hover {
      &::before {
        pointer-events: auto;
      }

      .link-stock {
        &__items {
          opacity: 1;
          transform: translateY(0);
          pointer-events: auto;
        }
      }
    }
  }

  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 100%;
    left: -14px;
    right: -14px;
    height: 9px;
    background-color: $t;
    pointer-events: none;
  }

  &_few {
    color: var(--primary-color);
    border-bottom-color: var(--primary-color-opacity);
  }

  &_end {
    color: var(--link-gray-color);
    border-bottom-color: var(--link-gray-color-opacity);
  }

  &__items {
    position: absolute;
    top: calc(100% + 9px);
    left: -14px;
    width: auto;
    padding: 8px 0;
    border-radius: 3px;
    background-color: var(--black-color);
    opacity: 0;
    transform: translateY(10px);
    pointer-events: none;
    transition: opacity var(--default-transition), transform var(--default-transition);
    z-index: 10;

    &::before {
      content: '';
      position: absolute;
      left: 15px;
      bottom: 100%;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 6px 5px 6px;
      border-color: $t $t var(--black-color) $t;
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

    &:first-child {
      border-top: 1px solid rgba(255, 255, 255, 0.15);
    }
  }

  &__address,
  &__count {
    line-height: 1.2em;
    color: rgba(255, 255, 255, 0.7);
  }

  &__address {
    color: rgba(255, 255, 255, 0.7);
  }

  &__count {
    font-weight: 500;
    color: white;
    white-space: nowrap;
  }
}

@include respond-up('medium') {
  .link-stock {
    &__item {
      padding: 6px 15px;
    }

    &__address,
    &__count {
      font-size: 13px;
    }

    &__address {
      min-width: 150px;
      padding-right: 10px;
    }
  }
}

@include respond-down('small') {
  .link-stock {
    &__address,
    &__count {
      font-size: 11px;
    }

    &__address {
      min-width: 120px;
    }
  }
}

@include respond-down('small') {
  .link-stock {
    &__items {
      left: -10px;
    }

    &__item {
      padding: 2px 6px;
    }

    &__address {
      padding-right: 5px;
    }
  }
}

@include respond-down('x-small') {
  .link-stock {
    &__address,
    &__count {
      font-size: 10px;
    }

    &__address {
      min-width: 100px;
    }
  }
}