@include respond-up('large') {
  .vacancies-sticky {
    padding: 33px;
    display: flex;
    flex-direction: column;
    background: #fff;
    position: sticky;
    top: 100px;
    border-radius: 3px;

    &__email-wrap {
      margin-top: 20px;
    }

    &__button-wrap {
      margin-top: 30px;
    }

    &__button {
      width: 100%;
    }
  }
}