.marketplace-manager-offer {
  padding: 30px 0;

  &__history {

  }

  &__history-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    padding: 3px 10px;
    font-size: 14px;

    &:nth-child(even) {
      background-color: #fff;
    }
  }
}