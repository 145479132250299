.policy {
  color: var(--text-color-light);
  line-height: 1.2;
  font-size: 13px;

  &__link {
    color: var(--primary-color);
  }
}

@include respond-down('small') {
  .policy {
    text-align: center;
  }
}