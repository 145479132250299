.content-header {

}

@include respond-up('large') {
  .content-header {
    padding: 35px 0;
  }
}

@include respond-up('medium') {
  .content-header {
    &__title {
      margin-top: 10px;
    }
  }
}

@include respond('medium') {
  .content-header {
    padding: 30px 0;
  }
}

@include respond-down('small') {
  .content-header {
    padding: 15px 0;

    &__title {
      margin-top: 8px;
    }
  }
}