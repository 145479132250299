.other-products {
  &__list-wrapper {
    position: relative;
  }

  &__list {
    display: flex;

    &::before {
      bottom: 0;
      left: 0;
      right: 0;
      height: 1px;
    }

    &::after {
      top: 0;
      right: 0;
      bottom: 0;
      width: 1px;
    }
  }

  &__item {
    background-color: white;
    box-sizing: border-box;
    border-right: 1px solid var(--border-gray-color);
  }

  &__arrow {
    position: absolute;
    top: 50%;

    &_left {
      left: 0;
      transform: translate3d(-50%, -50%, 0);
    }

    &_right {
      right: 0;
      transform: translate3d(50%, -50%, 0);
    }
  }
}

@include respond-up('large') {
  .other-products {
    &__head {
      margin: 70px 0 28px;
    }

    &__item {
      opacity: 0;
      transition: var(--default-transition);

      &.tns-slide-active {
        opacity: 1;
      }
    }
  }
}

@include respond('medium') {
  .other-products {
    &__head {
      margin: 45px 0 24px;
    }

    &__list {
      grid-template-columns: 1fr 1fr 1fr;
    }

    &__item {
      opacity: 0;
      transition: var(--default-transition);

      &.tns-slide-active {
        opacity: 1;
      }
    }
  }
}

@include respond-down('small') {
  .other-products {
    &__head {
      margin: 45px 0 24px;
    }

    &__list-wrapper {
      overflow: hidden;
      margin: 0 -19px;
    }

    &__list {
      display: flex;
      overflow: scroll;
      transform: none;
      padding-left: 19px;
      padding-bottom: 40px;
      margin-bottom: -40px;
    }

    &__item {
      width: 169px;
      min-width: 169px;
      display: none;

      opacity: 0;
      transition: var(--default-transition);

      &.tns-slide-active {
        opacity: 1;
        display: flex;
      }
    }

    &__product-card {
      min-height: 100%;

      .product-card {
        &__wrap {
          min-height: 100%;
          display: inline-flex;
          flex-direction: column;
        }
      }

      .link-stock {
        margin-top: auto;
        width: max-content;
      }
    }

    &__arrow {
      display: none;
    }
  }
}

@include respond-down('x-small') {
  .other-products {
    &__list-wrapper {
      margin: 0 -12px;
    }
  }
}
