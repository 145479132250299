.model-offers {
  &__tabs-wrapper {
    display: flex;
  }

  &__tabs {

  }

  &__tab {
    &._active {
      .model-offers {
        &__tab-link {
          color: #fff;
          background-color: var(--black-color);
        }
      }
    }
  }

  &__tab-link {
    line-height: 1.33;
    font-size: 15px;
    transition: var(--default-transition);
    color: var(--black-color);
    background-color: var(--background-color);
    border-radius: 3px;
    text-align: center;
    min-width: 47px;
  }

  &__help-button {
    display: none;
  }

  &__content-container {

  }

  &__content {

  }

  &__content-size {
    line-height: 1.24;
    font-weight: 500;
  }

  &__offers-list {

  }

  &__offers-item {
    border-top: 1px solid var(--border-gray-color);

    &:last-child {
      border-bottom: 1px solid var(--border-gray-color);
    }
  }

  &__empty {
    font-style: normal;
    font-weight: 400;
  }
}

@include respond-up('large') {
  .model-offers {
    padding: 40px 0 1px;

    &__empty {
      padding: 0 33px 40px;
    }

    &__tabs-wrapper {
      padding: 0 50px;
      margin-bottom: 38px;
    }

    &__tabs {
      margin: 0 -6px;
    }

    &__tab {
      padding: 0 6px;
    }

    &__tab-link {
      padding: 8px 10px;
    }

    &__help-button {

    }

    &__content-container {

    }

    &__content {

    }

    &__content-size {
      padding: 0 50px;
      font-size: 19px;
      margin-bottom: 15px;
    }

    &__offers-list {
      margin-bottom: 38px;
    }

    &__offers-item {

    }
  }
}

@include respond('medium') {
  .model-offers {
    &__empty {
      font-size: 15px;
      line-height: 133%;
    }
  }
}

@include respond('medium') {
  .model-offers {
    padding: 30px 0 1px;

    &__empty {
      padding: 0 30px 30px;
    }

    &__tabs-wrapper {
      padding: 0 30px;
      margin-bottom: 38px;
      justify-content: space-between;
    }

    &__tabs {
      margin: 0 -6px;
    }

    &__tab {
      padding: 0 6px;
    }

    &__tab-link {
      padding: 8px 10px;
    }

    &__help-button {
      display: flex;
    }

    &__content-container {

    }

    &__content {

    }

    &__content-size {
      padding: 0 30px;
      font-size: 19px;
      margin-bottom: 15px;
    }

    &__offers-list {
      margin-bottom: 30px;
    }

    &__offers-item {

    }
  }
}

@include respond-down('small') {
  .model-offers {
    padding: 30px 0 0;

    &__empty {
      padding: 0 19px 30px;
      font-size: 13px;
      line-height: 120%;
    }

    &__tabs-wrapper {
      padding: 0 19px;
      margin-bottom: 20px;
      justify-content: space-between;
    }

    &__tabs {
      margin: 0 -4px;
    }

    &__tab {
      padding: 0 4px;
    }

    &__tab-link {
      padding: 8px 10px;
      font-size: 13px;
    }

    &__help-button {

    }

    &__content-container {

    }

    &__content {
      &:last-child {
        .model-offers {
          &__offers-list {
            margin-bottom: 0;
          }

          &__offers-item {
            &:last-child {
              border-bottom: none;
            }
          }
        }
      }
    }

    &__content-size {
      padding: 0 19px;
      font-size: 18px;
      margin-bottom: 15px;
      padding-top: 20px;
      border-top: 1px solid var(--border-gray-color);
    }

    &__offers-list {

    }

    &__offers-item {
      &:last-child {
        border-bottom: none;
      }
    }
  }
}