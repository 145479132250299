






























































































































































































.record-for-service-contacts {
    &__input-item {
        padding: 0 11px 15px;
        flex: 0 0 50%;
    }
}
