:root {
  --default-input-height: 44px;
  --errors-color: var(--alert-color, #b50000)
}

$inputs: 'input[type="text"], input[type="search"], input[type="password"], input[type="number"], input[type="email"]';

form {
  label {
    margin-bottom: 4px;
    display: block;
    font-family: var(--font);
    font-size: 15px;
    line-height: 1.33em;
    color: var(--text-color-light);
  }

  ul.errors {
    margin-top: 4px;
    list-style: none;

    li {
      margin-top: 2px;
      color: var(--errors-color);
      font-family: var(--font);
      font-size: 13px;
      line-height: 1.2em;
    }
  }

  .form-field {
    margin-bottom: 10px;
  }

  #{$inputs}, textarea, select {
    box-shadow: none;
    margin: 0;
    padding: 12px;
    width: 100%;
    display: block;
    appearance: none;
    font-family: var(--font);
    font-size: 15px;
    line-height: 1.33em;
    color: var(--black-color);
    border: 1px solid var(--input-border-color);
    border-radius: 3px;
    background-color: white;
    transition: border-color var(--default-transition);

    &:hover, &:focus {
      border-color: var(--input-border-color-hover);
    }

    &::placeholder {
      font-size: 15px;
      line-height: 1.33em;
      color: var(--text-color-light-2);
    }

    &._error {
      border-color: var(--errors-color)
    }
  }

  #{$inputs}, select {
    height: var(--default-input-height);
  }

  textarea {
    padding: 5px 10px;
  }

  select {
    padding-right: 30px;
    background: url('/static/images/svg/arrows/bottom-gray.svg') no-repeat calc(100% - 12px) 50% / 10px 7px;
  }
}

label {
  margin-bottom: 4px;
  display: block;
  font-family: var(--font);
  font-size: 15px;
  line-height: 1.33em;
  color: var(--text-color-light);
}

ul.errors {
  margin-top: 4px;
  list-style: none;

  li {
    margin-top: 2px;
    color: var(--errors-color);
    font-family: var(--font);
    font-size: 13px;
    line-height: 1.2em;
  }
}

.form-field {
  margin-bottom: 10px;
}

#{$inputs}, textarea, select {
  box-shadow: none;
  margin: 0;
  padding: 12px;
  width: 100%;
  display: block;
  appearance: none;
  font-family: var(--font);
  font-size: 15px;
  line-height: 1.33em;
  color: var(--black-color);
  border: 1px solid var(--input-border-color);
  border-radius: 3px;
  background-color: white;
  transition: border-color var(--default-transition);

  &:hover, &:focus {
    border-color: var(--input-border-color-hover);
  }

  &::placeholder {
    font-size: 15px;
    line-height: 1.33em;
    color: var(--text-color-light-2);
  }

  &._error {
    border-color: var(--errors-color)
  }
}

#{$inputs}, select {
  height: var(--default-input-height);
}

textarea {
  padding: 5px 10px;
}

select {
  padding-right: 30px;
  background: url('/static/images/svg/arrows/bottom-gray.svg') no-repeat calc(100% - 12px) 50% / 10px 7px;
}


input[type="checkbox"] {
  display: none;

  & + label {
    display: inline-flex;
    align-items: center;
    font-size: 15px;
    line-height: 1.35em;
    color: #1F2129;
    cursor: pointer;
    margin-bottom: 0;

    &::before {
      content: '';
      display: inline-block;
      height: 19px;
      width: 19px;
      border: 1px solid var(--checkbox-border-color);
      border-radius: 3px;
      margin-right: 8px;
      box-sizing: border-box;
    }
  }

  &:checked {
    & + label {
      &::before {
        background: url('/static/images/svg/check.svg') no-repeat 50% 30%;
      }
    }
  }

  &[disabled="disabled"] {
    & + label {
      color: var(--button-disabled-color);
    }

    &:checked {
      & + label {
        &::before {
          background: url('/static/images/svg/check-disabled.svg') no-repeat 50% 30%;
        }
      }
    }
  }
}

.custom-checkbox {
  position: relative;

  input[type=checkbox] {
    &:checked {
      & + label {
        &:before {
          background: none;
        }

        &::after {
          transform: translateX(16px);
          background-color: #2c9407;
        }
      }
    }
  }

  input[type=checkbox] + label {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 133%;
    color: var(--link-gray-color);
    display: flex;
  }

  input[type=checkbox] + label:after {
    content: "";
    display: inline-block;
    height: 14px;
    width: 14px;
    background-color: var(--button-disabled-color);
    border-radius: 50%;
    position: absolute;
    left: 3px;
    top: 2px;
    transition: transform .2s ease-in-out, background-color .2s ease-in-out;
  }

  input[type=checkbox] + label:before {
    content: "";
    display: inline-block;
    height: 20px;
    width: 37px;
    border: 1px solid var(--button-border-color);
    border-radius: 100px;
    margin-right: 14px;
    box-sizing: border-box;
    margin-top: -2px;
  }

  &_light {
  }

  &_dark {
  }
}

.sort-field {
  display: flex;
  align-items: center;

  select {
    border: unset;
    padding: 12px 30px 11px 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 133%;
    color: var(--black-color);
  }

  &__icon {
    display: flex;
    height: max-content;
  }
}

@include respond-up('large') {
  .custom-checkbox {
    &:hover {
      input[type=checkbox] + label:after {
        transform: translateX(16px);
      }
    }
  }
}