.cart-confirm {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__button {

  }

  &__policy {
    color: var(--text-color-light);
    line-height: 1.2;
    font-size: 13px;
  }

  &__policy-link {
    color: var(--primary-color);
  }
}

@include respond-up('large') {
  .cart-confirm {
    &__button {
      margin-bottom: 23px;
    }

    &__policy {

    }

    &__policy-link {

    }
  }
}

@include respond('medium') {
  .cart-confirm {
    &__button {
      margin-bottom: 23px;
    }

    &__policy {

    }

    &__policy-link {

    }
  }
}

@include respond-down('small') {
  .cart-confirm {
    &__button {
      margin-bottom: 14px;
      width: 100%;
    }

    &__policy {
      text-align: center;
    }

    &__policy-link {

    }
  }
}