.question-form {
  &__fields {
    display: grid;
  }

  &__bottom {
    display: flex;
    align-items: center;
  }

  &__politic {
    font-size: 13px;
    line-height: 120%;
    color: var(--text-color-light);

    &_link {
      color: var(--primary-color);
    }
  }

  &__success-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__success-svg {
    width: 71px;
    height: 71px;
    border-radius: 100%;
    border: 2px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--gray-layer);
    margin-bottom: 25px;

    svg {
      height: 25px;
      width: 28px;

      path {
        fill: var(--black-color);
      }
    }
  }

  &__wrap {
    display: flex;
    flex-direction: column;
  }

  &__success-title {
    font-weight: 500;
    font-size: 24px;
    line-height: 28/24*1em;
    text-align: center;
    color: var(--black-color);
    margin-bottom: 12px;
  }

  &__success-description {
    font-weight: 400;
    font-size: 15px;
    line-height: 20/15*1em;
    text-align: center;
    color: var(--text-color-light-2);
  }
}

@include respond-up('large') {
  .question-form {

  }
}

@include respond-up('medium') {
  .question-form {
    &__fields {
      display: grid;
      grid-auto-columns: 1fr 1fr;
      grid-gap: 22px;
    }

    &__field {
      &_full {
        grid-column: 1/3;
      }
    }

    &__bottom {
      margin-top: 22px;
    }

    &__politic {
      flex: 0 0 227px;
      margin-left: 25px;
    }
  }
}

@include respond('medium') {
  .question-form {
    &__bottom {
      button {
        min-width: 220px;
      }
    }
  }
}

@include respond-down('medium') {
  .question-form {
  }
}

@include respond-down('small') {
  .question-form {
    &__fields {
      grid-template-columns: 1fr;
      grid-gap: 15px;
    }

    &__bottom {
      flex-direction: column;
      margin-top: 20px;
      align-items: stretch;

      button {
        width: 100%;
      }
    }

    &__politic {
      max-width: 227px;
      text-align: center;
      align-self: center;
      margin-top: 10px;
    }
  }
}