.assortment-page {
  &__filter-button {
    svg.icon-filter {
      height: 22px;
      width: 22px;
    }
  }

  &__product-card{
    height: 100%;
  }
}

@include respond-up('large') {
  .assortment-page {
    &__container {
      margin-bottom: 70px;
    }

    &__wrap {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: var(--gutter-large);
    }

    &__main {
      display: grid;
      /*grid-template-columns: 239px 1fr;
      grid-gap: var(--gutter-large);*/
    }

    &__filter-button {
      display: none;
    }

    &__side-filter {
      border-radius: 3px;
      z-index: 20;
    }

    &__product-list {
      z-index: 10;
    }
  }
}

@include respond('medium') {
  .assortment-page {
    &__container {
      margin-bottom: 45px;
    }

    &__wrap {
      margin-bottom: 45px;
    }

    &__selection-filter {
      margin-bottom: var(--gutter-medium);
    }

    &__filter-button {
      max-width: 200px;
      bottom: 45px;
    }
  }
}

@include respond-down('medium') {
  .assortment-page {
    &__filter-button {
      display: flex;
      align-items: center;
      justify-content: center;
      position: sticky;
      margin: 0 auto;
      font-size: 15px;
      line-height: 1.35em;
      color: #FFFFFF;

      svg.icon-filter {
        margin-right: 11px;
      }
    }

    &__side-filter {
      display: none;
    }
  }
}

@include respond-down('small') {
  .assortment-page {
    &__container {
      margin-bottom: 30px;
    }

    &__wrap {
      margin-bottom: 30px;
    }

    &__selection-filter {
      margin-bottom: var(--gutter-small);
    }

    &__filter-button {
      max-width: 146px;
      bottom: 30px;
    }
  }
}