.marks {
  position: absolute;
  right: 0;
  top: 100%;
  max-width: 450px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
  padding: 20px;
  background-color: #fff;
  display: none;

  &__form {
    transition: opacity 0.3s;

    &._loading {
      opacity: 0.3;

      button {
        background-color: #dadada;
        cursor: progress;
      }
    }
  }

  &._opened {
    display: block;
  }

  a.marks__toggle {
    font-size: 25px;
    position: absolute;
    text-decoration: none;
    top: 0;
    right: 4px;
    line-height: 1;
    border: none;
    color: #0a0a0a;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 1.1;
  }

  &__title-count {
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
    opacity: 0.7;
  }

  &__item {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item-value {
    border: 1px solid #2c1818;
    background-color: rgba(44,24,24,.1);
    padding: 3px 5px;
    border-radius: 4px;
    overflow: hidden;
    color: #555;
    cursor: copy;
    transition: background-color 0.3s;

    &._copied {
      background-color: rgba(112, 208, 85, 0.27);
    }
  }

  &__button-wrapper {
    margin-top: 10px;
  }

  &__button {

  }

  &__title-sub {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  &__order-list {
    margin-bottom: 10px;
  }
}

