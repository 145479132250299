.tyres-block {
  background: #ffffff;
  border-radius: 3px;

  &__content {
    overflow: hidden;
  }

  &._expand {
    .tyres-block {
      &__content {
        max-height: fit-content;
      }

      &__link {
        display: none;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
  }
}

@include respond-up('large') {
  .tyres-block {
    padding: 45px 60px;

    &__title {
      margin-bottom: 22px;
    }

    &__content {
      margin-bottom: 22px;
      max-height: 192px;
    }
  }
}

@include respond('medium') {
  .tyres-block {
    padding: 30px 40px;
    margin-bottom: 45px;

    &__title {
      margin-bottom: 22px;
    }

    &__content {
      margin-bottom: 22px;
      max-height: 312px;
    }
  }
}

@include respond-down('small') {
  .tyres-block {
    padding: 20px 20px;
    margin-bottom: 30px;

    &__title {
      margin-bottom: 12px;
    }

    &__content {
      margin-bottom: 12px;
      max-height: 529px;
    }
  }
}