.catalog-product-card {
  &.product-card {
    width: 100%;
  }

  &.other-products__product-card {
    .product-card__wrap {
      display: flex;
    }
  }

  &__stock-wrap {
    display: flex;
  }

  &__stock {
    position: relative;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      opacity: 0.5;
      width: 100%;
      border: 1px dashed var(--success-color);
    }

    &_in {
      color: var(--success-color);
    }

    &_out {
      color: var(--primary-color);

      &::before {
        border-color: var(--primary-color);
      }
    }
  }
}

@include respond-up('large') {
  .catalog-product-card {

  }
}

@include respond-up('medium') {
  .catalog-product-card {

  }
}

@include respond('medium') {
  .catalog-product-card {

  }
}

@include respond-down('medium') {
  .catalog-product-card {

  }
}

@include respond-down('small') {
  .catalog-product-card {

  }
}